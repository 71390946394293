import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Header, Menu, Button } from 'semantic-ui-react';
import { getSingleSession, getTask, closeTaskServer } from '../apiCall';
import { saveCompletedReportReviewOptions } from "../../redux/actions/index";
import userDummy from '../../assets/images/user-dummy.png';
import chatImg from '../../assets/images/chat_blue.png';
import videoImg from '../../assets/images/video.png';
import photoImg from '../../assets/images/photos.png';
import descriptionImg from '../../assets/images/description.png';
import LexxReportReviewChat from '../LexxReport/LexxReportReviewChat';

/* This is the component to review the session in Report */
function LexxReportSessionReview(props) {

    const [sessionData, setSessionData] = useState({});
    const [task, setTask] = useState([]);
    const [reviewChat, setReviewChat] = useState(false);
    const [selectedSession, setSelectedSession] = useState("");
    const [sessionId, setSessionId] = useState("");

    /* This function gets all the session data based on session id 
        and then sets it in sessionData variable
    */
    const getSingleSessionFn = () => {
        var leftOverSessions = props.savedReportReviewOptions.filter(function (n) { return !this.has(n) }, new Set(props.completedReportReviewOptions));
        if (leftOverSessions.length > 0) {
            setSelectedSession(leftOverSessions[0]);
            loadSession(leftOverSessions[0]);
        }
        setReviewChat(false);
    }

    /* If the user decides to load the session
        Load the entire session from redux 
    */
    const loadSession = (sessionId) => {
        getSingleSession(sessionId).then((response) => {
            setSessionId(sessionId);
            setSessionData(response.data.data);
            if (response.data.data.taskId !== null) getTaskServerCall(response.data.data.taskId);
        }).catch((error) => {
            console.log(error);
        });
    }

     /* Function gets called if there is a task associated with the session
        Get the task details 
        @id - task id
        It then sets the task in task variable
    */ 
    const getTaskServerCall = (id) => {
        getTask("/" + id).then((response) => {
            setTask(response.data.data.task);
        }).catch((error) => {
            console.log(error);
        });
    }

    /* Function to convert UTC date to readable date */
    const getDate = (dateUTC) => {
        return dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
    }

    /* Function to convert UTC date to readable time */
    const getTime = (dateUTC) => {
        if (dateUTC.getMinutes().toString().length === 1) {
            return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
        } else {
            return dateUTC.getHours() + ":" + dateUTC.getMinutes();
        }
    }

    /* Calculate the time spent in the session 
        and display in HH:MM
        completed session time - created session time
    */
    function timeDiffCalc(diffInMilliSeconds) {
        var displayMinutes, displaySeconds;
        const hours = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= hours * 86400;
        const minutes = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= minutes * 3600;
        const seconds = Math.floor(diffInMilliSeconds / 60) % 60;
        displayMinutes = minutes.toString().length > 1 ? minutes + ":" : "0" + minutes + ":";
        displaySeconds = seconds.toString().length > 1 ? seconds : "0" + seconds;
        return hours + ":" + displayMinutes + displaySeconds;
    }

    /* Function is called to calculate the steps completed in a troubleshooting process
        Loop over steps and add the steps in visited Steps variable
    */
    const stepsCalculated = (steps) => {
        var visitedSteps = "";
        steps.forEach((step) => {
            visitedSteps += " > Step " + (parseInt(step.step_id.substr(step.step_id.length - 2)) - 1);
        });
        return visitedSteps;
    }

    /* This function extracts the list of users the session is shared with 
        loop over the list and display they photos and name
        @sharedList - array of users it is shared with
    */
    const getSharedList = (sharedList) => {
        var shared = []
        if (sharedList.length > 0) {
            sharedList.forEach((eachUser, i) => {
                shared.push(<span key={i} className="sharedUser" onClick={() => props.history.push(`/userprofile/id=${eachUser.userId}`)}> <img src={eachUser.avatar !== "" ? eachUser.avatar : userDummy} alt='' /> <span>{eachUser.name} </span> </span>);
            });
            return shared;
        } else {
            return null;
        }
    }

    /* This function is called when the user closes the report
        this can only be done by the owner of the task
    */
    const closeReport = () => {
        closeTaskServer("/" + props.match.params.id + "/closed");
        props.history.push("/allTasks");
    }

    /* Redirect user to all Tasks */
    const closeTask = () => {
        props.history.push("/allTasks");
    }

    /* Take the user to the next session */
    const goNext = () => {
        props.saveCompletedReportReviewOptions([...props.completedReportReviewOptions, selectedSession]);
    }

    /* Function called when back btn is clicked
        either take them to the comments page
        or to the previous session
    */
    const goBack = () => {
        if (props.completedReportReviewOptions.length === 0) {
            props.history.goBack();
        } else {
            var sessionsCompleted = props.completedReportReviewOptions.filter(function(value){ 
                return value !== props.completedReportReviewOptions[props.completedReportReviewOptions.length - 1];
            });
            props.saveCompletedReportReviewOptions(sessionsCompleted);
        }
    }

    /* This function checks if the session has a chat
        so it checks the task keys and returns true or false
    */
    const showChat = () => {
        if(Object.keys(task).length > 0){
            for (var config of task.report.sessionConfigs) {
                if (config.sessionId === sessionId) {
                    return config.hasChat;
                }
            }
        }

    }

    useEffect(getSingleSessionFn, [props.completedReportReviewOptions]);

    return (
        <div className="session-summary my-sessions report-sessions">
            <div className="header-container">
                <Header as="h2">Task Report</Header>
                {
                    task.closed || (Object.keys(task).length > 0 && task.assignee.userId === props.userId) ? null :
                        <span className="saveDraft" onClick={() => closeReport()}>Close Report</span>
                }
                <div className="task-details">{task.taskNum !== "" ? task.taskNum + " • " + task.taskTitle : ""}</div>
            </div>
            <Header as="h3">Session {props.completedReportReviewOptions.length + 1}</Header>
            {
                Object.keys(sessionData).length > 0 ?
                    <>
                        <div className="session-details">
                            <div>
                                <p className="label">Session Name</p>
                                <p className="value">{sessionData.troubleshootingData !== null ? sessionData.troubleshootingData.title : ""}</p>
                            </div>
                            <div>
                                <p className="label">Date and Time</p>
                                <p className="value">{getDate(new Date(sessionData.createTime)) + " " + getTime(new Date(sessionData.createTime))}</p>
                            </div>
                            <div>
                                <p className="label">Shared with</p>
                                <p className="value">{sessionData.summarySharedWith.length > 0 ? getSharedList(sessionData.summarySharedWith) : 'N/A'}</p>
                            </div>
                            <div>
                                <p className="label">Resolution</p>
                                <p className="value">{sessionData.completeTime ? "FAULT FIXED" : "IN PROGRESS"}</p>
                            </div>
                            <div>
                                <p className="label">Number of Steps</p>
                                <p className="value">{sessionData.troubleshootingData !== null ? Object.keys(sessionData.troubleshootingData.step_activities).length - 1 : ""}</p>
                            </div>
                            <div>
                                <p className="label">Total Time Taken</p>
                                <p className="value">{sessionData.completeTime ? timeDiffCalc(Math.abs(new Date(sessionData.completeTime) - new Date(sessionData.createTime))) : null}</p>
                            </div>
                        </div>
                        <Header as="h3">Session breadcrumbs</Header>
                        <div>
                            Confirm Fault {stepsCalculated(sessionData.visitedTroubleshootingSteps)}
                        </div>
                        <Header as="h3" className="attachments">Session attachments</Header>
                        <ul>
                            <li onClick={() => setReviewChat(true)}>
                                <img src={chatImg} alt="" />
                                <span className="record">Chat Records</span>
                                <span className="arrow"> &gt; </span>
                            </li>
                            <li>
                                <img src={descriptionImg} alt="" />
                                <span className="record">Session learnings/notes</span>
                                <span className="arrow"> &gt; </span>
                            </li>
                            {/* <li>
                                <img src={photoImg} alt="" />
                                <span className="record">Photos</span>
                                <span className="arrow"> &gt; </span>
                            </li>
                            <li>
                                <img src={videoImg} alt="" />
                                <span className="record">Videos</span>
                                <span className="arrow"> &gt; </span>
                            </li> */}
                        </ul>
                    </>
                    : null}
            {
                props.savedReportReviewOptions.length - 1 === props.completedReportReviewOptions.length ?
                    Object.keys(task).length > 0 && (task.closed || task.assignee.userId === props.userId) ?
                        <Menu borderless fixed='bottom' id="menu">
                            <Menu.Item className="width100">
                                <Button className='primarybtn' onClick={() => closeTask()}>
                                    Close task
                                </Button>
                            </Menu.Item>
                        </Menu>
                        :
                        <Menu borderless fixed='bottom' id="menu">
                            <Menu.Item className="width100">
                                <Button className='primarybtn' onClick={() => closeReport()}>
                                    Approve and close task
                                </Button>
                            </Menu.Item>
                        </Menu>
                    :
                    <Menu borderless fixed='bottom' id="menu">
                        <Menu.Item position='left'>
                            <Button className='secondarybtn' onClick={() => goBack()}>
                                Back
                            </Button>
                        </Menu.Item>
                        <Menu.Item position='right'>
                            <Button className='primarybtn' onClick={() => goNext()}>
                                Next
                            </Button>
                        </Menu.Item>
                    </Menu>
            }
            {
                reviewChat && showChat() ? <LexxReportReviewChat open={reviewChat} chatWindow={setReviewChat} sessionId={sessionData.sessionId} save={false} /> : null
            }
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        savedReportReviewOptions: state.savedReportReviewOptions,
        completedReportReviewOptions: state.completedReportReviewOptions,
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps, { saveCompletedReportReviewOptions })(LexxReportSessionReview));