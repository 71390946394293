import React, { useState } from "react";
import LexxQRCode from './LexxQRCode';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LexxQRModal from "./LexxQRModal";

/* The component will be called when the user clicks on the menu */
const LexxScanner = (props) => {

    const [showModal, setShowModal] = useState(false); // to show the text in Modal
    const [text, setText] = useState(""); // text scanned to be shown

    /* Function to call when the barcode/qr code is scanned 
       @result - the scanned text
    */
    const onSuccess = (result) => {
        axios.post("/addScannedText", {
            result: result,
            username: props.username,
            timestamp: (new Date()).toUTCString() //Convert to UTC
        });
        setText(result);
        setShowModal(true);
    }

    const handleClose = () => {
        setShowModal(false);
    }

  return (
      <div className="qrcode-scanner">
          <LexxQRCode type={"QR"} onResult={(res) => onSuccess(res)} />
          <LexxQRCode type={"BR"} onResult={(res) => onSuccess(res)} />
          {
              showModal ?
              <LexxQRModal showModal={showModal} handleClose={handleClose} text={text} />
            :  null
          }
      </div>
  );

};

const mapStateToProps = (state) => {
    return {
        username: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps)(LexxScanner));