import React, { useState, useEffect } from 'react';
import { Icon, Modal, Feed, Form } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { getTeamPosts, sendPosts, readPost } from '../apiCall';
import { connect } from 'react-redux';
import userDummy from '../../assets/images/user-dummy.png';

/* This is the component to show the team updates */
function LexxPostNews(props) {

    const [posts, setPosts] = useState([]); // array of all the posts
    const [text, setText] = useState(""); // message to send

    /* This function get all the posts for the user from backend 
        and set it in posts variable
        and set them as read */
    const getPosts = () => {
        let mounted = true;
        getTeamPosts().then((response) => {
            if (mounted) {
            setPosts(response.data.data.updates);
            readPosts(response.data.data.updates);
            }
        }).catch((error) => {
            console.log(error);
        });
        return () => {mounted = false};
    }

    useEffect(getPosts, []);

    /* When the user enters message and clicks the add post btn 
        checks if the message is not empty, 
        then sends to server
    */
    const addPost = () => {
        if (text.length > 0) {
            const data = { "text": text };
            sendPosts(data).then((response) => {
                setText(""); //text to null again
                getPosts(); // get the posts from server
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    /* Calls the backend that all these posts are read
     @postsData - posts array which contain all the posts
    */
    const readPosts = (postsData) => {
        postsData.forEach((post) => {
            if (!post.read) {
                readPost("/" + post.updateId);
            }
        })
    }

    /* redirect user to the other technician profile */
    const showTeamMemberProfile = (userId) => {
        props.history.push(`/userprofile/id=${userId}`);
    }

    /* This function converts the UTC date to readable date */
    const getDate = (dateUTC) => {
        return dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
    }

    /* This function converts the UTC date to readable time */
    const getTime = (dateUTC) => {
        if (dateUTC.getMinutes().toString().length === 1) { 
            return dateUTC.getHours() + ":0" + dateUTC.getMinutes(); // add zero if minutes are single digit
        } else {
            return dateUTC.getHours() + ":" + dateUTC.getMinutes();
        }
    }

    /* update text field when user types in */
    const handleChange = (e) => {
        setText(e.target.value);
    }

    /* When enter key is pressed, submit the form */
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            addPost();
        }
    }

    return (
        <Modal
            closeIcon
            open={props.open}
            onClose={() => props.teamUpdate(false)}
            onOpen={() => props.teamUpdate(true)}
            className="addMember-container"
        >
            <Modal.Header>Team Updates</Modal.Header>
            <Modal.Content className="teamList teamUpdateContainer" scrolling>
                <Feed>
                    {posts.length > 0 && posts.map((post, i) => {
                        return (
                            <Feed.Event key={i} className={!post.read ? "blue" : ""} data-testid={'post-update-item'}>
                                {
                                    post.sourceAvatar !== "" ?
                                        <Feed.Label image={post.sourceAvatar} onClick={() => showTeamMemberProfile(post.sourceId)} /> :
                                        <Feed.Label image={userDummy} onClick={() => showTeamMemberProfile(post.sourceId)} />
                                }
                                <Feed.Content>
                                    <Feed.Label className="name" content={post.sourceName} />
                                    <Feed.Date content={getDate(new Date(post.createTime)) + " " + getTime(new Date(post.createTime))} />
                                    <Feed.Summary>
                                        {post.text}
                                    </Feed.Summary>
                                </Feed.Content>
                            </ Feed.Event>
                        )
                    })
                    }
                </Feed>
                <div className="bottom-nav">
                    <Form.Field>
                        <input placeholder='Write Message' name="text" onChange={handleChange} value={text} onKeyPress={handleKeyPress} />
                        <Icon name="send" data-testid={'send-icon'} color="grey" size="big" className="marL10" onClick={() => addPost()} />
                    </Form.Field>
                </div>
            </Modal.Content>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps)(LexxPostNews));