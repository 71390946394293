import * as React from "react";
import { Icon } from "semantic-ui-react";
import Recorder from "./recorderjs/recorder";
import { getTokenOrRefresh } from "../apiCall";
import { ResultReason } from "microsoft-cognitiveservices-speech-sdk";
const speechsdk = require("microsoft-cognitiveservices-speech-sdk");

const { v4: uuidv4 } = require("uuid");
const swaggerAPI = "https://vttdev.lexxtechnologies.com/audioTransfer";
/* This component is for the search bar */
class LexxSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "", // search query string
      show: false,
      blob: null,
      isRecording: false,
      stream: null,
      analyserData: { data: [], lineTo: 0 },
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchClick = this.searchClick.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.download = this.download.bind(this);
    this.getTranscriptText = this.getTranscriptText.bind(this);
    this.onMouseDownClick = this.onMouseDownClick.bind(this);
    this.startUserMedia = this.startUserMedia.bind(this);
    this.createDownloadLink = this.createDownloadLink.bind(this);

    // webkit shim
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    navigator.getUserMedia =
      navigator.getUserMedia || navigator.mediaDevices.getUserMedia || navigator.webkitGetUserMedia;
    window.URL = window.URL || window.webkitURL;

    this.audio_context = new AudioContext({ sampleRate: 16000 });
    this.recorder = null;

    navigator.getUserMedia({ audio: true }, this.startUserMedia, function (e) {
      console.log("No live audio input: " + e);
    });
  }

  startUserMedia(stream) {
    var input = this.audio_context.createMediaStreamSource(stream);
    console.log("Media stream created.");

    this.recorder = new Recorder(input);
    console.log("Recorder initialised.");
  }

  /**
   * Start Recording
   */
  start() {
    this.recorder.record();
    this.setState({ isRecording: true });
  }
  /**
   * Stop Recording
   */
  stop() {
    this.recorder.stop();
    this.setState({
      isRecording: false,
    });
    // this.createDownloadLink();
  }

  async createDownloadLink() {
    this.recorder &&
      (await this.recorder.exportWAV((blob) => {
        this.getTranscriptText(blob);
        var url = URL.createObjectURL(blob);
        var li = document.createElement("li");
        var au = document.createElement("audio");
        var hf = document.createElement("a");
        au.controls = true;
        au.src = url;
        hf.href = url;
        hf.download = new Date().toISOString() + ".wav";
        hf.innerHTML = hf.download;
        li.appendChild(hf);
        hf.click();
      }));
  }

  dontGotStream(error) {
    console.log("Get stream failed", error);
  }
  /**
   * Download voice file
   */
  download() {
    Recorder.download(this.state.blob, "Recording");
    console.log(this.state.blob);
    this.getTranscriptText();
  }

  /**
   * Save voice file to micro service
   */
  async getTranscriptText(blob) {
    var date = new Date();
    console.log("BLOB", blob);
    const formData = new FormData();
    formData.append("annotated", true);
    formData.append("audioid", uuidv4());
    formData.append("date", date.toLocaleDateString());
    formData.append("filebin", blob);
    formData.append("trained", true);
    formData.append("transcription", this.state.searchString);
    var request = new XMLHttpRequest();
    request.open("POST", swaggerAPI, true);
    request.send(formData);
    request.onload = () => {
      if (request.status === 201) {
        const data = JSON.parse(request.response);
        if (data.recognized_text) {
          console.log(data.recognized_text);
        }
      }
    };
    this.recorder.clear();
  }

  /* Function called 
        When enter key is pressed , search the solr 
    */
  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.props.act(this.state.searchString);
    }
  }

  /* Update the search String when user types */
  handleChange(event) {
    this.setState({
      searchString: event.target.value,
    });
  }

  /* Function called 
        When btn is clicked , search the solr 
    */
  searchClick() {
    this.props.act(this.state.searchString);
  }

  /**
   * 
   Start Speech recognition
   Speech recognition using azure seech recognition library 
   */
  async onMouseDownClick(e) {
    e.preventDefault();
    this.start();
    const tokenObj = await getTokenOrRefresh();
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
      tokenObj.authToken,
      tokenObj.region
    );
    speechConfig.speechRecognitionLanguage = "en-US";

    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new speechsdk.SpeechRecognizer(
      speechConfig,
      audioConfig
    );

    recognizer.recognizeOnceAsync((result) => {
      let displayText;
      if (result.reason === ResultReason.RecognizedSpeech) {
        displayText = result.text;
      } else {
        displayText = "";
      }
      this.setState({
        searchString: displayText,
      });
      setTimeout(() => {
        this.searchClick();
      }, 2000);
      this.stop();
    });
  }

  render() {
    const { isRecording, blob, stream } = this.state;

    return (
      <div>
        <input
          className="search-input"
          onChange={(e) => this.handleChange(e)}
          onKeyPress={(e) => this.handleKeyPress(e)}
          value={this.state.searchString}
          placeholder="Search"
        />
        <Icon
          name={"microphone"}
          size={"large"}
          color={isRecording ? "#FFFFFF" : "blue"}
          onMouseDown={(e) => this.onMouseDownClick(e)}
        />
        <Icon name="arrow right" onClick={() => this.searchClick()} />
        <ul id="recordingslist"></ul>
      </div>
    );
  }
}

export default LexxSearchBar;
