import React from 'react';
import { Label } from "semantic-ui-react";
import userDummy from '../../assets/images/user-dummy.png';

/* This is the component for showing the comments for the task */
const LexxComment = ({ comment: { text, commentId, owner, createTime }, loggedInUser, editing, deleteComment, editComment, editSavedComment, setEditedComment, editedComment, editable, fromreport}) => {
    /* text - the comment text
        commentId - comment id
        owner - comment owner (true/false)
        createTime - comment created time
        loggedinUser - user id of logged in user 
        editing - if in editing state 
        deleteComment - function when comment is deleted
        editComment - function when comment is edited
    */

    /* Convert the UTC date to readable date */
    const getDate = (dateUTC) => {
        let date = new Date();
        let dateMessage = dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
        let todayDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
        return dateMessage === todayDate ? "Today" : dateMessage;
    }

    /* Convert the UTC date to readable time */
    const getTime = (dateUTC) => {
        if (dateUTC.getMinutes().toString().length === 1) {
            return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
        } else {
            return dateUTC.getHours() + ":" + dateUTC.getMinutes();
        }
    }

    return (
        <div className="each-comment">
            <div className="profile-image">
                <Label circular className="collaborators float-left" as='a' image={owner.avatar !== "" ? owner.avatar : userDummy} />
            </div>
            <div className="comment-data">
                <div>
                    <span className="name">{owner.name}</span>
                    <span className="date">{getDate(new Date(createTime)) + " " + getTime(new Date(createTime))}</span>
                </div>
                {editing && editable ?
                    <input type="text" className="editingText" value={editedComment} onChange={(e) => setEditedComment(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && editSavedComment(commentId)}/> :
                    <p>
                        {text}
                    </p>
                }
                {
                    loggedInUser === owner.id && !editing && editable ?
                        <>
                            <button className='pointer-button' onClick={() => editComment(commentId, text)}>Edit </button>
                            <button className="delete pointer-button" onClick={() => deleteComment(commentId)}>Delete </button>
                        </>
                        : editing ?
                            <div>
                                <button className='pointer-button' onClick={() => editSavedComment(commentId)}>Save </button>
                            </div>
                        : null
                }
            </div>
        </div>
    )
}

export default LexxComment;