import React, { useState } from "react";
import { Image, Modal, Button } from "semantic-ui-react";
import userDummy from "../../assets/images/user-dummy.png";
import LexxFullScreen from "./LexxFullScreen";

/* This component will help display each message of chat */
const LexxNote = ({
  message: { data, sourceId, isFile, noteFor, createTime },
  username,
  usersInChat,
}) => {
  let isSentByCurrentUser = false; // by default logged in user's message is false
  let videoFormats = [".mov", ".mp4", ".wmv", ".avi"]; // videoFormats allowed
  const trimmedName = username.trim().toLowerCase();
  const [isFullscreen, setIsFullscreen] = useState(false);
  // if the source of the message and logged in user is same
  if (sourceId === trimmedName) {
    isSentByCurrentUser = true;
  }

  /* Function to get the current date
      "today" - if the message sent today
      "dd/mm/yyyy" - if any other
    */
  const getDate = (dateUTC) => {
    let date = new Date();
    let dateMessage =
      dateUTC.getDate() +
      "/" +
      (dateUTC.getMonth() + 1) +
      "/" +
      dateUTC.getFullYear();
    let todayDate =
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    return dateMessage === todayDate ? "Today" : dateMessage;
  };

  /* Function to get the time */
  const getTime = (dateUTC) => {
    if (dateUTC.getMinutes().toString().length === 1) {
      return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
    } else {
      return dateUTC.getHours() + ":" + dateUTC.getMinutes();
    }
  };

  /* Get the profile image of the user based on the parameter
        @name - name of the user whose profile image is to be shown
        usersInChat is an array with all the users and profile image of them
    */
  const getProfileImage = (name) => {
    for (var eachUser in usersInChat) {
      if (eachUser === name) {
        return usersInChat[eachUser];
      }
    }
  };
  const mediaFullScreen = () => {
    setIsFullscreen((isFullscreen) => !isFullscreen);
  };
  return (
    !(noteFor === "myself" && sourceId !== username) && (
      <div className="messageContainer justifyStart">
        <div className="left-align">
          <div className="date">
            <Image src={userDummy} className="chatIcon" />
            <b>{getProfileImage(sourceId).name}</b> {`    `}
            {getDate(new Date(createTime)) +
              " " +
              getTime(new Date(createTime))}
          </div>
          {isFile ? (
            <div className="noteBox">
              {videoFormats.some((eachFormat) =>
                data.toLowerCase().includes(eachFormat)
              ) ? (
                <video src={data} width="200" height="200" controls />
              ) : (
                <img
                  src={data}
                  alt=""
                  className="sharedImg"
                  onClick={() => mediaFullScreen()}
                />
              )}
              <br></br>
              <br></br>
              <br></br>
            </div>
          ) : (
            <div className="noteBox">
              <p>{data}</p>
            </div>
          )}
          {noteFor === "myself" && sourceId === username ? (
            <div className="date">Private note</div>
          ) : (
            <div className="date">Shared with your colleagues</div>
          )}
        </div>
        <LexxFullScreen
          isFullscreen={isFullscreen}
          data={data}
          mediaFullScreen={mediaFullScreen}
        />
      </div>
    )
  );
};

export default LexxNote;
