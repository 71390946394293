import * as React from "react";
import throttle from "lodash.throttle";
import { connect } from "react-redux";
import LexxImage from "../global/LexxImage";
import LexxList from "../global/LexxList";
import LexxPara from "../global/LexxPara";
import LexxFiTable from "../global/LexxFiTable";
import Unknown from "../global/Unknown";

/* This component is for showing the results after the search */
class LexxCard extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickThrottled = throttle(this.handleClick, 1000);
    this.getMetaRows = this.getMetaRows.bind(this);
  }

  /* Take the user to the specific page for eg: troubleshooting, schematic, etc. */
  handleClick() {
    this.props.act(this.props.data.payload);
  }

  /* Mapping info type from Front-end with solr */
  mapping(info_type) {
    switch (info_type) {
      case "Fault Isolation":
        return "Troubleshooting Guide";
      case "Examinations, tests & checks":
      case "proced":
        return "Procedure";
      case "Work Order":
        return "Work Order";
      case "Schematics":
        return "Schematics";
      case "Description":
        return "Parts List";
      default:
        return "Reference";
    }
  }

  /* Display different kind of fields for different info type of data */
  getMetaRows() {
    const textLabels = {
      fault_Code: "Fault Code",
      fault_name: "Fault Name",
      title_meta: "Category",
      subCategory: "Sub Category",
      schematics_title: "Manual",
      drawing_number: "Drawing Number",
      sheet: "Sheet",
      series: "Park Name",
      wtg_name: "Turbine Number",
      score: "Relevancy",
      pod_eod: "Notes",
      info_type: "Result Type",
    };

    let displayFields = ["fault_name", "fault_Code"];
    switch (this.props.data.payload["info_type"]) {
      case "procedure":
      case "Description":
        displayFields = ["title_meta", "subCategory", "info_type"];
        break;
      case "workorder":
        displayFields = [
          "series",
          "wtg_name",
          "fault_Code",
          "pod_eod",
          "info_type",
        ];
        break;
      case "partsbreakdown":
        displayFields = [
          "subCategory",
          "schematics_title",
          "drawing_number",
          "sheet",
          "info_type",
        ];
        break;
      default:
        displayFields = ["fault_name", "fault_Code"];
    }

    /* Based on the display field in the previous function 
             display the fields in table */
    return Object.keys(this.props.data).map((key) => {
      if (displayFields.includes(key)) {
        let cardmeta_title = textLabels[key];
        if (this.props.data[key]) {
          return (
            <tr key={key}>
              <td>
                <span className="card-text cardmeta-title">
                  {cardmeta_title}
                </span>
              </td>
              <td>
                <span className="card-text cardmeta-value">
                  {key === "info_type"
                    ? this.mapping(this.props.data[key])
                    : this.props.data[key]}
                </span>
              </td>
            </tr>
          );
        } else return null;
      } else return null;
    });
  }

  /* Cancel the throttling when component is unmounted */
  componentWillUnmount() {
    this.handleClickThrottled.cancel();
  }

  /* This function is to call the respective section/component depending on the tag 
        @obj - obj is the tag data we get from backend
    */
  getSection(obj) {
    let compGroup = obj.map((tagData) => {
      switch (tagData.tag) {
        case "ol":
          return (
            <LexxList
              key={Math.random()}
              listData={tagData}
              bullet={false}
              troubleshooting={true}
            />
          );
        case "ul":
          return (
            <LexxList
              key={Math.random()}
              listData={tagData}
              bullet={true}
              troubleshooting={true}
            />
          );
        case "img":
          return (
            <LexxImage
              key={Math.random()}
              imageData={tagData}
              troubleshooting={true}
            />
          );
        case "video":
          return (
            <LexxImage
              key={Math.random()}
              imageData={tagData}
              troubleshooting={true}
            />
          );
        case "p":
          return (
            <LexxPara
              key={Math.random()}
              paraData={tagData}
              troubleshooting={true}
            />
          );
        case "table":
          return (
            <LexxFiTable
              key={Math.random()}
              tableData={tagData}
              troubleshooting={true}
            />
          );
        default:
          return Unknown;
      }
    });
    return <>{compGroup}</>;
  }

  render() {
    let card_key_class = "card-key";
    let meta = this.getMetaRows(); // get the fields to display
    // if the steps are visited, then add a class to show green color
    this.props.visitedTroubleshootingSteps.map((val, _) => {
      if (
        val["search_item"] === this.props.troubleshootingData.id &&
        val["step_id"] === this.props.data.payload["step_id"]
      ) {
        card_key_class = "card-key-visited";
      }
      return null;
    });

    return (
      <div
        className={
          this.props.data.payload["isStepDisabled"] ||
          this.props.data.payload["isStepDisabled"] === undefined
            ? "lexx-card"
            : "lexx-card-disable"
        }
      >
        <div className="card-content">
          {this.props.data.key >= 0 && !this.props.keyNumeric ? (
            <div className={card_key_class}>
              {(this.props.data.key + 10).toString(36).toUpperCase()}
            </div>
          ) : null}
          {this.props.data.key >= 0 && this.props.keyNumeric ? (
            <div className={card_key_class}>{this.props.data.key + 1}</div>
          ) : null}
          {this.props.data.title ? (
            <div className="card-header">
              {this.props.data.title.charAt(0).toUpperCase() +
                this.props.data.title.slice(1)}
            </div>
          ) : null}
          {meta ? (
            <table>
              <tbody>{meta}</tbody>
            </table>
          ) : (
            <span />
          )}
          {typeof this.props.showDesc !== "undefined" &&
          !this.props.showDesc ? null : (
            <div className="card-description">
              <span
                className="card-text"
                dangerouslySetInnerHTML={{ __html: this.props.data.snippet }}
              ></span>
              {this.getSection(this.props.data.payload["stepData"])}
            </div>
          )}
        </div>
        <button
          className="card-button"
          onClick={this.handleClickThrottled}
          disabled={
            !this.props.data.payload["isStepDisabled"] &&
            this.props.data.payload["isStepDisabled"] !== undefined
          }
        >
          <span
            className="card-text button-text"
            dangerouslySetInnerHTML={{
              __html: this.props.button.replace(
                "Information",
                this.mapping(this.props.data["info_type"])
              ),
            }}
          ></span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    troubleshootingData: state.troubleshootingData,
    visitedTroubleshootingSteps: state.visitedTroubleshootingSteps,
  };
};

export default connect(mapStateToProps, {})(LexxCard);
