import React from 'react';
import { Image } from "semantic-ui-react";
import userDummy from '../../assets/images/user-dummy.png';

/* This component will help display each message of chat */
const LexxNote = ({ message: { data, sourceId, isFile, noteFor, createTime }, username, usersInChat }) => {
    let videoFormats = [".mov", ".mp4", ".wmv", ".avi"]; // videoFormats allowed

    /* Function to get the current date
      "today" - if the message sent today
      "dd/mm/yyyy" - if any other
    */ 
    const getDate = (dateUTC) => {
        let date = new Date();
        let dateMessage = dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
        let todayDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
        return dateMessage === todayDate ? "Today" : dateMessage;
    }

    /* Function to get the time */
    const getTime = (dateUTC) => {
        if (dateUTC.getMinutes().toString().length === 1) {
            return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
        } else {
            return dateUTC.getHours() + ":" + dateUTC.getMinutes();
        }
    }

    /* Get the profile image of the user based on the parameter
        @name - name of the user whose profile image is to be shown
        usersInChat is an array with all the users and profile image of them
    */
    const getProfileImage = (name) => {
        for (var eachUser in usersInChat) {
            if (eachUser === name) {
                return usersInChat[eachUser];
            }
        }
    }

    return (
                !(noteFor === 'myself' && sourceId !== username) && 
                <div className="messageContainer justifyStart">
                    <div className="left-align">
                        <div className="date"> 
                            <Image src={userDummy} className="chatIcon" /> 
                            <b style={{marginLeft: '2%'}}>{getProfileImage(sourceId).name}</b> {`    `}
                            {getDate(new Date(createTime)) + " " + getTime(new Date(createTime))}
                        </div>
                        {isFile ?
                            <div className="noteBox">
                                {videoFormats.some(eachFormat => data.toLowerCase().includes(eachFormat)) ?
                                    <video src={data} width="200" height="200" controls />
                                    :
                                    <a href={data}>
                                        <img src={data} alt="" className="sharedImg" />
                                    </a>
                                }
                                <br></br><br></br><br></br>
                            </div>
                            :
                            <div>
                                <p style={{ marginLeft: '5%', marginRight: '5%', whiteSpace: 'wrap' }}>{data}</p>
                                {
                                    (noteFor === 'myself' && sourceId === username) ? 
                                    <div className="date">
                                        Private note
                                    </div> : 
                                    <div className="date">
                                        Shared with your colleagues 
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            
    );
}

export default LexxNote;