import React, { useState, useEffect } from 'react';
import { Modal, List } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getChatData, getOrganizationProfilePictures } from '../apiCall';
import LexxMessage from "./LexxMessage";

/* Domponent for the Chat Review which is a modal in the Report */
function LexxReviewChat(props) {

    const [messages, setMessages] = useState([]); // array of all the message
    const [allUsers, setUsers] = useState([]); // all users in the chat


    /* Function to get all the messages of the chat from the server
        and images of the users involved in the chat
        later set the messages and users in messages, allusers variable
    */
    const getMessages = () => {
        getChatData("?sessionId=" + props.sessionID).then((response) => {
            setMessages(response.data.data.messages);
        }).catch((error) => {
            console.log(error);
        });

        getOrganizationProfilePictures().then((response) => {
            setUsers(response.data.data.users);
        });
    }

    useEffect(getMessages, []);

    return (
        <Modal
            closeIcon
            open={props.open}
            onClose={() => props.chatWindow(false)}
            onOpen={() => props.chatWindow(true)}
            className="chat-container"
            dimmer={'blurring'}
        >
            <Modal.Header>
                Review Chat
            </Modal.Header>
            <Modal.Content className="teamList sessionList chatWrapper">
                <List>
                    {Object.keys(allUsers).length > 0 && messages.map((message, i) => <div key={i} className="messageWrapper"><LexxMessage message={message} username={props.username} usersInChat={allUsers} /></div>)}
                </List>
            </Modal.Content>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        sessionID: state.sessionData.sessionID,
        username: state.userInfo.userId,
        sessionUsers: state.sessionUsers
    };
};

export default withRouter(connect(mapStateToProps)(LexxReviewChat));