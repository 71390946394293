import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from 'redux-persist';
import "semantic-ui-css/semantic.min.css";
import './app/assets/css/index.css';
import './app/assets/css/lexx.css';
import './app/assets/font/inter.css';
import App from './app/components/App';
import * as serviceWorker from './serviceWorker';
import { store } from './app/redux/store/index';
import KeycloakUserService from "../src/app/components/login/KeyCloakService";

let persistor = persistStore(store);

const renderApp = () =>
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  ); 

KeycloakUserService.initKeycloak(renderApp);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
