import React from 'react';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { saveSearchResults } from '../../redux/actions';
import LexxDashboardCard from './LexxDashboardCard';
import LexxFooterToolbar from '../LexxFooterToolbar/LexxFooterToolbar';
import LexxCarousel from '../LexxCarousel/LexxCarousel';

/* This is the component to display the Dashboard (main page after login) */
const LexxDashboard = (props) => {

    /* This component calls 2 components 
        1. Dashboard Card - for buttons
        2. Carousel - for session and task carousel
    */
    return (
        <div className="content-container home-container">
            <Container className='dashboard-container'>
                <h2>
                    Dashboard
                </h2>
                <LexxDashboardCard />          
                <LexxCarousel/>
            </Container>
            <Container className='dashboard-footer-container'>
                <LexxFooterToolbar/>
            </Container>
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        searchResults: state.searchResults
    };
};

export default connect(mapStateToProps, {
    saveSearchResults
})(LexxDashboard);