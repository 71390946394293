import React, { useState, useEffect } from 'react'
import { Image, Menu, Dropdown } from 'semantic-ui-react'
import chatImg from '../../assets/images/chat.png';
import learningImg from '../../assets/images/learning.png';
import addImg from '../../assets/images/addPerson.png';
import dotsImg from '../../assets/images/dots.png';
import nextImg from '../../assets/images/next.png';
import faultFixedImg from '../../assets/images/fault_fixed.png';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import LexxInviteToSession from "./LexxInviteToSession";
import LexxChat from "./LexxChat";
import LexxSessionLearning from "./LexxSessionLearning";
import io from "socket.io-client";
import { SOCKET_EVENTS } from "../../constants/socketEvents";
import { faultFixed, readMessages } from "../apiCall";

let socket;
/* This component is to display the bottom menu for session */
function LexxSessionToolbar(props) {

    const faultText = props.isTroubleshooting ? "Fault is Fixed" : "End Look Up";

    /* Fixed items at bottom of the page */
    const items = [
        {
            text: 'Session Chat',
            key: 'chat',
            icon: chatImg,
            allowedToSessionShare: true
        },
        {
            text: 'Add Notes',
            key: 'learning',
            icon: learningImg,
            allowedToSessionShare: true
        },
        {
            text: 'Invite Colleague',
            key: 'invite',
            icon: addImg,
            allowedToSessionShare: false
        }
    ]

    /* These extra Items are shown through 3 dots */
    const extraItems = [
        {
            key: faultText,
            text: faultText,
            value: faultText,
            image: { avatar: true, src: faultFixedImg }
        },
        // {
        //     key: 'Next Step',
        //     text: 'Next Step',
        //     value: 'Next Step',
        //     image: { avatar: true, src: nextImg }
        // }
    ]

    const [inviteModal, setInviteModal] = useState(false); // invite technican modal - hide/show
    const [sessionChat, setSessionChat] = useState(false); // chat modal - hide/show
    const [sessionLearning, setSessionLearning] = useState(false);
    const [unread, setUnread] = useState(0); // unread message count
    // const [usersInChat, setUsers] = useState([]);

    /* Get the unread messages for that session from the server 
        create a socket connection and get the unread messages 
    */
    const getUnreadMessages = () => {
        socket = io();
        if (props.username && props.sessionID) {
            socket.emit(SOCKET_EVENTS.JOIN_SESSION, { userId: props.username, sessionId: props.sessionID }, (error) => {
                if (error) {
                    alert(error);
                }
            });
            socket.on(SOCKET_EVENTS.UNREAD_COUNT, (data) => {
                setUnread(data.unread);
            });
            socket.on(SOCKET_EVENTS.SESSION_DATA, (data) => {
                // setUsers(data.users);
            });
        }
    }

    /* If the extra items are clicked from 3 dots 
        redirect user based on the item clicked
    */
    const extraItemClicked = (value) => {
        if (value === 'Next Step' && props.isTroubleshooting) {
            props.history.push("/troubleshooting/issues/options/");
        } else if(value === 'Next Step'){
            props.history.goBack();
        }
        else {
            faultFixed(props.sessionID);
            props.history.push(`/feedback/`);
        }
    }

    /* If the fixed items are clicked, show modal for invite user or chat modal 
        @key - passed from UI from the option selected
    */
    const optionSelected = (key) => {
        switch (key) {
            case 'invite':
                inviteMember(true);
                break;
            case 'chat':
                readMessages({sessionId: props.sessionID});
                chatWindow(true);
                break;
            case 'learning':
                readMessages({sessionId: props.sessionID});
                learningWindow(true)
            default:
                break;
        }
    }

    const learningWindow = (isOpen) => {
        setSessionLearning(isOpen)
    }

    /* Show or hide the chat modal */
    const chatWindow = (isOpen) => {
        setSessionChat(isOpen);
    }

    /* Show or hide the invite technician modal */
    const inviteMember = (isOpen) => {
        setInviteModal(isOpen);
    }

    useEffect(getUnreadMessages, [props.sessionID]);

    return (
        <>
            <Menu className="menu-height session-bar">
                {
                    items.map((val) => {
                        if (props.isOwner) {
                            return (
                                <Menu.Item
                                    key={val.key}
                                    name={val.text}
                                    className="footer-menu"
                                    onClick={() => optionSelected(val.key)}
                                >
                                    <>
                                        <Image src={val.icon} className='footer-toolbar-image' />
                                        <p className='footer-toolbar-text-selected'>{val.text}
                                            {
                                                val.key === 'chat'&& unread > 0 ?
                                                    <span className="notification-number session-chat owner"> {unread} </span>
                                                    : null
                                            }
                                        </p>
                                    </>
                                </Menu.Item>
                            )
                        } else {
                            if (val.allowedToSessionShare && !props.isShared) {
                                return (
                                    <Menu.Item
                                        key={val.key}
                                        name={val.text}
                                        className="footer-menu shared"
                                        onClick={() => optionSelected(val.key)}
                                    >
                                        <>
                                            <Image src={val.icon} className='footer-toolbar-image' />
                                            <p className='footer-toolbar-text-selected'>{val.text}
                                                {
                                                    val.key === 'chat' && unread > 0 ?
                                                        <span className="notification-number session-chat"> {unread} </span>
                                                        : null
                                                }
                                            </p>
                                        </>
                                    </Menu.Item>
                                )
                            }
                            return null;
                        }
                    })
                }
                {props.isOwner ?
                        extraItems.map((option) => (
                                    <Menu.Item
                                     key={option.key}
                                     name={option.text}
                                     className="footer-menu"
                                     onClick={() => extraItemClicked(option.value)}
                                 >
                                    <>
                                        <Image src={option.image.src} className='footer-toolbar-image' />
                                        <p className='footer-toolbar-text-selected'>{option.text}
                                        </p>
                                    </>
                                    </Menu.Item>
                                    
                                ))
                        
                    : null}
            </Menu>
            {
                inviteModal ? <LexxInviteToSession open={inviteModal} inviteMember={inviteMember} /> : null

            }
            {
                sessionChat ? <LexxChat open={sessionChat} chatWindow={chatWindow} socket={socket} usersInChat={props.sessionUsers} /> : null

            }
            {
                sessionLearning ? <LexxSessionLearning open={sessionLearning} learningWindow={learningWindow} socket={socket} usersInChat={props.sessionUsers} /> : null
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isTroubleshooting: state.isTroubleshooting,
        isOwner: state.sessionData?.isOwner,
        isShared: state.sessionData?.isShared ? state.sessionData.isShared : false,
        sessionID: state.sessionData?.sessionID ? state.sessionData.sessionID : null,
        username: state.userInfo.userId,
        sessionUsers: state.sessionUsers
    };
};

export default withRouter(connect(mapStateToProps)(LexxSessionToolbar));