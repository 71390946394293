import React, { useState, useEffect } from 'react';
import { Icon, Input, Modal, List, Image } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { getTeam } from '../apiCall';
import userDummy from '../../assets/images/user-dummy.png';


/* This component(modal) is shown when a user needs to be share a session */
function LexxShareSession(props) {

    const [search, setSearch] = useState(""); // search query for users
    const [stableOrganizationList, setStableOrganizationList] = useState([]); // entire list of users in your team
    const [organizationList, setOrganizationList] = useState([]); // entire list of users in team which changes when user seaches

    /* Get all the users from the db in your team and set it in variable */
    const getUsers = () => {
        getTeam().then((response) => {
            setStableOrganizationList(response.data.data.team.length === 0 ? [] : response.data.data.team);
            setOrganizationList(response.data.data.team.length === 0 ? [] : response.data.data.team);
        }).catch((error) => {
                console.log(error);
        });
    }

    useEffect(getUsers, []);

        /* When the technician is searched, filter and set the setOrganizationList, it will re-render */
    const changeSearch = (event) => {
        setSearch(event.currentTarget.value);
        const filteredData = stableOrganizationList.filter(item => {
            return Object.keys(item).some((eachKey) => {
                if (eachKey === "name" || eachKey === "title") {
                    return item[eachKey].toLowerCase().includes(event.currentTarget.value.toLowerCase());
                } else {
                    return null;
                }
            })
        });
        setOrganizationList(filteredData);
    }

    /* Redirect the user to their profile */
    const showTeamMemberProfile = (userId) => {
        props.history.push(`/userprofile/id=${userId}`);
    }

    return (
        <Modal
            closeIcon
            open={props.open}
            onClose={() => props.closeDialog(false)}
            onOpen={() => props.closeDialog(true)}
            className="addMember-container"
        >
            <Modal.Header>Share session data</Modal.Header>
            <div className="searchTeam">
                <Input
                    icon={<Icon name='search' color="black" />}
                    iconPosition='left' placeholder='Search' value={search} onChange={changeSearch} />
            </div>
            <Modal.Content className="teamList sessionList">
                <List>
                    {organizationList.map((tech, i) => {
                        if (tech.name !== undefined) {
                            return (
                                <List.Item key={i}>
                                    <Image avatar src={tech.profileImg !== "" ? tech.profileImg : userDummy} onClick={() => showTeamMemberProfile(tech.userId)} />
                                    <List.Content onClick={() => props.shareSessionWithUser(tech.userId)}>
                                        <List.Header>{tech.name}</List.Header>
                                        <List.Description>
                                            {tech.title}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            )
                        } else {
                            return null;
                        }
                    })
                    }
                </List>
            </Modal.Content>
        </Modal>
    )
}

export default withRouter(LexxShareSession);