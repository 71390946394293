import { store } from '../redux/store/index';
import { newSession, updateSession } from './apiCall';

function getSessionState() {
    const state = store.getState();
    let sessionState = {
        routerPath: window.location.pathname,
        // searchResults: state.searchResults,
        searchResults: [],
        troubleshootingData: state.troubleshootingData,
        troubleshootingOption: state.troubleshootingOption === undefined ? null : state.troubleshootingOption,
        troubleshootingStep: state.troubleshootingStep === undefined ? null : state.troubleshootingStep,
        visitedTroubleshootingSteps: state.visitedTroubleshootingSteps === undefined ? null : state.visitedTroubleshootingSteps,
        isTroubleshooting: state.isTroubleshooting,
        searchQuery: state.searchQuery,
        taskId: state.taskId
    };
    return sessionState;
}

export function sessionCreate(props) {
    newSession(getSessionState()).then((response) => {
        if (response.data.code === "Success") {
            props.saveSessionData({
                sessionID: response.data.data.sessionId,
                isOwner: true,
                isShared: false
            });
            // props.saveTaskId(response.data.data.sessionId);
        }
    }).catch((err) => {
        console.log(err);
    });
}

export function sessionUpdate() {
    if(store?.getState()?.sessionData?.isOwner){
        updateSession("/" + store.getState().sessionData.sessionID, getSessionState()).then((response) => {
            if (response.data.code === "Success") {
                // console.log(response);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
}