import axios from "axios";
import Cookie from 'universal-cookie';

const SIGNUP = "/users";
const ALL_SESSIONS = "/worksessions";
const START_UPDATE_SESSION = "/worksessions";
const GET_TEAM_SESSION = "/worksessions/";

const SEARCH = "/search";
const GET_PROCEDURE = "/procedure";

const GET_PROFILE = "/users/";
const GET_PROFILE_LINK = "/users/avatars/uplink/";
const PROFILE_UPLOADED = "users/avatars/key";
const CHANGE_STATUS = "/users/status";
const SAVE_PROFILE = "/users/profile";
const CALL_MADE = "/comms";

const GET_ALL_USERS = "/users";

const GET_TEAM_LIST = '/teams';
const GET_TEAM_LIST_ALL = '/teams/all-users';

const GET_NOTIFICATIONS = "/notifications";

const POST_FEEDBACK = "/updateActivities";
const POST_STEP_FEEDBACK = "/updateStepActivities";
const LOG_DATA = "/dummy";

const FIREBASE_CALL = "/users/firebaseToken";
const GET_SOLUTIONS = "/solutions";
const SAVE_SOLUTIONS = "/solutions";
const SAVE_NEW_STEP = "/steps";
const SOLUTION_ACTIONS = "/solutions/";
const GET_STEPS = "/steps";
const STEP_ACTIONS = "/steps/";
const SPEECH_TOCKEN = "/getSpeechToken";
const LOG_ACTION = "/loguseraction";
const SEND_TEAM_INVITE = "/notifications/teamInvites";
const GET_UNREAD_POSTS = "/teamupdates/unread";
const GET_POST_NEWS = "/teamupdates";
const CONFIRM_TEAM_ADD = "/notifications/teamInvites/";
const CONFIRM_SESSION_ADD = "/notifications/sessionInvites/";
const GET_CHAT_DATA = "/messages";
const GET_NOTE_DATA = "/notes";
const SEND_TEMP_NOTIFICATION_FORCHAT = "/notifications/tempChatInvite";
const GET_PROFILE_ALL_USERS = "/users?min=true";
const CREATE_TASK = "/tasks";
const UPDATE_TASK = "/tasks";
const GET_TASK_LIST = "/tasks";
const COMMENTS = "/comments";
const DELEGATE_TASK = "/notifications/taskInvites";
const TASK_ASSIGN = "/notifications/taskInvites/";
const REPORTS = "/tasks/reports";
const GET_SESSION_UNREAD_MESSAGES = "/messages/unread";
const INVITE_INTO_SESSION = "/notifications/sessionInvites";
const READ_MESSAGES = "/messages/read";
const SHARE_SESSION = "/notifications/sessionSummaries";


export function shareSession(data){
    return axios.post(SHARE_SESSION, data);
}

export function readMessages(data){
    return axios.post(READ_MESSAGES, data);
}

export function getSessionUnreadMessages(){
    return axios.get(GET_SESSION_UNREAD_MESSAGES);
}

export function inviteIntoSession(data){
    return axios.post(INVITE_INTO_SESSION, data);
}

export function getReports(){
    return axios.get(REPORTS);
}

export function delegateTaskToTeamMember(data){
    return axios.post(DELEGATE_TASK, data);
}

// export function taskAssignResult(url, data){
//     return axios.post(TASK_ASSIGN + url, data);
// }

export function taskAssignResult(url, data){
    return axios.post(UPDATE_TASK + url, data);
}

export function getComments(url){
    return axios.get(COMMENTS + url);
}

export function addComment(data){
    return axios.post(COMMENTS, data);
}

export function editComment(url,data){
    return axios.post(COMMENTS + url, data);
}

export function deleteComment(url){
    return axios.post(COMMENTS + url);
}

export function createTask(data){
    return axios.post(CREATE_TASK, data);
}

export function getTaskList(){
    return axios.get(GET_TASK_LIST);
}

export function getTask(url){
    return axios.get(GET_TASK_LIST + url);
}

export function updateTask(url, data){
    return axios.post(GET_TASK_LIST + url, data);
}
export function deleteTask(data){

    return axios.delete(CREATE_TASK+"/"+data);
}
export function saveReportIntermediate(url, data){
    return axios.post(GET_TASK_LIST + url, data);
}

export function closeTaskServer(url){
    return axios.post(GET_TASK_LIST + url);
}

export function getOrganizationProfilePictures(){
    return axios.get(GET_PROFILE_ALL_USERS);
}

export function getChatMediaLink(url, data){
    return axios.post(GET_CHAT_DATA + url, data);
}

export function sendNotification(data){
    return axios.post(SEND_TEMP_NOTIFICATION_FORCHAT, data);
}

export function uploadChatData (link, file, type) {
    let options = { headers: { 'Content-Type': type }};
    return axios.put(link, file, options);
}

export function getOrganizationWideUsers(){
    return axios.get(GET_ALL_USERS);
}

export function sendTeamInvite(data) {
    return axios.post(SEND_TEAM_INVITE, data);
}

export function readPost(updateID){
    return axios.post(GET_POST_NEWS + updateID);
}

export function sendPosts(data){
    return axios.post(GET_POST_NEWS, data);
}

export function getTeamPosts(){
    return axios.get(GET_POST_NEWS);
}

export async function logUserAction(data){
    return axios.post(LOG_ACTION, data);
}

export function authSignup(data){
    return axios.post(SIGNUP, data);
}

export function getAllSessions() {
  return axios.get(ALL_SESSIONS);
}

export function getSessionByTaskId(url) {
    return axios.get(ALL_SESSIONS + url);
}

export function getChatData(url){
    return axios.get(GET_CHAT_DATA + url);
}

export function getNoteData(url){
    return axios.get(GET_NOTE_DATA + url);
}

export function postNoteData(data){
    return axios.post(GET_NOTE_DATA, data);
}

export function getSingleSession(sessionID){
    return axios.get(ALL_SESSIONS + "/" + sessionID);
}

export function getTeamSession(url){
    return axios.get(GET_TEAM_SESSION + url + "/team");
}

export function faultFixed(url){
    return axios.post(GET_TEAM_SESSION + url + "/completeTime");
}

export function search(query, isTroubleshooting) {
  return axios.post(SEARCH, { q: query, isTroubleshooting: isTroubleshooting});
}

export function getProcedure(data){
    return axios.get(GET_PROCEDURE, data);
}

export function getSearchResultData(url, data){
    return axios.get(url, data);
}

export function getProfile (requesterID) {
    return axios.get(GET_PROFILE + requesterID);
}

export function getProfileImageLink (requesterID, fileName) {
    return axios.get(GET_PROFILE_LINK + requesterID + fileName);
}

export function uploadProfileImage (link, file) {
    let options = { headers: { 'Content-Type': 'image/*' } };
    return axios.put(link, file, options);
}

export function profileUploaded (data) {
    return axios.post(PROFILE_UPLOADED, data);
}

export function callFunctionalityUsed(data) {
    return axios.post(CALL_MADE, data);
}

export function changeStatus(status){
    return axios.post(CHANGE_STATUS, {status: status});
}

export function getUserProfile(userToBeRequested, requesterID){
    return axios.get(GET_PROFILE + userToBeRequested, { params: {requesterId: requesterID }});
}

export function saveProfile(data){
    return axios.post(SAVE_PROFILE, data);
}

export function getNotifications(){
    return axios.get(GET_NOTIFICATIONS);
}

export function confirmTeamAdd(url, data){
    return axios.post(CONFIRM_TEAM_ADD + url, data);
}

export function markNotificationRead(url){
    return axios.post(GET_NOTIFICATIONS + url);
}

export function confirmAddToSession(url, data){
    return axios.post(CONFIRM_SESSION_ADD + url, data);
}

export function newSession(sessionData){
    return axios.post(START_UPDATE_SESSION, sessionData);
}

export function updateSession(url, sessionData){
    return axios.post(START_UPDATE_SESSION + url, sessionData);
}

export function getTeam(){
    return axios.get(GET_TEAM_LIST); 
}

export function getTeamAllUsers(url){
    return axios.get(GET_TEAM_LIST_ALL + url); 
}

export function deleteTeamMember(url){
    return axios.delete(GET_TEAM_LIST + url);
}

export function logData(data){
    return axios.post(LOG_DATA, data);
}

export function getUnreadTeamUpdates(){
    return axios.get(GET_UNREAD_POSTS); 
}

export function postFeedback(data){
    return axios.get(POST_FEEDBACK, data);
}

export function postStepFeedback(data){
    return axios.post(POST_STEP_FEEDBACK, data);
}

export function sendFirebaseToken(data){
    return axios.post(FIREBASE_CALL, data);
}

export function getAllSolutions(){
    return axios.get(GET_SOLUTIONS);
}

export function saveSolution(data){
    return axios.put(SAVE_SOLUTIONS,data);
}

export function saveNewStep(data, solutionId){
    return axios.put(SOLUTION_ACTIONS + solutionId + SAVE_NEW_STEP, data);
}

export function deleteSolution(id){
    return axios.delete( SOLUTION_ACTIONS +id);
}

export function getSolutionById(id) {
    return axios.get(GET_SOLUTIONS+"/"+id)
}

export function getStepBySolutionId(solutionId) {
    return axios.get(SOLUTION_ACTIONS+solutionId+GET_STEPS);
}

export function updateSolution(solutionData){
    return axios.patch(SAVE_SOLUTIONS, solutionData);
}

export function deleteSteps(solutionId, stepId) {
    return axios.delete(SOLUTION_ACTIONS + solutionId + STEP_ACTIONS + stepId);
}

export function saveEditedStep (solutionId, stepDetails, stepId) {
    return axios.patch(SOLUTION_ACTIONS+solutionId+STEP_ACTIONS+stepId, stepDetails);
}

export async function getTokenOrRefresh() {
    const cookie = new Cookie();
    const speechToken = cookie.get('speech-token');
    if (speechToken === undefined) {
        try {
            const res = await axios.get(SPEECH_TOCKEN);
            const token = res.data.token;
            const region = res.data.region;
            cookie.set('speech-token', region + ':' + token, {maxAge: 540, path: '/'});

            return { authToken: token, region: region };
        } catch (err) {
            console.log(err.response.data);
            return { authToken: null, error: err.response.data };
        }
    } else {
        const idx = speechToken.indexOf(':');
        return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
    }
}