import React, { useState, useEffect, useRef } from 'react';
import { Icon, Input, Modal, List, Form, Image, Button } from 'semantic-ui-react';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getChatData, getChatMediaLink, uploadChatData, sendNotification, getOrganizationProfilePictures } from '../apiCall';
import io from "socket.io-client";
import { SOCKET_EVENTS } from "../../constants/socketEvents.js";
import LexxAskHelpMessage from "./LexxAskHelpMessage";
import userDummy from '../../assets/images/user-dummy.png';
import { saveUsers } from "../../redux/actions/index";

let socket; 
/* Parent component for the Chat which is a modal */
function LexxAskHelpChat(props) {

    const [messages, setMessages] = useState([]); // array of all the message
    const [text, setText] = useState(""); // text typed by the logged in user
    const [sessionID, setSessionId] = useState(""); // session ID it is associated with
    const [allUsers, setUsers] = useState([]); // all users in the chat
    const [onlineUsers, setonlineUserss] = useState([]); // online users at that time in the chat


    /* Function to get all the users, also all the profile images of all the users 
       Also to setup a socket connection and get chat messages 
    */
    const getUsers = () => {
        socket = io();
        if (props.username) {
            var data;
            if(sessionID === "" || props.sessionId === undefined){
                data = { userId: props.username, sessionId: sessionID === "" ? props.sessionId : sessionID };
            }else{
                data = { userId: props.username };
            }
            socket.emit(SOCKET_EVENTS.JOIN_SESSION_TEMP, data, (error) => {
                if (error) {
                    alert(error);
                }
            });
            socket.on(SOCKET_EVENTS.SESSION_DATA_TEMP, ({ sessionId, users }) => {
                setonlineUserss(users);
                if (sessionID === "" && props.sessionId === undefined) {
                    setSessionId(sessionId);
                    sendChatInvite(sessionId);
                }
            });
        }

        getOrganizationProfilePictures().then((response) => {
            props.saveUsers(response.data.data.users);
            setUsers(response.data.data.users);
        });

        if(props.sessionId || sessionID){
            setSessionId(props.sessionId);
            var id = props.sessionId !== undefined ? props.sessionId : sessionID;
            getChatData("?sessionId=" + id).then((response) => {
                setMessages(response.data.data.messages);
            }).catch((error) => {
                console.log(error);
            });
        };

        if (props.username) {
            // first stream to receive and handle messages
            socket.on(SOCKET_EVENTS.MESSAGE, message => {
                setMessages(messages => [...messages, message]);
            });
        }
    }

    useEffect(getUsers, []);
    useEffect(() => { scrollToBottom() }, [messages]);
    

    // Function to send notification to a user for inviting him/her for the chat
    const sendChatInvite = (chatSessionId) => {
        var data = {
            toUserId: props.toUser,
            chatSessionId : chatSessionId
        }
        sendNotification(data);
    }

    // Function to add the message, the message is sent in the socket connection
    const addPost = () => {
        socket.emit(SOCKET_EVENTS.SEND_MESSAGE_TEMP, { data: text, isFile: false }, () => setText(""));
        setText("");
    }

    // Function called on change of text on the message field
    const handleChange = (e) => {
        setText(e.target.value);
    }


    /* Get the profile image of the user based on the parameter
        @name - name of the user whose profile image is to be shown
        onlineUsers is an array with all the users and profile image of them
    */
    const getProfileImage = (name) => {
        if (onlineUsers.length > 0) {
            for (var eachUser of onlineUsers) {
                if (eachUser.userId === name) {
                    return eachUser.profileImg !== "" ? eachUser.profileImg : userDummy;
                }
            }
        } else {
            return null;
        }
    }


    /* Function to load the file to AWS and grab the key and then get the url to show the image */
    const loadFile = (e) => {
        let file = e.target.files[0];
        let fileName = file.name;
        let newFileName = props.username + Date.now() + fileName;
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("app_token");
        let data = { sessionId: sessionID, key: newFileName, type: file.type };
        getChatMediaLink("/uplinks", data).then((result) => {
            if (result.data.code === 'Success') {
                delete axios.defaults.headers.common["Authorization"];
                var blob = file.slice(0, file.size, file.type);
                let newFile = new File([blob], sessionID + "/" + newFileName, { type: file.type });
                uploadChatData(result.data.data.url, newFile, file.type).then((response) => {
                    axios.defaults.headers.common["Authorization"] = localStorage.getItem("app_token");
                    socket.emit(SOCKET_EVENTS.SEND_MESSAGE_TEMP, { data: newFileName, isFile: true }, () => setText(""));
                }).catch((e) => console.log(e))
            }
        })
    }

    const messagesEndRef = useRef(null);
    /* Scroll the user to bottom when message is sent */
    const scrollToBottom = () => {
        setTimeout(function () {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
        }, 500);

    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            addPost();
        }
    }

    /* Function to get the unique users from a set of users to show in online 
        Placed in to fix a bug when multiple users with same name show up in chat
    */
    const getUniqueUsers = () => {
        const unique = [...new Set(onlineUsers.map(user => user.userId))];
        return unique.map((id, index) => <Image className="online-person" key={index} src={getProfileImage(id)} />)
    }


    return (
        <Modal
            closeIcon
            open={props.open}
            onClose={() => props.close(false)}
            onOpen={() => props.close(true)}
            className="chat-container"
            dimmer={'blurring'}
        >
            <Modal.Header>
                Session Chat
                <div className="online-header">
                    {getUniqueUsers()}
                </div>
            </Modal.Header>
            <Modal.Content className="teamList sessionList chatWrapper">
                <List>
                    {Object.keys(allUsers).length > 0 && messages.map((message, i) => <div key={i} className="messageWrapper"><LexxAskHelpMessage message={message} username={props.username} usersInChat={allUsers} /></div>)}
                    <div ref={messagesEndRef} />
                </List>
            </Modal.Content>
            <Modal.Actions>
                <Form.Field>
                    <Button as="label" htmlFor="file" type="button" className="mediaInput">
                        <Icon name="camera" color="grey" size="big" className="marL10" />
                    </Button>
                    <input type="file" id="file" style={{ display: "none" }} onChange={loadFile} accept="video/*,image/*" />
                    <Input placeholder='Write Message' name="text" onChange={handleChange} value={text} onKeyPress={handleKeyPress} />
                    <Icon name="send" color="grey" size="big" className="marL10" onClick={() => addPost()} />
                </Form.Field>
            </Modal.Actions>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        username: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps, { saveUsers })(LexxAskHelpChat));