import React, { useState, useEffect } from 'react';import { Menu, Button, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { setSearchType, saveTaskId, newSessionfromtask} from '../../redux/actions';
import { updateTask } from '../apiCall';

/* Stateless component to show the buttons at the bottom
    based on if the task owner is vieweing it 
    if the person assigned has accepted or not
    if the person has accepted and is not the owner
    */
function BottomPanelButtons(props) {

    // Checking for the readyness of showing bottom buttons while loading the task data
    const [loadButtons , setLoadButtons] = useState(false); 
    const [showAcceptButton, setShowAcceptButton] = useState(true);
    useEffect(()=> {
        if(
            props.ownerView === true || 
            // props.ownerView === false ||
            props.isEditable === true
            // props.isEditable === false 
        ) {
            setLoadButtons(true);
        }
    }, [props.ownerView, props.isEditable])

    const taskdecision = (decision) => {
        props.taskDecision(decision); 
        setShowAcceptButton(false);
    }

    const newSessionClick = () => {
        props.newSessionfromtask(true);
        localStorage.setItem('taskId', props.task.taskId);
        if (props.task.status === 'inprogress') {
            props.setSearchType(true);
            props.saveTaskId(props.task.taskId);
            props.history.push('/')
        } else {
            updateTask("/" + props.task.taskId + "/status", { "status": "inprogress" });
            props.setSearchType(true);
            props.saveTaskId(props.task.taskId);
            props.history.push('/');
        }
    }
    return (
        props.task.acceded && (props.ownerView || props.isEditable) ?
            props.isEditable ?
                props.task.status === 'completed' || props.task.status === 'No Issue Found' ?
                    props.task.report !== undefined ?
                        props.task.report.currStep !== -1 ?
                            <Menu borderless fixed='bottom' id="menu">
                                <Button className='generate-report' onClick={() => props.loadReport()}>
                                    Continue Editing close-out Report
                                </Button>
                            </Menu>
                            :
                            props.task.closed === undefined && props.task.owner.userId === props.userId ?
                            null
                                // <Menu borderless fixed='bottom' id="menu">
                                //     <Button className='generate-report' onClick={() => props.closeTask()}>
                                //         Close Task
                                //     </Button>
                                // </Menu>
                                : null
                        :
                        <Menu borderless fixed='bottom' id="menu">
                            <Button className='generate-report' onClick={() => props.history.push("/createReport/id=" + props.task.taskId)}>
                                Generate Report
                            </Button>
                        </Menu>
                    :
                    <Menu borderless fixed='bottom' id="menu">
                        <Menu.Item position='left'>
                            {/* <Popup content={props.task.status === 'inprogress' ? 'Click here to start a new session' : `Change the status to 'In Progress' to access the session`}
                            trigger={ */}
                                {/* <Button
                                    className='ts-action-btn'
                                    // disabled={props.task.status === 'inprogress' ? false : true}
                                    // onClick={
                                    //     props.task.status === 'inprogress' ? () => {
                                    //     props.setSearchType(true);
                                    //     props.saveTaskId(props.task.taskId);
                                    //     props.history.push('/')
                                    // }:null}
                                    onClick={newSessionClick}
                                >
                                    Access Session
                                </Button> */}
                            {/* }
                            >
                            </Popup> */}
                        </Menu.Item>
                        <Menu.Item position='right'>
                            <Button
                                color='blue'
                                className='ts-action-btn'
                                disabled={props.task.status === 'inprogress' ? false : true}
                                onClick={() => props.handleChange(100)}
                            >
                                Task Completed
                            </Button>
                        </Menu.Item>
                    </Menu>
                // :
                // Commenting this part since Review report is no more part of the flow now.
                // props.task.status === 'completed' && props.task.report !== undefined && props.task.report.currStep === -1 ?
                //     props.task.closed !== undefined ?
                //         <Menu borderless fixed='bottom' id="menu">
                //             <Button className='generate-report' onClick={() => props.history.push("/reviewReportSummary/id=" + props.match.params.id)}>
                //                 View Report
                //             </Button>
                //         </Menu>
                //         :
                //         <Menu borderless fixed='bottom' id="menu">
                //             <Button className='generate-report' onClick={() => props.history.push("/reviewReportSummary/id=" + props.match.params.id)}>
                //                 Review Report
                //             </Button>
                //         </Menu>
                    : null
            : (loadButtons && !props.task.acceded && props.ownerView) &&
            <Menu borderless fixed='bottom' id="menu">
                <Menu.Item position='left'>
                    <Button
                        className='ts-action-btn'
                        disabled={!showAcceptButton}
                        onClick={() => {taskdecision(false)}}
                    >
                        Decline Task
                    </Button>
                </Menu.Item>
                <Menu.Item position='right'>
                    <Button
                        color='blue'
                        disabled={!showAcceptButton}
                        className='ts-action-btn'
                        onClick={() => {taskdecision(true)}}
                    >
                        Accept Task
                    </Button>
                </Menu.Item>
            </Menu>

    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps, { setSearchType, saveTaskId, newSessionfromtask })(BottomPanelButtons));