import React from 'react'
import { Button, Header, Modal } from 'semantic-ui-react'

/* Modal to confirm the addition of user 
  pass the text to show
  and 2 buttons - confirm and cancel */
function LexxMemberModal(props) {
  return (
    <Modal closeIcon open={props.open} className="lexxConfirmModal"
      onClose={() => props.closeDialog(false)}
      onOpen={() => props.closeDialog(true)}
    >
      <Header icon={props.icon} color={props.iconColor} />
      <Modal.Content>
        <p>
          {props.text}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button className={props.classSecondaryBtn} onClick={() => props.confirmOption(false)}>
          {props.buttonSecondaryText}
        </Button>
        <Button color='blue' onClick={() => props.confirmOption(true)}>
          {props.buttonPrimaryText}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default LexxMemberModal;