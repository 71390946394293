import React, { useState, useEffect } from 'react';
import { Image, Input, Icon, List } from 'semantic-ui-react';
import addIcon from '../../assets/images/person_add.png';
import deleteIcon from '../../assets/images/delete.png';
import availableIcon from '../../assets/images/available.png';
import busyIcon from '../../assets/images/busy.png';
import LexxMemberModal from './LexxMemberModal.jsx';
import LexxAddMember from './LexxAddMember.jsx';
import LexxPostNews from './LexxPostNews';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getTeam, deleteTeamMember, getUnreadTeamUpdates } from '../apiCall';
import userDummy from '../../assets/images/user-dummy.png';

/* This is the component to show the team list - for a user  */
function LexxTeamResult(props) {

    const [searchValue, setSearchValue] = useState(""); // search value for team members
    const [teamList, setTeamList] = useState([]); // list of team members which changes when user searches
    const [stableTeam, setStableTeam] = useState([]); // stable list which will contain all team members in the list
    const [showDeleteModal, setShowDeleteModal] = useState(false); // show delete modal
    const [showAddModal, setShowAddModal] = useState(false); // show add new technician modal
    const [showTeamUpdateModal, setShowTeamUpdateModal] = useState(false); // show team updates modal
    const [nametoDelete, setNameToDelete] = useState(""); // person's name to delete to show in modal
    const [userIdtoDelete, setUserIdToDelete] = useState(""); // person's id to delete
    const [unreadCount, setUnreadCount] = useState(0); // unread messages for team update

    /* function called when component is mounted 
        get the team list from server
        also set the variable with the data for search and display
    */
    const getTeamList = () => {
        let mounted = true;
        getTeam().then((response) => {
            if (mounted) {
                setStableTeam(response.data.data.team.length === 0 ? [] : response.data.data.team);
                setTeamList(response.data.data.team.length === 0 ? [] : response.data.data.team);
                getCount(); // call the unread messages for team update
            }
        }).catch((error) => {
            console.log(error);
        });
        return () => {mounted = false};
    }

    useEffect(getTeamList, []);

    /* Get the unread messages for team update from backend
        and set it in variable */ 
    const getCount = () => {
        getUnreadTeamUpdates().then((response) => {
            setUnreadCount(response.data.data.unreadNum);
        }).catch((error) => {
            console.log(error);
        });
    }

    /* When the user searches for a team member 
        filter from the list and check if the value entered matches name or title
        of team member
        @event - event is the variable returned from UI
     */ 
    const searchTeam = (event) => {
        setSearchValue(event.currentTarget.value);
        const filteredData = stableTeam.filter(item => { // filter from list
            return Object.keys(item).some((eachKey) => { // go through the object of each team member
                if (eachKey === "name" || eachKey === "title") {
                    return item[eachKey].toLowerCase().includes(event.currentTarget.value.toLowerCase());
                } else {
                    return null;
                }
            })
        });
        setTeamList(filteredData); // set with the filtered data
    }

    /* function to show the delete modal 
    @isopen - boolean - true (to show) false (hide)
    @name - person name to delete (to show in modal)
    @userId - id of the person to delete
    */
    const showDeleteDialog = (isOpen, name, userId) => {
        setShowDeleteModal(isOpen);
        setNameToDelete(name);
        setUserIdToDelete(userId);
    }

    /* Function when the user confirms to delete a user
        @isDelete - if true then delete the user
        send the request to the backend
    */
    const confirmDelete = (isDelete) => {
        if (isDelete) {
            deleteTeamMember("?targetId=" + userIdtoDelete).then(() => {
                setShowDeleteModal(false);
                getTeamList();
            }).catch((error) => {
                console.log(error);
            });
        } else {
            closeDialog(false);
        }
    }

    /* Function to show the add technician modal
     @isOpen - true (to show the modal), false (hide)
     */
    const addMember = (isOpen) => {
        setShowAddModal(isOpen);
    }

    /* Function to show the team update modal
        @isOpen - true (to show the modal), false (hide)
     */
    const teamUpdate = (isOpen) => {
        setShowTeamUpdateModal(isOpen);
        getCount();
    }

    /* Redirect user to other technician profile */
    const showTeamMemberProfile = (userId) => {
        props.history.push(`/userprofile/id=${userId}`);
    }

    /* Function to close the delete modal */
    const closeDialog = (isOpen) => {
        setShowDeleteModal(isOpen);
    }

    return (
        <div className="teamContainer">
            <div className="teamHeader">
                <div className="left-float">
                    <h2>
                        My Teams
                    </h2>
                </div>
                <div className="right-float">
                    <span className="team-update" onClick={() => teamUpdate(true)}>
                        Team Updates {unreadCount > 0 ? <span> {unreadCount} </span> : null}
                    </span>
                    <Image src={addIcon} className='add-team-icon' onClick={() => addMember(true)} />
                </div>
            </div>
            <div className="searchTeam">
                <Input
                    icon={<Icon name='search' color="black" />}
                    iconPosition='left' placeholder='Search' value={searchValue} onChange={searchTeam} />
            </div>
            <div className="teamList">
                <List>
                    {teamList.length > 0 ?
                        teamList.map((tech, i) => {
                            return (
                                <List.Item key={i} data-testid={`team-item-${i}`}>
                                    {
                                        tech.profileImg !== "" ?
                                            <Image avatar src={tech.profileImg} onClick={() => showTeamMemberProfile(tech.userId)} /> :
                                            <Image avatar src={userDummy} onClick={() => showTeamMemberProfile(tech.userId)} />
                                    }
                                    {tech.status === "available" ?
                                        <Image className="status" src={availableIcon} /> :
                                        <Image className="status" src={busyIcon} />
                                    }
                                    <List.Content>
                                        <List.Header>{tech.name}</List.Header>
                                        <List.Description>
                                            {tech.title}
                                        </List.Description>
                                    </List.Content>
                                    <List.Content floated='right' className="cursor-pointer" onClick={() => showDeleteDialog(true, tech.name, tech.userId)}>
                                        <Image className="delete" src={deleteIcon} />
                                    </List.Content>
                                </List.Item>
                            )
                        }) : null
                    }
                </List>
                {
                    showDeleteModal ?
                        <LexxMemberModal open={showDeleteModal} closeDialog={closeDialog} confirmOption={confirmDelete} icon={"warning sign"} iconColor={"red"}
                            text={"Are you sure you want to remove " + nametoDelete + " from your team?"}
                            buttonPrimaryText="Remove" buttonSecondaryText="Cancel"
                            classSecondaryBtn="grey-btn" />
                        : null
                }
                {
                    showAddModal ?
                        <LexxAddMember open={showAddModal} addMember={addMember} />
                        : null
                }
                {
                    showTeamUpdateModal ?
                        <LexxPostNews open={showTeamUpdateModal} teamUpdate={teamUpdate} />
                        : null
                }

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps)(LexxTeamResult));