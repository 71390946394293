import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Header, Menu, Button, TransitionablePortal, Segment } from 'semantic-ui-react';
import { getSingleSession, getTask, saveReportIntermediate, getSessionByTaskId } from '../apiCall';
import { saveCompletedSessionOptions, saveReduxDraftReport } from "../../redux/actions/index";
import userDummy from '../../assets/images/user-dummy.png';
import chatImg from '../../assets/images/chat_blue.png';
import videoImg from '../../assets/images/video.png';
import photoImg from '../../assets/images/photos.png';
import descriptionImg from '../../assets/images/description.png';
import LexxReportReviewChat from './LexxReportReviewChat';
import LexxReportSessionChatView from './LexxReportSessionChatView';
import LexxReportSessionNotesView from './LexxReportSessionNotesView'

function LexxReportSessionView(props) {

    const [sessionData, setSessionData] = useState({});
    const [sessions, setSessions] = useState([]);  // array to contain the sessions
    const [taskId, setTaskId] = useState("");
    const [taskName, setTaskName] = useState("");
    const [reviewChat, setReviewChat] = useState(false);
    const [taskReportSaved, saveTaskReportSaved] = useState(false);
    const [selectedSession, setSelectedSession] = useState("");
    const [sessionReport, setSessionReport] = useState({});


    /* This function gets all the session data based on session id 
        and then sets it in sessionData variable
    */
    const getSingleSessionFn = () => {
        saveDraftReport();
        saveTaskReportSaved(false);
        if (props.savedSessionOptions.length === props.completedSessionOptions.length) {
            let lastStep = props.draftReport;
            if (lastStep.sessionConfigs !== undefined) {
                lastStep.currStep = lastStep.sessionConfigs.length;
                props.saveReduxDraftReport(lastStep);
            }
            // props.history.push("/reviewReport/id=" + props.match.params.id);
        } else {
            var leftOverSessions = props.savedSessionOptions.filter(function (n) { return !this.has(n) }, new Set(props.completedSessionOptions));
            if (leftOverSessions.length > 0) {
                setSelectedSession(leftOverSessions[0]);
                loadSession(leftOverSessions[0]);
            }
        }
        
    }

    /* If the user decides to load the session
        Load the entire session from redux 
    */
    const loadSession = (sessionId) => {
        getSingleSession(sessionId).then((response) => {
            setSessionData(response.data.data);
            if (response.data.data.taskId !== null) getTaskServerCall(response.data.data.taskId);
        }).catch((error) => {
            console.log(error);
        });
    }

    /* Function gets called if there is a task associated with the session
        Get the task details 
        @id - task id
        It then sets the task in task variable
    */ 
    const getTaskServerCall = (id) => {
        getTask("/" + id).then((response) => {
            setTaskId(response.data.data.task.taskNum);
            setTaskName(response.data.data.task.taskTitle);
        }).catch((error) => {
            console.log(error);
        });
    }

    /* Function to convert UTC date to readable date */
    const getDate = (dateUTC) => {
        return dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
    }

     /* Function to convert UTC date to readable time */
    const getTime = (dateUTC) => {
        if (dateUTC.getMinutes().toString().length === 1) {
            return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
        } else {
            return dateUTC.getHours() + ":" + dateUTC.getMinutes();
        }
    }

    /* Calculate the time spent in the session 
        and display in HH:MM
        completed session time - created session time
    */
    function timeDiffCalc(diffInMilliSeconds) {
        var displayMinutes, displaySeconds;
        const hours = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= hours * 86400;
        const minutes = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= minutes * 3600;
        const seconds = Math.floor(diffInMilliSeconds / 60) % 60;
        displayMinutes = minutes.toString().length > 1 ? minutes + ":" : "0" + minutes + ":";
        displaySeconds = seconds.toString().length > 1 ? seconds : "0" + seconds;
        return hours + ":" + displayMinutes + displaySeconds;
    }

    /* Function is called to calculate the steps completed in a troubleshooting process
        Loop over steps and add the steps in visited Steps variable
    */
    const stepsCalculated = (steps) => {
        var visitedSteps = "";
        steps.forEach((step) => {
            visitedSteps += " > Step " + (parseInt(step.step_id.substr(step.step_id.length - 2)) - 1);
        });
        return visitedSteps;
    }

    /* This function extracts the list of users the session is shared with 
        loop over the list and display they photos and name
        @sharedList - array of users it is shared with
    */
    const getSharedList = (sharedList) => {
        var shared = []
        if (sharedList.length > 0) {
            sharedList.forEach((eachUser, i) => {
                shared.push(<span key={i} className="sharedUser" onClick={() => props.history.push(`/userprofile/id=${eachUser.userId}`)}> <img src={eachUser.avatar !== "" ? eachUser.avatar : userDummy} alt='' /> <span>{eachUser.name} </span> </span>);
            });
            return shared;
        } else {
            return null;
        }
    }

    /* This function saves the report both in redux and in db
        @inNext - if true means go to next page, otherwise it is intermediate report save
    */
    const saveReport = (isNext) => {
        props.saveReduxDraftReport(sessionReport);
        saveReportIntermediate("/" + sessionData.taskId + "/report", { "report": sessionReport }).then((response) => {
    
            if (isNext){
                console.log("enter to response ", isNext,props.savedSessionOptions);
                props.saveCompletedSessionOptions(props.savedSessionOptions);
                props.history.push("/reviewReport/id=" + props.match.params.id);
            }
            
        }).catch((error) => {
            console.log(error);
        });
        saveTaskReportSaved(true);
        
    }

    /* This function checks if the chat is present or not 
        it helps to load report from draft 
        if the user selected save chat for previous session, load it and save it in variable
    */
    const checkForChat = (id) => {
        var completedSessions = props.draftReport.sessionConfigs;
        if(completedSessions !== undefined && props.draftReport.sessionConfigs.length > 0){
            for (var i = 0; i < completedSessions.length; i++) {
                if(completedSessions[i].sessionId === id){
                    return completedSessions[i].hasChat;
                }
            }
            return false;
        }else{
            return false;
        }
    }

   /* Funtion called to save the draft report and update if we should chat or not based on user selection
        @id - session id
        @includeChat - boolean
     */
    const saveDraftReport = (id, includeChat) => {
        // if(id !== undefined){
            var sessions = [];
            for (var i = 0; i < props.savedSessionOptions.length; i++) {
                sessions.push({
                    sessionId: props.savedSessionOptions[i],
                    hasChat: props.savedSessionOptions[i] === id ? includeChat : checkForChat(props.savedSessionOptions[i])
                });
            };
            setSessionReport({ sessionConfigs: sessions, currStep: props.completedSessionOptions.length });
        // }else{
        //     setSessionReport(props.draftReport)
        // }

    }

        /* This function get all the sessions from the server
        and sets it in the variable sessions
    */
        const getSessions = () => {
            getSessionByTaskId("?taskId=" + props.match.params.id).then((response) => {
                let selectedArray = []
                for (let i = 0; i<response.data.data.length; i++) {
                    if (props.savedSessionOptions.includes(response.data.data[i].sessionId)) {
                        selectedArray.push(response.data.data[i])
                    }
                }
                setSessions(selectedArray);
            }).catch((error) => {
                console.log(error);
            });
            getTaskServerCall(props.match.params.id);
        }

    useEffect(getSessions, []);

     /* Function called when back btn is clicked
        either take them to the comments page
        or to the previous session
    */
    const goBack = () => {
        // if (props.completedSessionOptions.length === 0) {
            props.history.push("/reviewSessionReport/id=" + props.match.params.id);
        // } else {
        //     var sessionsTobeReviewed = sessionReport.sessionConfigs;
        //     sessionsTobeReviewed.forEach((function (eachSession, i) {
        //         if (eachSession.sessionId === sessionData.sessionId) {
        //             var filteredArray = props.completedSessionOptions.filter(function (value) {
        //                 return value !== sessionsTobeReviewed[i - 1].sessionId;
        //             });
        //             props.saveCompletedSessionOptions(filteredArray);
        //         }
        //     }))

        // }

    }

    useEffect(getSingleSessionFn, [props.completedSessionOptions]);

    const sessionsReview = () => {
        var sessionsToShow = [];
        var sessionsNeeded = [];
        if (sessions.length > 0) {
            sessions.forEach((session, idx) => {
                console.log(session, idx, 'session, idx,');
                sessionsToShow.push(<>
                <div className="session-details">
                            <div>
                                <h2 className="label">Session {idx + 1}</h2>
                            </div>
                            <div>
                                <p className="label">Session Name</p>
                                <p className="value">{session.troubleshootingData !== null ? session.troubleshootingData.title : ""}</p>
                            </div>
                            <div>
                                <p className="label">Date and Time</p>
                                <p className="value">{getDate(new Date(session.createTime)) + " " + getTime(new Date(session.createTime))}</p>
                            </div>
                            <div>
                                <p className="label">Session owner</p>
                                <p className="value">{session.owner.name?session.owner.name :"Session owner missing"}</p>
                            </div>
                            {/* <div>
                                <p className="label">Shared with</p>
                                <p className="value">{getSharedList(session.summarySharedWith)}</p>
                            </div> */}
                            <div>
                                <p className="label">Resolution</p>
                                <p className="value">{session.completeTime ? "FAULT FIXED" : "IN PROGRESS"}</p>
                            </div>
                            <div>
                                <p className="label">Number of Steps</p>
                                <p className="value">{(session.troubleshootingData !== null && session.troubleshootingData.step_activities) ? Object.keys(session.troubleshootingData.step_activities).length - 1 : "N/A"}</p>
                            </div>
                            <div>
                                <p className="label">Total Time Taken</p>
                                <p className="value">{session.completeTime ? timeDiffCalc(Math.abs(new Date(session.completeTime) - new Date(session.createTime))) : null}</p>
                            </div>
                            <div>
                                <p className="label">Session Chat</p>
                                <LexxReportSessionChatView 
                                sessionID={session.sessionId} 
                                username={session.owner.id} />
                            </div>
                            <div>
                                <p className="label">Session Notes</p>
                                <LexxReportSessionNotesView 
                                sessionID={session.sessionId} 
                                username={session.owner.id}/>
                            </div>
                        </div>
                        <br></br>
                </>)
                // if (sessionsNeeded.includes(session.sessionId)) {
                //     sessionsToShow.push(<SavedSessionCard key={idx} sessionData={session} isClickable={false} />);
                // } else {
                //     return null
                // }
            })
        } else {
            return sessionsToShow;
        }
        return sessionsToShow;
    }

    return (
        <div className="session-summary my-sessions report-sessions">
            <div className="header-container">
                <Header as="h2">Task Report</Header>
                {/* <span className="saveDraft" onClick={() => saveReport(false)}>Save Task Report</span> */}
                <div className="task-details">{taskId !== "" ? taskId + " • " + taskName : ""}</div>
                <div>
                    <span className="filled"></span>
                    <span className="filled"></span>
                    <span className="filled"></span>
                    <span></span>
                </div>
            </div>
            <div className="sliding-cards">
                {sessionsReview()}
            </div>
            {/* <Header as="h3">Session {props.completedSessionOptions.length + 1}</Header>
            {
                Object.keys(sessionData).length > 0 ?
                    <>
                        <div className="session-details">
                            <div>
                                <p className="label">Session Name</p>
                                <p className="value">{sessionData.troubleshootingData !== null ? sessionData.troubleshootingData.title : ""}</p>
                            </div>
                            <div>
                                <p className="label">Date and Time</p>
                                <p className="value">{getDate(new Date(sessionData.createTime)) + " " + getTime(new Date(sessionData.createTime))}</p>
                            </div>
                            <div>
                                <p className="label">Resolution</p>
                                <p className="value">{sessionData.completeTime ? "FAULT FIXED" : "IN PROGRESS"}</p>
                            </div>
                            <div>
                                <p className="label">Number of Steps</p>
                                <p className="value">{sessionData.troubleshootingData !== null ? Object.keys(sessionData.troubleshootingData.step_activities).length - 1 : ""}</p>
                            </div>
                            <div>
                                <p className="label">Total Time Taken</p>
                                <p className="value">{sessionData.completeTime ? timeDiffCalc(Math.abs(new Date(sessionData.completeTime) - new Date(sessionData.createTime))) : null}</p>
                            </div>
                        </div>
                        <Header as="h3">Session breadcrumbs</Header>
                        <div>
                            Confirm Fault {stepsCalculated(sessionData.visitedTroubleshootingSteps)}
                        </div>
                        <Header as="h3" className="attachments">Session attachments</Header>
                        <ul>
                            <li onClick={() => setReviewChat(true)}>
                                <img src={chatImg} alt="" />
                                <span className="record">Chat Records</span>
                                <span className="arrow"> &gt; </span>
                            </li>
                            <li>
                                <img src={descriptionImg} alt="" />
                                <span className="record">Session learnings/notes</span>
                                <span className="arrow"> &gt; </span>
                            </li>
                            <li>
                                <img src={photoImg} alt="" />
                                <span className="record">Photos</span>
                                <span className="arrow"> &gt; </span>
                            </li>
                            <li>
                                <img src={videoImg} alt="" />
                                <span className="record">Videos</span>
                                <span className="arrow"> &gt; </span>
                            </li>
                        </ul>
                    </>
                    : null} */}
            <Menu borderless fixed='bottom' id="menu">
                <Menu.Item position='left'>
                    <Button className='secondarybtn' onClick={() => goBack()}>
                        Back
                    </Button>
                </Menu.Item>
                <Menu.Item position='right'>
                    <Button className='primarybtn' onClick={() => saveReport(true)}>
                        Save and Continue
                    </Button>
                </Menu.Item>
            </Menu>
            {
                reviewChat ? <LexxReportReviewChat open={reviewChat} chatWindow={setReviewChat} saveDraftReport={saveDraftReport} sessionId={sessionData.sessionId} save={true} /> : null
            }
            <TransitionablePortal
                open={taskReportSaved}
                onClose={() => saveTaskReportSaved(false)}
            >
                <Segment
                    style={{ left: '25%', position: 'fixed', top: '2%', zIndex: 11000, width: '220px' }}
                    className='green-error'
                >
                    <p>Task Report Saved</p>
                </Segment>
            </TransitionablePortal>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        savedSessionOptions: state.savedSessionOptions,
        completedSessionOptions: state.completedSessionOptions,
        draftReport: state.draftReport
    };
};

export default withRouter(connect(mapStateToProps, { saveCompletedSessionOptions, saveReduxDraftReport })(LexxReportSessionView));