import React, { useState, useEffect } from 'react';
import { Icon, Input, Modal, List, Image } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTeamSession, inviteIntoSession } from '../apiCall';
import faultFixedImg from '../../assets/images/fault_fixed.png';
import userDummy from '../../assets/images/user-dummy.png';

/* This component(modal) is shown when a user needs to be invited to a session */
function LexxInviteToSession(props) {

    const [search, setSearch] = useState(""); // search query for users
    const [stableOrganizationList, setStableOrganizationList] = useState([]); // entire list of users in your team
    const [organizationList, setOrganizationList] = useState([]); // entire list of users in team which changes when user seaches

    /* Get all the users from the db in your team and set it in variable */
    const getUsers = () => {
        getTeamSession(props.sessionID).then((response) => {
            setStableOrganizationList(response.data.data.team.length === 0 ? [] : response.data.data.team);
            setOrganizationList(response.data.data.team.length === 0 ? [] : response.data.data.team);
        })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(getUsers, []);

    /* When the technician is searched, filter and set the setOrganizationList,it will re-render */
    const changeSearch = (event) => {
        setSearch(event.currentTarget.value);
        const filteredData = stableOrganizationList.filter(item => {
            return Object.keys(item).some((eachKey) => {
                if (eachKey === "name" || eachKey === "title") {
                    return item[eachKey].toLowerCase().includes(event.currentTarget.value.toLowerCase());
                } else {
                    return null;
                }
            })
        });
        setOrganizationList(filteredData);
    }

    /* Redirect the user to their profile */
    const showTeamMemberProfile = (userId) => {
        props.history.push(`/userprofile/id=${userId}`);
    }

    /* Send the invite request to the technician 
        @invitee - technician email id the invite needs to be sent
        @requested - boolean value, if true request will be sent
    */
    const inviteUserToSession = (invitee, requested) => {
        if (!requested) {
            const data = {
                inviteeId: invitee,
                sessionId: props.sessionID
            };
            inviteIntoSession(data).then((response) => {
                getUsers(); // get the users list again
            }).catch((e) => {
                console.log(e);
            })
        }
    }

    return (
        <Modal
            closeIcon
            open={props.open}
            onClose={() => props.inviteMember(false)}
            onOpen={() => props.inviteMember(true)}
            className="addMember-container"
        >
            <Modal.Header>Invite a colleague</Modal.Header>
            <div className="searchTeam">
                <Input
                    icon={<Icon name='search' color="black" />}
                    iconPosition='left' placeholder='Search' value={search} onChange={changeSearch} />
            </div>
            <Modal.Content className="teamList sessionList">
                <List>
                    {organizationList.map((tech, i) => {
                        if (tech.name !== undefined) {
                            return (
                                <List.Item key={i}>
                                    {
                                        tech.profileImg !== "" ?
                                            <Image avatar src={tech.profileImg} onClick={() => showTeamMemberProfile(tech.userId)} />
                                            :
                                            <Image avatar src={userDummy} onClick={() => showTeamMemberProfile(tech.userId)}/>
                                    }
                                    <List.Content>
                                        <List.Header>{tech.name}</List.Header>
                                        <List.Description>
                                            {tech.title}
                                        </List.Description>
                                    </List.Content>
                                    <List.Content floated='right' className="cursor-pointer color-blue" onClick={() => inviteUserToSession(tech.userId, tech.pendingInvite)}>
                                        {
                                            tech.pendingInvite ?
                                                <>
                                                    {/* <Image src={faultFixedImg} className='sessionInvite' /> Invitation Sent */}
                                                    <Icon name="check" className="marT10" />  Invitation Sent

                                                </>
                                                :
                                                <>
                                                    <Icon name="add" className="marT10" /> Invite to Session
                                                </>
                                        }
                                    </List.Content>
                                </List.Item>
                            )
                        } else {
                            return null;
                        }

                    })
                    }
                </List>
            </Modal.Content>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        sessionID: state.sessionData.sessionID
    };
};

export default withRouter(connect(mapStateToProps)(LexxInviteToSession));