import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Icon, Header, Menu, Button, TransitionablePortal, Segment } from 'semantic-ui-react';
import { getSingleSession, shareSession as shareSessionAPI, getTask } from '../apiCall';
import LexxShareSession from './LexxShareSession';
import chatImg from '../../assets/images/chat_blue.png';
import videoImg from '../../assets/images/video.png';
import photoImg from '../../assets/images/photos.png';
import descriptionImg from '../../assets/images/description.png';
import userDummy from '../../assets/images/user-dummy.png';
import { saveSearchResults, saveTroubleshootingData, saveTroubleshootingOption, saveTroubleshootingStep, setSearchType, saveVisitedTroubleshootingSteps, saveSearchQuery, saveSessionData, saveSessionUsers } from '../../redux/actions';
import LexxReviewChat from './LexxReviewChat';
import LexxReviewNotes from './LexxReviewNotes';

/* This component shows a single session summary */
function LexxSessionSummary(props) { 

    const [sessionData, setSessionData] = useState({});
    const [shareSession, setShareSession] = useState(false);
    const [reviewChat, setReviewChat] = useState(false);
    const [reviewNotes, setReviewNotes] = useState(false);
    const [error, setError] = useState(false);
    const [requestSent, setRequestSent] = useState(false);
    const [taskId, setTaskId] = useState("");
    const [taskName, setTaskName] = useState("");

    /* This function gets all the session data based on session id 
        and then sets it in sessionData variable
    */
    const getSingleSessionFn = () => {
        getSingleSession(props.match.params.id).then((response) => {
            setSessionData(response.data.data)
            props.saveSessionData({ sessionID: response.data.data.sessionId});
            if (response.data.data.taskId !== null) getTaskServerCall(response.data.data.taskId);
        }).catch((error) => {
            console.log(error);
        });
    }

    /* Function gets called if there is a task associated with the session
        Get the task details 
        @id - task id
        It then sets the task id and name in taskId and taskName variable
    */ 
    const getTaskServerCall = (id) => {
        getTask("/" + id).then((response) => {
            setTaskId(response.data.data.task.taskNum);
            setTaskName(response.data.data.task.taskTitle);
        }).catch((error) => {
            console.log(error);
        });
    }

    /* If the user decides to load the session
        Load the entire session from redux 
        */
    const loadSession = () => {
        let userDetails = [];
        props.saveSessionData({ sessionID: sessionData.sessionId, isOwner: props.userId === sessionData.owner.userId ? true : false, isShared: isShared(props.userId) });
        props.saveSearchResults(sessionData.searchResults);
        props.saveTroubleshootingData(sessionData.troubleshootingData);
        props.saveTroubleshootingOption(sessionData.troubleshootingOption);
        props.saveTroubleshootingStep(sessionData.troubleshootingStep);
        props.setSearchType(sessionData.isTroubleshooting);
        props.saveVisitedTroubleshootingSteps(sessionData.visitedTroubleshootingSteps);
        props.saveSearchQuery(sessionData.searchQuery);
        userDetails.push(sessionData.owner, ...sessionData.guests);
        props.saveSessionUsers(userDetails);
        props.history.push(sessionData.routerPath);
    }

    /* Function to convert UTC date to readable date */
    const getDate = (dateUTC) => {
        return dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
    }

    /* Function to convert UTC date to readable time */
    const getTime = (dateUTC) => {
        if (dateUTC.getMinutes().toString().length === 1) {
            return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
        } else {
            return dateUTC.getHours() + ":" + dateUTC.getMinutes();
        }
    }

    /* Calculate the time spent in the session 
        and display in HH:MM
        completed session time - created session time
    */
    function timeDiffCalc(diffInMilliSeconds) {
        var displayMinutes, displaySeconds;
        const hours = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= hours * 86400;
        const minutes = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= minutes * 3600;
        const seconds = Math.floor(diffInMilliSeconds / 60) % 60;
        displayMinutes = minutes.toString().length > 1 ? minutes + ":" : "0" + minutes + ":";
        displaySeconds = seconds.toString().length > 1 ? seconds : "0" + seconds;
        return hours + ":" + displayMinutes + displaySeconds;
    }

    /* Function is called to calculate the steps completed in a troubleshooting process
        Loop over steps and add the steps in visited Steps variable
    */
    const stepsCalculated = (steps) => {
        var visitedSteps = "";
        steps.forEach((step) => {
            visitedSteps += " > Step " + (parseInt(step.step_id.substr(step.step_id.length - 2)) - 1);
        });
        return visitedSteps;
    }

    /* This function shares the session with user
        @id - person the session should be shared with 
    */
    // const shareSessionWithUser = (id) => {
    //     var data = { "toUserId": id, "sessionId": sessionData.sessionId };
    //     shareSessionAPI(data).then((response) => {
    //         setShareSession(false);
    //         setError(true);
    //         setRequestSent(true);
    //         if (response.data.code === "Success") getSingleSessionFn();
    //     }).catch((error) => {
    //         setShareSession(false);
    //         setRequestSent(false);
    //         setError(true);
    //         console.log(error);
    //     });
    // }

    /* This function is to check if the session is shared with a 
        particular user
        @user - user email id
    */
    const isShared = (user) => {
        var found = false;
        if (sessionData.summarySharedWith.length > 0) {
            sessionData.summarySharedWith.forEach((eachUser) => {
                if (user === eachUser.userId) {
                    found = true;
                }
            });
            return found;
        } else {
            return found;
        }
    }

    /* This function extracts the list of users the session is shared with 
        loop over the list and display they photos and name
        @sharedList - array of users it is shared with
    */
    const getSharedList = (sharedList) => {
        var shared = []
        if (sharedList.length > 0) {
            sharedList.forEach((eachUser, i) => {
                shared.push(<span key={i} className="sharedUser" onClick={() => props.history.push(`/userprofile/id=${eachUser.userId}`)}> <img src={eachUser.avatar !== "" ? eachUser.avatar : userDummy} alt='' /> <span>{eachUser.name} </span> </span>);
            });
            return shared;
        } else {
            return null;
        }
    }

    /* If the view is of session owner, then he/she can share session
        Set the share session to true, to show modal
    */
    const openSession = () => {
        setError(false);
        setShareSession(true)
    }

    useEffect(getSingleSessionFn, []);

    return (
        <>
            {
                Object.keys(sessionData).length > 0 ?
                    <div className="session-summary">
                        <div>
                            <Header as="h2">Session Summary</Header>
                            {/* {
                                props.userId === sessionData.owner.userId ? <Icon name="share alternate" color="blue" size='large' onClick={() => openSession()} /> : null
                            } */}

                        </div>
                        <div className="task-details">{taskId !== "" ? taskId + " • " + taskName : ""}</div>
                        <div className="session-details">
                            <div>
                                <p className="label">Session Name</p>
                                <p className="value">{sessionData.troubleshootingData.title}</p>
                            </div>
                            <div>
                                <p className="label">Date and Time</p>
                                <p className="value">{getDate(new Date(sessionData.createTime)) + " " + getTime(new Date(sessionData.createTime))}</p>
                            </div>
                            <div>
                                <p className="label">Shared with</p>
                                {/* <p className="value">{getSharedList(sessionData.summarySharedWith)}</p> */}
                                <p className="value">{sessionData.guests.length > 0 ? getSharedList(sessionData.guests) : 'N/A'}</p>
                            </div>
                            <div>
                                <p className="label">Resolution</p>
                                <p className="value">{sessionData.completeTime ? "FAULT FIXED" : "IN PROGRESS"}</p>
                            </div>
                            {
                                sessionData.troubleshootingData.step_activities && 
                                <div>
                                    <p className="label">Number of Steps</p>
                                    <p className="value">{Object.keys(sessionData.troubleshootingData.step_activities).length - 1}</p>
                                </div>
                            }
                            <div>
                                <p className="label">Total Time Taken</p>
                                <p className="value">{sessionData.completeTime ? timeDiffCalc(Math.abs(new Date(sessionData.completeTime) - new Date(sessionData.createTime))) : null}</p>
                            </div>
                        </div>
                        <Header as="h3">Session breadcrumbs</Header>
                        <div>
                            Confirm Fault {stepsCalculated(sessionData.visitedTroubleshootingSteps)}
                        </div>
                        <Header as="h3" className="attachments">Session attachments</Header>
                        <ul>
                            <li onClick={() => setReviewChat(true)}>
                                <img src={chatImg} alt="" />
                                <span className="record">Chat Records</span>
                                <span className="arrow"> &gt; </span>
                            </li>
                            <li onClick={() => setReviewNotes(true)}>
                                <img src={descriptionImg} alt="" />
                                <span className="record">Session learnings/notes</span>
                                <span className="arrow"> &gt; </span>
                            </li>
                            {/* <li>
                                <img src={photoImg} alt="" />
                                <span className="record">Photos</span>
                                <span className="arrow"> &gt; </span>
                            </li>
                            <li>
                                <img src={videoImg} alt="" />
                                <span className="record">Videos</span>
                                <span className="arrow"> &gt; </span>
                            </li> */}
                        </ul>
                        <Menu borderless fixed='bottom' id="menu">
                            <Menu.Item position='left'>
                                <Button className='secondarybtn' onClick={() => props.history.goBack()}>
                                    Back
                                </Button>
                            </Menu.Item>
                            <Menu.Item position='right'>
                                <Button className='primarybtn' onClick={() => loadSession()}>
                                    Enter Session
                                </Button>
                            </Menu.Item>
                        </Menu>
                    </div>
                    : null
            }
            {/* {
                shareSession ? <LexxShareSession open={shareSession} closeDialog={setShareSession} shareSessionWithUser={shareSessionWithUser} /> : null
            } */}
            {
                reviewChat ? <LexxReviewChat open={reviewChat} chatWindow={setReviewChat} /> : null
            }
            {
                reviewNotes ? <LexxReviewNotes open={reviewNotes} noteWindow={setReviewNotes} /> : null
            }
            <TransitionablePortal
                open={error}
                onClose={() => setError(false)}
            >
                <Segment
                    style={{ left: '25%', position: 'fixed', top: '2%', zIndex: 11000, width: '220px' }}
                    className={requestSent ? 'green-error' : 'red-error'}
                >
                    {
                        requestSent ?
                            <p>Request sent to the user</p> :
                            <p>Session already shared with the user</p>
                    }

                </Segment>
            </TransitionablePortal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps, {
    saveSearchResults,
    saveTroubleshootingData,
    saveTroubleshootingOption,
    saveTroubleshootingStep,
    setSearchType,
    saveVisitedTroubleshootingSteps,
    saveSearchQuery,
    saveSessionData,
    saveSessionUsers
})(LexxSessionSummary));