import React, { useState, useEffect } from 'react';
import { Image, List } from 'semantic-ui-react';
import LexxMemberModal from '../LexxTeam/LexxMemberModal.jsx';
import LexxDeclineModal from "../LexxTeam/LexxDeclineModal.jsx";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import LexxAskHelpChat from '../LexxDashboard/LexxAskHelpChat.jsx';
import { saveSearchResults, saveTroubleshootingData, saveTroubleshootingOption, saveTroubleshootingStep, setSearchType, saveVisitedTroubleshootingSteps, saveSearchQuery, saveSessionData, saveSessionUsers,fromTask } from '../../redux/actions';
import { getNotifications, confirmTeamAdd, markNotificationRead, confirmAddToSession, getSingleSession } from '../apiCall';
import userDummy from '../../assets/images/user-dummy.png';

/* This is the component to show the notification messages */
function Notifications(props) {

    const [notifications, setNotifications] = useState([]); // notification array
    const [showTeamInviteModal, setShowTeamInviteModal] = useState(false); // if notification for invite
    const [nameToAdd, setNameToAdd] = useState(""); // name to show for the notification
    const [notficationId, setNotificationId] = useState(""); // notification identifier
    const [showSessionInviteModal, setShowSessionInviteModal] = useState(false); // show the modal for session invite
    const [showTaskDecline, setShowTaskDecline] = useState(false); // if task invite is rejected, show the modal for reason
    const [chatModal, setChatModal] = useState([false, ""]); // chat modal to show or not
    const [notificationChosen, setNotificationChosen] = useState(""); // notification choosen string

    /* To get the notification data from server */
    const getNotification = () => {
        getNotifications().then((response) => {
            setNotifications(response.data.data.notifications);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    useEffect(getNotification, []);
    useEffect(()=> { 
        props.fromTask(false)},[]);

    /* Date to present in the UI */
    const getDate = (dateUTC) => {
        return dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
    }

    /* Time to present in the UI */
    const getTime = (dateUTC) => {
        if (dateUTC.getMinutes().toString().length === 1) {
            return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
        } else {
            return dateUTC.getHours() + ":" + dateUTC.getMinutes();
        }
    }

    /* This is to format the message and show it in the UI based on the type of notification */
    const getMessage = (type) => {
        switch (type) {
            case 'TEAM_INVITE':
                return 'invited you to join their team';
            case 'Message':
                return 'sent you a message';
            case 'SESSION_INVITE':
                return 'invited you into a session';
            case 'TASK_ACCEPT':
                return 'accepted the task ';
            case 'TASK_DECLINE':
                return 'declined the task ';
            case 'TASK_STATUS_UPDATE':
                return 'has marked this ';
            case 'SESSION_SUMMARY':
                return 'has shared session data with you'
            case 'REPORT_COMPLETION':
                return 'has completed a report for a task.'
            case 'TEMP_CHAT_INVITE':
                    return 'wants to chat with you.'
            default:
                return 'assigned a task to you ';
        }
    }

    /* Get the height of the notification so that background can be updated for CSS */
    const getHeight = () => {
        return window.outerHeight > (notifications.length * 100) ? window.outerHeight + 'px' : (notifications.length * 100) + 'px';
    }

    /* Mark the notification as read */
    const markasRead = (notificationClicked) => {
        markNotificationRead("/" + notificationClicked.notificationId + "/read");
    }


    /* Function called when notification is clicked and then based on the type the variables are set */
    const notificationClickedFn = (notificationClicked) => {
        if (!notificationClicked.read || notificationClicked.type === 'SESSION_SUMMARY') {
            switch (notificationClicked.type) {
                case 'TEAM_INVITE':
                    markasRead(notificationClicked); // notification is marked as read
                    setShowTeamInviteModal(true);
                    setNameToAdd(notificationClicked.source.name); // name who invited you
                    setNotificationId(notificationClicked.notificationId); // notification ID
                    getNotification();
                    break;
                case 'SESSION_INVITE':
                    markasRead(notificationClicked);
                    setShowSessionInviteModal(true);
                    setNameToAdd(notificationClicked.source.name);
                    setNotificationId(notificationClicked.notificationId);
                    getNotification();
                    break;
                case 'SESSION_SUMMARY':
                    markasRead(notificationClicked);
                    props.history.push("session/id=" + notificationClicked.sessionId);
                    break;
                case 'TASK_INVITE':
                    props.history.push("viewTask/id=" + notificationClicked.taskId + "/notifId=" + notificationClicked.notificationId);
                    break;
                case 'TASK_ACCEPT':
                    markasRead(notificationClicked);
                    getNotification();
                    break;
                case 'REPORT_COMPLETION':
                    markasRead(notificationClicked);
                    props.history.push("viewTask/id=" + notificationClicked.taskId);
                    break;
                case 'TASK_DECLINE':
                    setNotificationChosen(notificationClicked);
                    setShowTaskDecline(true);
                    markasRead(notificationClicked);
                    getNotification();
                    break;
                case 'TASK_STATUS_UPDATE':
                    markasRead(notificationClicked);
                    props.history.push("viewTask/id=" + notificationClicked.taskId);
                    break;
                case 'TEMP_CHAT_INVITE':
                    markasRead(notificationClicked);
                    getNotification();
                    setChatModal([true, notificationClicked.chatSessionId]);
                    break;
                default:
                    markasRead(notificationClicked);
                    getNotification();
                    break;
            }
        }
    }

    /* This is the function which sends data to user based on if the user accepts the invite or not
     @accept - is the boolean value related to if the user accepted or not
        */
    const confirmSessionInvite = (accept) => {
        markNotificationRead("/" + notficationId + "/read");
        if (accept === false) {
            getNotification();
        }
        const data = {
            accepted: accept === false ? false : true
        }
        confirmAddToSession(notficationId, data).then((response) => {
            setShowSessionInviteModal(false);
            extractSession(response.data.data.sessionId);
        }).catch((error) => {
            console.log(error);
        });
    }

    /* This function helps to load the session and all the redux variables are set from the data received from server */
    const loadSession = (sessionState) => {
        let userDetails = [];
        props.saveSessionData({ sessionID: sessionState.sessionId, isOwner: props.userId === sessionState.owner.userId ? true : false });
        props.saveSearchResults(sessionState.searchResults); // load the search results
        props.saveTroubleshootingData(sessionState.troubleshootingData); // the troubleshooting data if any
        props.saveTroubleshootingOption(sessionState.troubleshootingOption); // troubleshooting option the iuser has completed
        props.saveTroubleshootingStep(sessionState.troubleshootingStep); // troubleshooting step the user is at
        props.setSearchType(sessionState.isTroubleshooting); // is troubleshooting or not
        props.saveVisitedTroubleshootingSteps(sessionState.visitedTroubleshootingSteps); // visited steps
        props.saveSearchQuery(sessionState.searchQuery); //search query
        userDetails.push(sessionState.owner, ...sessionState.guests);
        props.saveSessionUsers(userDetails);
        props.history.push(sessionState.routerPath);
    }

    /* Extract the session data when the session is shared
        loadsession is called with the response data to load the session
    */
    const extractSession = (sessionID) => {
        getSingleSession(sessionID).then((response) => {
            loadSession(response.data.data)
        }).catch((error) => {
            console.log(error);
        });
    }


    /* Function to confirm accepting or declining the request for team */
    const confirmAdd = (accept) => {
        const data = {
            accepted: accept === false ? false : true
        }
        confirmTeamAdd(notficationId, data).then((response) => { // send data to server
            setShowTeamInviteModal(false);
            getNotification();
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <div className="notificationsContainer" style={{ height: getHeight() }}>
            <List>
                {notifications.map((eachNotification, i) => {
                    return (
                        <List.Item key={i} className={eachNotification.read ? "read" : "unread"}>
                            <Image avatar src={userDummy} onClick={() => props.history.push(`/userprofile/id=${eachNotification.source.id}`) }/> 
                            {/* {
                                eachNotification.source.avatar !== "" ?
                                <Image avatar src={eachNotification.source.avatar} onClick={() => props.history.push(`/userprofile/id=${eachNotification.source.id}`) }/> 
                                :
                                <Image avatar src={userDummy} onClick={() => props.history.push(`/userprofile/id=${eachNotification.source.id}`) }/> 
                            } */}   
                           
                            <List.Content onClick={() => notificationClickedFn(eachNotification)}>
                                <List.Header>{eachNotification.source.name}
                                    <span> {getMessage(eachNotification.type)}
                                        {eachNotification.type.includes("TASK") ? <b>{eachNotification.taskTitle}</b> : null}
                                        {eachNotification.type === "TASK_STATUS_UPDATE" && eachNotification.toStatus !== undefined ? " as " + eachNotification.toStatus.charAt(0).toUpperCase() + eachNotification.toStatus.slice(1) + "." : null}
                                    </span>
                                </List.Header>
                                <List.Description>
                                    <span> {getDate(new Date(eachNotification.createTime))} </span>
                                    <span> at </span>
                                    <span> {getTime(new Date(eachNotification.createTime))} </span>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    )
                })
                }
            </List>
            {
                showTeamInviteModal ?
                    <LexxMemberModal open={showTeamInviteModal} closeDialog={setShowTeamInviteModal} confirmOption={confirmAdd} icon={"add user"} iconColor={"blue"}
                        text={nameToAdd + " invited you to their team "}
                        buttonPrimaryText="Accept" buttonSecondaryText="Decline"
                        classSecondaryBtn="red-btn" />
                    : null
            }
            {
                showSessionInviteModal ?
                    <LexxMemberModal open={showSessionInviteModal} closeDialog={setShowSessionInviteModal} confirmOption={confirmSessionInvite} icon={"add user"} iconColor={"blue"}
                        text={nameToAdd + " invited you into their session"}
                        buttonPrimaryText="Accept" buttonSecondaryText="Decline"
                        classSecondaryBtn="red-btn" />
                    : null
            }
            {
                showTaskDecline ?
                    <LexxDeclineModal open={showTaskDecline} closeDialog={setShowTaskDecline} notificationChosen={notificationChosen} />
                    : null
            }
            {
                chatModal[0] ?
                    <LexxAskHelpChat open={chatModal} close={setChatModal} sessionId={chatModal[1]} /> : null
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps, {
    saveSearchResults,
    saveTroubleshootingData,
    saveTroubleshootingOption,
    saveTroubleshootingStep,
    setSearchType,
    saveVisitedTroubleshootingSteps,
    saveSearchQuery,
    saveSessionData,
    saveSessionUsers,fromTask
})(Notifications));