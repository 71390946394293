export const SOCKET_EVENTS = {
    SESSION_DATA: "SESSION_DATA",
    MESSAGE: "MESSAGE",
    SEEN_DATA: "SEEN_DATA",
    JOIN_SESSION: "JOIN_SESSION",
    SEND_MESSAGE: "SEND_MESSAGE",
    SEND_ACK_MESSAGE: "SEND_ACK_MESSAGE",
    UNREAD_COUNT : "UNREAD_COUNT",
    JOIN_SESSION_TEMP: 'JOIN_SESSION_TEMP',
    SESSION_DATA_TEMP: 'SESSION_DATA_TEMP',
    SEND_MESSAGE_TEMP: "SEND_MESSAGE_TEMP"
};
// Socket events used for chat in xTeams