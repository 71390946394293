import React, { useState, useEffect } from 'react';
import { Icon, Input, Modal, List, Image } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sendTeamInvite, getOrganizationWideUsers } from '../apiCall';
import dummyUser from '../../assets/images/user-dummy.png';

/* Modal for Adding a Member to your Team */
function LexxAddMember(props) {

    /* Search bar State */
    const [search, setSearch] = useState("");
    /* Organisation List that never changes */
    const [stableOrganizationList, setStableOrganizationList] = useState([]);
    /* Organisation List that changes on search */
    const [organizationList, setOrganizationList] = useState([]);

    /* Get users from the DB */
    const getUsers = () => {
        getOrganizationWideUsers().then((response) => {
            setStableOrganizationList(response.data.data.users); // set to stable list for search
            setOrganizationList(response.data.data.users); // set to organization list which is reduced to when searched
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(getUsers, []);

    /* Method called on Search to filter user list */
    const changeSearch = (event) => {
        setSearch(event.currentTarget.value);
        const filteredData = stableOrganizationList.filter(item => {
            return Object.keys(item).some((eachKey) => {
                if (eachKey === "name" || eachKey === "title") {
                    return item[eachKey].toLowerCase().includes(event.currentTarget.value.toLowerCase());
                } else {
                    return null;
                }
            })
        });
        setOrganizationList(filteredData);
    }

    /* Method to take user to the user Profile */
    const showTeamMemberProfile = (userId) => {
        props.history.push(`/userprofile/id=${userId}`);
    }

    /* Method to send the add request */
    const addMember = (invitee) => {
        const data = { inviteeId: invitee };
        sendTeamInvite(data).then((response) => {
            getUsers();
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Modal
            closeIcon
            open={props.open}
            onClose={() => props.addMember(false)}
            onOpen={() => props.addMember(true)}
            className="addMember-container"
        >
            <Modal.Header>Add people to my team</Modal.Header>
            <div className="searchTeam">
                <Input
                    icon={<Icon name='search' color="black" />}
                    iconPosition='left' placeholder='Search' value={search} onChange={changeSearch} />
            </div>
            <Modal.Content className="teamList">
                <List>
                    {organizationList.map((tech, i) => {
                        if (tech.name !== undefined) {
                            return (
                                <List.Item key={i}>
                                    <Image avatar src={tech.profileImg !== "" ? tech.profileImg : dummyUser} onClick={() => showTeamMemberProfile(tech.userId)} />
                                    <List.Content>
                                        <List.Header>{tech.name}</List.Header>
                                        <List.Description>
                                            {tech.title}
                                        </List.Description>
                                    </List.Content>
                                    {
                                        tech.invited !== undefined && tech.invited ?
                                            <List.Content floated='right' className="cursor-pointer pending-request">
                                                Pending
                                            </List.Content>
                                            :
                                            <List.Content floated='right' className="cursor-pointer color-blue" data-testid={'add-member'} onClick={() => addMember(tech.userId)}>
                                                <Icon name="add" className="marT10" /> add
                                            </List.Content>
                                    }

                                </List.Item>
                            )
                        } else {
                            return null;
                        }

                    })
                    }
                </List>
            </Modal.Content>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps)(LexxAddMember));