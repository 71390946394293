import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Sidebar } from "semantic-ui-react";
import { changeSidebarVisibility } from "../redux/actions";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import Header from "./menu/Header";
import SidebarComp from "./menu/SidebarComp";
import TroubleshootingHome from "./troubleshooting/TroubleshootingHome";
import LexxFeedback from "./LexxFeedback/LexxFeedback";
import TroubleshootingAction from "./troubleshooting/TroubleshootingAction";
import ThankYouHome from "./thankyou/ThankYouHome";
import Procedure from "./procedure/Procedure";
import LexxSearchResults from "./LexxSearchResults/LexxSearchResults";
import LexxSearchResultsXviewer from "./LexxSearchResults/LexxSearchResultsXviewer.jsx";
import LexxTroubleshootingOptions from "./LexxTroubleshootingOptions/LexxTroubleshootingOptions";
import LexxWorkorder from "./LexxWorkorder/LexxWorkorder";
import LexxSchematicView from "./LexxSchematicContainer/LexxSchematicView";
import LexxDashboard from "./LexxDashboard/LexxDashboard";
import { loginAction, saveActiveTime } from "../redux/actions/index";
import LexxIdlePopup from "../components/LexxIdlePopup/LexxIdlePopup";
import IdleTimer from "react-idle-timer";
import LexxTeamResult from "./LexxTeam/LexxTeamResult";
import UserProfile from "./profile/UserProfile";
import Notifications from "./menu/Notifications";
import EditProfile from "../components/profile/EditUserProfile";
import Sessions from "./LexxSession/LexxAllSessions";
import LexxSessionSummary from "./LexxSession/LexxSessionSummary";
import { logData } from "./apiCall";
import KeycloakUserService from "../components/login/KeyCloakService";
import AddSolutionsFaultDetails from "./AddSolution/AddSolutionsFaultDetails";
import AddSolutionsSteps from "./AddSolution/AddSolutionsSteps";
import NewStep from "./AddSolution/NewStep";
import NewSolutions from "./AddSolution/NewSolutions";
import SolutionSummary from "./AddSolution/SolutionSummary";
import Tasks from "./LexxTask/LexxAllTask";
import ViewTask from "./LexxTask/LexxTask";
import CreateReport from "./LexxReport/LexxReviewComments";
import LexxReportSessions from "./LexxReport/LexxReportSessions";
import ReviewTaskReport from "./LexxReport/LexxReportSummary";
import LexxReportGenerated from "./LexxReport/LexxReportGenerated";
import LexxReportSummaryReview from "./LexxReportReview/LexxReportSummaryReview";
import LexxReportSessionReview from "./LexxReportReview/LexxReportSessionReview";
import LexxReports from "./LexxReport/LexxReports";
import LexxScanner from './LexxQRCode/LexxScanner';
import LexxReportSessionView from "./LexxReport/LexxReportSessionView";
import NewProcedure from "./procedure/NewProcedure.jsx";


const App = (props) => {

  const [showModal, setShowModal] = useState(false);
  const timeout = 1000 * 60 * 30; // 30mins
  const [timer, setTimer] = useState(null);
  const [isTimedOut, setIsTimeOut] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);



  const onActive = () => {
    setIsTimeOut(false);
  };

  const onIdle = (e) => {
    if (!isTimedOut) {
      setShowModal(true);
      setTimer(null);
      setIsTimeOut(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the user presses Ctrl+P (key code 80) on Windows/Linux or Command+P (key code 80) on macOS
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 80) {
        event.preventDefault(); // Prevent the default Ctrl+P behavior (print)
        handlePrint(); // Call your custom print function here
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handlePrint = () => {
    // Custom print logic here
    setIsPrinting(true);
    window.print();
    setIsPrinting(false);
  };

  const handleLogout = () => {
    const data = {
      params: {
        username: props.userInfo.username,
        sessionTime: timer.getElapsedTime() / 1000,
      },
    };
    logData(data);
    setShowModal(false);
    KeycloakUserService.doLogout();
  };

  const onAction = (e) => {
    setIsTimeOut(false);
  };

  if (!KeycloakUserService.isLoggedIn()){
    return KeycloakUserService.doLogin();
  } else {
  return (
    <div>
      <IdleTimer
        ref={(ref) => {
          setTimer(ref);
          props.saveActiveTime(ref == null ? null : ref.getElapsedTime());
        }}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        timeout={timeout}
      />
      <Sidebar.Pushable>
        <SidebarComp />
        <Sidebar.Pusher dimmed={props.sidebarVisible}>
        {!isPrinting? (<Header />):(<div></div>)}
          <Switch>
            <Route exact path="/" component={LexxDashboard} />
            <Route
              exact
              path="/troubleshooting"
              component={TroubleshootingHome}
            />
            <Route exact path="/feedback/" component={LexxFeedback} />
            <Route
              exact
              path="/troubleshooting/issues/options/action"
              component={TroubleshootingAction}
            />
            <Route
              exact
              path="/troubleshooting/issues"
              component={LexxSearchResults}
            />
            <Route
              exact
              path="/troubleshooting/xviewer-issues"
              component={LexxSearchResultsXviewer}
            />
            <Route
              exact
              path="/newprocedure"
              component={NewProcedure}
            />
            <Route
              exact
              path="/troubleshooting/issues/options/"
              component={LexxTroubleshootingOptions}
            />
            <Route
              exact
              path="/troubleshooting/issues/workorder/"
              component={LexxWorkorder}
            />
            <Route exact path="/thankyou" component={ThankYouHome} />
            <Route
              exact
              path="/schematic/id=:id"
              component={LexxSchematicView}
            />
            <Route exact path="/procedure/id=:id" component={Procedure} />
            <Route exact path="/myteam/" component={LexxTeamResult} />
            <Route
              exact
              path="/userProfile/id=:id"
              render={() => <UserProfile otherProfile={true} />}
            />
            <Route path="/editProfile" component={EditProfile} />
            <Route
              path="/userProfile"
              render={() => <UserProfile otherProfile={false} />}
            />
            <Route exact path="/allNotifications/" component={Notifications} />
            <Route exact path="/allSessions" component={Sessions} />
            <Route
              exact
              path="/session/id=:id"
              component={LexxSessionSummary}
            />
            <Route exact path="/allTasks" component={Tasks} />
            <Route path="/viewTask/id=:id" component={ViewTask} />
            <Route path="/createReport/id=:id" component={CreateReport} />
            <Route path="/reviewSessionReport/id=:id" component={LexxReportSessions}/>
            <Route path="/reviewEachSession/id=:id" component={LexxReportSessionView}/>
            <Route path="/reviewReport/id=:id" component={ReviewTaskReport} />
            <Route path="/reportGenerated/id=:id" component={LexxReportGenerated} />
            <Route path="/reviewReportSummary/id=:id" component={LexxReportSummaryReview} />
            <Route path="/reviewReportSession/id=:id" component={LexxReportSessionReview} />
            <Route path="/reports" component={LexxReports}/>
            <Route path="/qrcode" component={LexxScanner} />
            <Route
              exact
              path="/addsolutionsfaultdetails"
              component={AddSolutionsFaultDetails}
            />
            <Route
              exact
              path="/addsolutionssteps"
              component={AddSolutionsSteps}
            />
            <Route exact path="/solutionsummary" component={SolutionSummary} />
            <Route exact path="/newstep" component={NewStep} />
            <Route exact path="/newsolutions" component={NewSolutions} />
            <Route exact path="*" render={() => <Redirect to="/" />} />
          </Switch>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      {showModal ? (
        <LexxIdlePopup
          showModal={showModal}
          handleClose={handleClose}
          handleLogout={handleLogout}
        />
      ) : null}
    </div>
  );
};
}

const mapStateToProps = (state) => {
  return {
    sidebarVisible: state.sidebarVisible,
    userInfo: state.userInfo,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    changeSidebarVisibility,
    loginAction,
    saveActiveTime,
  })(App)
);
