import React, { useState, useEffect, useRef } from 'react';
import { Icon, Input, Modal, List, Form, Image, Button } from 'semantic-ui-react';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getChatData, getChatMediaLink, uploadChatData, getOrganizationProfilePictures } from '../apiCall';
import { SOCKET_EVENTS } from "../../constants/socketEvents";
import LexxMessage from "./LexxMessage";
import userDummy from '../../assets/images/user-dummy.png';

/* Parent component for the Chat which is a modal */
function LexxChat(props) {

    const [messages, setMessages] = useState([]);  // array of all the message
    const [text, setText] = useState(""); // text typed by the logged in user
    const [allUsers, setUsers] = useState([]);  // all users in the chat
    // const [seenUsers, setSeenUsers] = useState([]);

    /* Function to get all the users, also all the profile images of all the users 
       Also to setup a socket connection and get chat messages 
    */
    const getUsers = () => {
        getChatData("?sessionId=" + props.sessionID).then((response) => {
            setMessages(response.data.data.messages);
        }).catch((error) => {
            console.log(error);
        });

        if (props.username && props.sessionID) {
            // first stream to receive and handle messages
            props.socket.on(SOCKET_EVENTS.MESSAGE, message => {
                setMessages(messages => [...messages, message]);
            });
        }

        getOrganizationProfilePictures().then((response) => {
            setUsers(response.data.data.users);
        });
    }

    useEffect(getUsers, []);
    useEffect(() => {
        scrollToBottom()
    }, [messages]);

    // Function to add the message, the message is sent in the socket connection
    const addPost = () => {
        if (text !== "") {
            props.socket.emit(SOCKET_EVENTS.SEND_MESSAGE, { data: text, isFile: false }, () => setText(""));
            setText("");
        }
    }

    // Function called on change of text on the message field
    const handleChange = (e) => {
        setText(e.target.value);
    }

     /* Get the profile image of the user based on the parameter
        @name - name of the user whose profile image is to be shown
        usersData is an array with all the session users and profile image of them
    */
    const getProfileImage = (name) => {
        var usersData = props.sessionUsers;
        if (usersData.length > 0) {
            for (var eachUser of usersData) {
                if (eachUser.userId === name) {
                    return eachUser.avatar !== "" ? eachUser.avatar : userDummy;
                }
            }
        } else {
            return null;
        }
    }

     /* Function to load the file to AWS and grab the key and then get the url to show the image */
    const loadFile = (e) => {
        let file = e.target.files[0];
        let fileName = file.name;
        let newFileName = props.username + Date.now() + fileName;
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("app_token");
        let data = {sessionId: props.sessionID, key: newFileName, type: file.type};
        getChatMediaLink("/uplinks", data).then((result) => {
            if (result.data.code === 'Success') {
                delete axios.defaults.headers.common["Authorization"];
                var blob = file.slice(0, file.size, file.type);
                let newFile = new File([blob], props.sessionID + "/" + newFileName, { type: file.type });
                uploadChatData(result.data.data.url, newFile, file.type).then((response) => {
                    axios.defaults.headers.common["Authorization"] = localStorage.getItem("app_token");
                    props.socket.emit(SOCKET_EVENTS.SEND_MESSAGE, { data: newFileName, isFile: true }, () => setText(""));
                }).catch((e) => console.log(e))
            }
        })
    }

    const messagesEndRef = useRef(null);
    /* Scroll the user to bottom when message is sent */
    const scrollToBottom = () => {
        setTimeout(function () { 
            messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }) 
        }, 500);

    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            addPost();
        }
    }

    /* Function to get the unique users from a set of users to show in online 
        Placed in to fix a bug when multiple users with same name show up in chat
    */
    const getUniqueUsers = () => {
        const unique = [...new Set(props.usersInChat.map(user => user.userId))];
        return unique.map((id, index) => <Image className="online-person" key={index} src={getProfileImage(id)} />)
    }


    return (
        <Modal
            closeIcon
            open={props.open}
            onClose={() => props.chatWindow(false)}
            onOpen={() => props.chatWindow(true)}
            className="chat-container"
            dimmer={'blurring'}
        >
            <Modal.Header>
                Session Chat
                {/* This following component will be enabled once the profile feature is fully developed */}
                {/* <div className="online-header">
                    {getUniqueUsers()}
                </div> */}
            </Modal.Header>
            <Modal.Content className="teamList sessionList chatWrapper">
                <List>
                    {Object.keys(allUsers).length > 0 && messages.map((message, i) => <div key={i} className="messageWrapper"><LexxMessage message={message} username={props.username} usersInChat={allUsers} /></div>)}
                    <div ref={messagesEndRef} />
                </List>
            </Modal.Content>
            <Modal.Actions>
                <Form.Field>
                    <Button as="label" htmlFor="file" type="button" className="mediaInput">
                        <Icon name="camera" color="grey" size="big" className="marL10" />
                    </Button>
                    <input type="file" id="file" style={{ display: "none" }} onChange={loadFile} accept="video/*,image/*" />
                    <Input placeholder='Write Message' name="text" onChange={handleChange} value={text} onKeyPress={handleKeyPress} />
                    <Icon name="send" color="grey" size="big" className="marL10" onClick={() => addPost()} />
                </Form.Field>
            </Modal.Actions>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        sessionID: state.sessionData.sessionID,
        username: state.userInfo.userId,
        sessionUsers: state.sessionUsers
    };
};

export default withRouter(connect(mapStateToProps)(LexxChat));