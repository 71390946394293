import React, { useState, useEffect } from 'react';
import { Modal, List, Menu, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getChatData, getOrganizationProfilePictures } from '../apiCall';
import LexxMessage from "../LexxSession/LexxMessage";

/* Component for the Chat to review the chat in modal */
function LexxReportReviewChat(props) {

    const [messages, setMessages] = useState([]); // array of all the message
    const [allUsers, setUsers] = useState([]); // all users in the chat

    /* Function to get all the messages of the chat from the server
        and images of the users involved in the chat
        later set the messages and users in messages, allusers variable
    */
    const getMessages = () => {
        getChatData("?sessionId=" + props.sessionId).then((response) => {
            setMessages(response.data.data.messages);
        }).catch((error) => {
            console.log(error);
        });
        getOrganizationProfilePictures().then((response) => {
            setUsers(response.data.data.users);
        });
    }

    /* Function to save the chat for report or not
        @isSave is the boolean to save/not save (true/false)
    */
    const saveChat = (isSave) => {
        props.saveDraftReport(props.sessionId, isSave); //save in redux
        props.chatWindow(false); // close modal
    }

    useEffect(getMessages, []);

    return (
        <Modal
            closeIcon
            open={props.open}
            onClose={() => props.chatWindow(false)}
            onOpen={() => props.chatWindow(true)}
            className="chat-container"
            dimmer={'blurring'}
        >
            <Modal.Header>
                Review Chat
            </Modal.Header>
            <Modal.Content className="teamList sessionList chatWrapper">
                <List>
                    {Object.keys(allUsers).length > 0 &&  messages.map((message, i) => <div key={i} className="messageWrapper"><LexxMessage message={message} username={props.username} usersInChat={allUsers} /></div>)}
                </List>
                {props.save ?
                    <Menu borderless fixed='bottom' id="menu">
                        <Menu.Item position='left'>
                            <Button className='secondarybtn' onClick={() => saveChat(false)}>
                                Don't Save
                            </Button>
                        </Menu.Item>
                        <Menu.Item position='right'>
                            <Button className='primarybtn' onClick={() => saveChat(true)}>
                                Save
                            </Button>
                        </Menu.Item>
                    </Menu>
                    : null
                }
            </Modal.Content>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        username: state.userInfo.userId,
        sessionUsers: state.sessionUsers
    };
};

export default withRouter(connect(mapStateToProps)(LexxReportReviewChat));