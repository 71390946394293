import React from "react";
import PdfHighlighterPage from "../pdfHighlighter/PdfHighlighterPage.js";
import LexxBreadcrumbs from "../LexxBreadcrumbs/LexxBreadcrumbs";


function NewProcedure(){
    return(
   <div className="content-container home-container">
        <LexxBreadcrumbs /> 
        <PdfHighlighterPage />
    </div>
    )
}

export default NewProcedure;