import React from 'react'
import { Button, Header, Modal, Icon } from 'semantic-ui-react';
import { withRouter } from "react-router-dom";

/* Modal to show the task owner that the task has been declined with message */
function LexxDeclineModal(props) {

    /* Method to send user to the task page */
    const goToTask = (notificationClicked) => {
        props.history.push("viewTask/id=" + notificationClicked.taskId);
    }

    return (
        <Modal closeIcon open={props.open} className="lexxDeclineReasonModal"
            onClose={() => props.closeDialog(false)}
            onOpen={() => props.closeDialog(true)}
        >
            <Header> Task Declined </Header>
            <Modal.Content>
                <p> <Icon name="warning circle" /> <b> {props.notificationChosen.source.name} </b> has declined the task: <b> {props.notificationChosen.taskTitle} </b></p>
                <p> {props.notificationChosen.responseData}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='blue' onClick={() => goToTask(props.notificationChosen)}>
                    Go To Task
        </Button>
            </Modal.Actions>
        </Modal>
    )
}


export default withRouter(LexxDeclineModal);