import React from 'react';
import { Button, Modal } from 'semantic-ui-react';


/* Modal to show the scanned text in the UI */
function LexxQRModal(props) {

  return (
    <Modal onClose={() => props.handleClose} open={props.showModal}>
      <Modal.Header>Scanned Text </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            {props.text} 
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='blue' onClick= {props.handleClose}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default LexxQRModal