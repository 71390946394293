import React from "react";
import { Image, Modal, Button } from "semantic-ui-react";
const LexxFullScreen = ({ isFullscreen, data, mediaFullScreen }) => {
  return (
    <div>
      <Modal onOpen={isFullscreen} open={isFullscreen} style={{ width: "40%" }}>
        <Modal.Header>Photo</Modal.Header>
        <Modal.Content image>
          <Image src={data} wrapped />
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => mediaFullScreen()}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default LexxFullScreen;
