import React, { useState, useEffect } from 'react';
import { Modal, List } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getNoteData, getOrganizationProfilePictures } from '../apiCall';
import LexxNote from "./LexxNote";

/* Domponent for the Chat Review which is a modal in the Report */
function LexxReportSessionNotesView({sessionID, username}) {

    const [messages, setMessages] = useState([]); // array of all the message
    const [allUsers, setUsers] = useState([]); // all users in the chat


    /* Function to get all the messages of the chat from the server
        and images of the users involved in the chat
        later set the messages and users in messages, allusers variable
    */
    const getMessages = () => {
        getNoteData("?sessionId=" + sessionID).then((response) => {
            setMessages(response.data.data.messages);
        }).catch((error) => {
            console.log(error);
        });

        getOrganizationProfilePictures().then((response) => {
            setUsers(response.data.data.users);
        });
    }

    useEffect(getMessages, []);

    return (
        <div>
            <List>
                {Object.keys(allUsers).length > 0 && messages.map((message, i) => <div key={i} className="messageWrapper"><LexxNote message={message} username={username} usersInChat={allUsers}/></div>)}
            </List>
        </div>
    )
}

export default LexxReportSessionNotesView;