import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Header, Label, Container, Icon, Segment,Input  } from 'semantic-ui-react';
import { getReports } from '../apiCall';

/* Component displays all the reports for a user */
function LexxReports(props) {

    const [reports, setReports] = useState(""); // array to hold the reports
    const [apiFetch,setApiFetch] = useState(false);
    const [search, setSearch] = useState(""); //search query for reports
    const [stableReports, setStableReports] = useState("")

    /* Extract all the reports from server 
    and set them in reports variable */
    const getReportsFromServer = () => {
        getReports().then((response) => {
            setReports(response.data.data.tasks);
            setStableReports(response.data.data.tasks)
            setApiFetch(true);
            console.log(response.data.data.tasks);
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(getReportsFromServer, []);

    /* Redirect the user to task page where report is stored */
    const reportClicked = (taskId) => {
        props.history.push(`/viewTask/id=${taskId}`);
    }

    const changeSearch = (event) => {
        setSearch(event.currentTarget.value);
        let filteredData = [];
        // const filteredData = stableTasks.filter(item => {
        //     return Object.keys(item).some((eachKey) => {
        //         if (eachKey === "searchQuery") {
        //             return item[eachKey].toLowerCase().includes(event.currentTarget.value.toLowerCase());
        //         } else {
        //             return null;
        //         }
        //     })
        // });
        for (let i=0; i<stableReports.length; i++) {
            if ((stableReports[i].taskTitle.toLowerCase()).includes(event.currentTarget.value.toLowerCase())) {
                filteredData.push(stableReports[i]);
            }
        }
        setReports(filteredData);
    }
    
    if(apiFetch){
        return (
            <div className="my-sessions">
                <Header as="h2">My Reports</Header>
                <div className="searchTeam">
                <Input
                    icon={<Icon name='search' color="black" />}
                    iconPosition='left' placeholder='Search' value={search} onChange={changeSearch} />
                </div>
                {
                   reports.length == 0  && (
                    <Container text style={{ marginTop: '50px' }}>
                    <Segment placeholder>
                      <Header icon>
                        <Icon name="file outline" />
                        No Reports Available
                      </Header>
                      <Segment.Inline>
                        <p>
                          There is no reports available at the moment. Please check back later.
                        </p>
                      </Segment.Inline>
                    </Segment>
                  </Container>
                 )
                  
                }
                {reports.length > 0  && reports.map((item, key) => {
                    return (
                        <div key={key} className="each-session taskList" onClick={() => reportClicked(item.taskId)}>
                            <div className="each-task">
                                <div className="task-info">
                                    <div className="time"> {item.taskNum} </div>
                                    <div className="query"> {item.taskTitle} </div>
                                </div>
                                <Label circular className="collaborators float-right" as='a' image={item.assignee.avatar} />
                            </div>
                        </div>
                    )
                })}
            </div>
        ) 
    }
    else{
        return (
            <div className="my-sessions">
                <Header as="h2">My Reports</Header>
                <div className="searchTeam">
                <Input
                    icon={<Icon name='search' color="black" />}
                    iconPosition='left' placeholder='Search' value={search} onChange={changeSearch} />
            </div>
            </div>
        )
    }
   
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps, null)(LexxReports));