import React, { useEffect, useState } from "react";
import Spinner from "./Spinner";
import {
  PdfLoader,
  PdfHighlighter,
  Highlight,
  Popup,
  AreaHighlight,
} from "react-pdf-highlighter";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";

const PdfHighlighterPage = (props) => {
  const [highlights, setHighlights] = useState([]);
  let outLine = JSON.parse(props.outLineRedux);
  const [zoomIn,setZoomIn]=useState(1);
  const HighlightPopup = null;
  let highlightText = [];
  let tableContents = [];

  const resetHash = () => {
    document.location.hash = "";
    // let x = "r6bc3600e8192462aad86d9113077037a5";
    // document.location.hash = `highlight-${x}`;
  };
  const handleZoomIn = ()=>{
       console.log(zoomIn)
       setZoomIn(zoomIn+0.25);
  }
  const handleZoomOut = ()=>{
    setZoomIn(1);
  }
  const getRectValues = (element) => {
    let rectValues = [];
    if (element.meta.id) {
      rectValues.push({
        height: element.meta.height,
        width: element.meta.width,
        x1: element.meta.x1,
        x2: element.meta.x2,
        y1: element.meta.y1,
        y2: element.meta.y2,
        pageNumber: element.meta.pageNumber,
      });
    } else if (element.meta.length > 0) {
      for (let i = 0; i < element.meta.length; i++) {
        rectValues.push({
          height: element.meta[i].height,
          width: element.meta[i].width,
          x1: element.meta[i].x1,
          x2: element.meta[i].x2,
          y1: element.meta[i].y1,
          y2: element.meta[i].y2,
          pageNumber: element.meta[i].pageNumber,
        });
      }
    }
    return rectValues;
  };

  

  const getBoundingRect = (element) => {
    if (element.meta.id) {
      const value = {
        height: element.meta.height,
        width: element.meta.width,
        x1: element.meta.x1,
        x2: element.meta.x2,
        y1: element.meta.y1,
        y2: element.meta.y2,
        pageNumber: element.meta.pageNumber,
      };
      return value;
    } else if (element.meta.length > 0) {
      const value = {
        height: element.meta[0].height,
        width: element.meta[0].width,
        x1: element.meta[0].x1,
        x2: element.meta[0].x2,
        y1: element.meta[0].y1,
        y2: element.meta[0].y2,
        pageNumber: element.meta[0].pageNumber,
      };
      return value;
    }
  };


  // outLine.forEach((element) => {
  //   highlightText.push({
  //     comment: {
  //       emoji: "",
  //       text: "",
  //     },
  //     content: {
  //       text: element.heading_s,
  //     },
  //     position: {
  //       boundingRect: getBoundingRect(element),
  //       rects: [
  //         {
  //           height: props.docDetail.meta[0].height,
  //           width: props.docDetail.meta[0].width,
  //           x1: props.docDetail.meta[0].x1,
  //           x2: props.docDetail.meta[0].x2,
  //           y1: props.docDetail.meta[0].y1,
  //           y2: props.docDetail.meta[0].y2,
  //           pageNumber: props.docDetail.meta[0].pageNumber,
  //         },
  //       ],
  //       pageNumber:
  //         element.meta.length > 0
  //           ? element.meta[0].pageNumber
  //           : element.meta.pageNumber,
  //     },
  //     id:
  //       element.meta.length > 0
  //         ? element.meta[0].id.split("/")[0]
  //         : element.meta.id.split("/")[0],
  //   });
  // });

  if(props.docDetail.meta){
  tableContents.push({

    comment: {

      emoji: "",

      text: "",

    },

    content: {

      text: "Summary from search display",

    },
   
    position: {

      boundingRect: {

        height: props.docDetail.meta[0].height,

        width: props.docDetail.meta[0].width,

        x1: props.docDetail.meta[0].x1,

        x2: props.docDetail.meta[0].x2,

        y1: props.docDetail.meta[0].y1,

        y2: props.docDetail.meta[0].y2,

        pageNumber: props.docDetail.meta[0].pageNumber,

      },

      rects: [

        {

          height: props.docDetail.meta[0].height,

          width: props.docDetail.meta[0].width,

          x1: props.docDetail.meta[0].x1,

          x2: props.docDetail.meta[0].x2,

          y1: props.docDetail.meta[0].y1,

          y2: props.docDetail.meta[0].y2,

          pageNumber: props.docDetail.meta[0].pageNumber,

        },

      ],

      pageNumber: props.docDetail.meta[0].pageNumber,

    },




    id: props.docDetail.meta[0].id.replace("/meta#", ""),

    isOutLine: true,

  });
}
  if (outLine) {
    outLine.forEach((element) => {
      tableContents.push({
        comment: {
          emoji: "",
          text: "",
        },
        content: {
          text: element.heading_s,
        },
        position: {
          boundingRect: getBoundingRect(element),
          rects: getRectValues(element),
          pageNumber:
            element.meta.length > 0
              ? element.meta[0].pageNumber
              : element.meta.pageNumber,
        },
        id: element.id.replace("/outline#", ""),
        isOutLine: true,
      });
    });
    tableContents.sort(function (a, b) {
      return a.position.boundingRect.y1 - b.position.boundingRect.y1;
    });
    tableContents.sort(function (a, b) {
      return (
        a.position.boundingRect.pageNumber - b.position.boundingRect.pageNumber
      );
    });
  }

  let scrollViewerTo = (highlight) => {
    console.log(highlight);
  };

  const parseIdFromHash = () =>
    document.location.hash.slice("#highlight-".length);

  useEffect(() => {
    window.addEventListener("hashchange", scrollToHighlightFromHash, false);
  }, []);

  const scrollToHighlightFromHash = () => {
    const highlight = getHighlightById(parseIdFromHash());

    if (highlight) {
      scrollViewerTo(highlight);
    }
  };

  const getHighlightById = (id) => {
    let highlightContent = tableContents.find(
      (highlight) => highlight.id === id
    );
    if (!highlightContent) {
      highlightContent = tableContents.find((highlight) => highlight.id === id);
    }
    return highlightContent;
  };
  return (
    <div className="App" style={{ display: "flex", height: "100vh",width:"100%" }} key={zoomIn}>
      <Sidebar highlights={highlightText} tableContents={tableContents} />
      {/* <div className="zoom-option">
        <div onClick={()=>{handleZoomIn()}}><Icon name='zoom-in' color="black"> </Icon></div>
        <div onClick={()=>{handleZoomOut()}}><Icon name='zoom-out' color="black" > </Icon></div>   
        </div> */}
      <div
        style={{
          height: "100vh",
          width: "75vw",
          position: "relative",
        }}
      >
        <PdfLoader url={props.fileLinkRedux} beforeLoad={<Spinner />}>
          {(pdfDocument) => (
            <PdfHighlighter
              pdfDocument={pdfDocument}
              onScrollChange={resetHash}
              onSelectionFinished={() => {}}
              pdfScaleValue={`${zoomIn}`}
              scrollRef={(scrollTo) => {
                scrollViewerTo = scrollTo;

                scrollToHighlightFromHash();
              }}
              highlightTransform={(
                highlight,
                index,
                setTip,
                hideTip,
                viewportToScaled,
                screenshot,
                isScrolledTo
              ) => {
                const isTextHighlight = !Boolean(
                  highlight.content && highlight.content.image
                );
                const component = isTextHighlight ? (
                  <Highlight
                    position={highlight.position}
                    comment={highlight.comment}
                  />
                ) : (
                  <AreaHighlight
                    // isScrolledTo={isScrolledTo}
                    highlight={highlight}

                    // onChange={(boundingRect) => {
                    //   updateHighlight(
                    //     2,
                    //     { boundingRect: viewportToScaled(boundingRect) },
                    //     { image: screenshot(boundingRect) }
                    //   );
                    // }}
                  />
                );

                return (
                  <Popup
                    popupContent={<HighlightPopup {...highlight} />}
                    // onMouseOver={(popupContent) =>
                    //   setTip(highlight, (highlight) => popupContent)
                    // }
                    // onMouseOut={hideTip}
                    onMouseOver={() => {}}
                    key={index}
                    children={component}
                  />
                );
              }}
              highlights={
                props.docDetail.meta?
              (  [
                    {
  
                    comment: {
  
                      emoji: "",
  
                      text: "",
  
                    },
  
                    content: {
  
                      text: "Summary from search display",
  
                    },
  
                    position: {
  
                      boundingRect: {
  
                        height: props.docDetail.meta[0].height,
  
                        width: props.docDetail.meta[0].width,
  
                        x1: props.docDetail.meta[0].x1,
  
                        x2: props.docDetail.meta[0].x2,
  
                        y1: props.docDetail.meta[0].y1,
  
                        y2: props.docDetail.meta[0].y2,
  
                        pageNumber: props.docDetail.meta[0].pageNumber,
  
                      },
  
                      rects: [
  
                        {
  
                          height: props.docDetail.meta[0].height,
  
                          width: props.docDetail.meta[0].width,
  
                          x1: props.docDetail.meta[0].x1,
  
                          x2: props.docDetail.meta[0].x2,
  
                          y1: props.docDetail.meta[0].y1,
  
                          y2: props.docDetail.meta[0].y2,
  
                          pageNumber: props.docDetail.meta[0].pageNumber,
  
                        },
  
                      ],
  
                      pageNumber: props.docDetail.meta[0].pageNumber,
  
                    },
  
  
  
  
                    id: props.docDetail.meta[0].id.replace("/meta#", ""),
  
                    isOutLine: true,
  
                  },
  
                ]):[]
              }
            />
          )}
        </PdfLoader>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fileLinkRedux: state.fileLinkRedux,
    outLineRedux: state.outLineRedux,
    docDetail: state.docDetail,
  };
};

export default connect(mapStateToProps)(PdfHighlighterPage);
