import React from 'react';
import { Container, Icon, Button } from 'semantic-ui-react';
import { withRouter } from "react-router-dom";

/* Stateless componet to display that report is generated */
function LexxReportGenerated(props){

    /* Redirect the user to reports page */
    const goToReport = () => {
        props.history.push("/reports");
    }

    return (
        <div className="content-container home-container thankyou-outer-container">
            <Container className='thankyou-inner-container'>
                <div className="thankyou-wrapper">
                    <Icon name="check circle" color="green" className="report-generated-icon" />
                    <p className='page-header-text'>
                        Report Generated
                    </p>
                    <p className='section-body-text marginL3'>You can access the report from the task card or from my reports page</p>
                    <div className="report-gen-btn">
                        <Button color='blue' onClick={() => goToReport()}>
                            Back To Reports
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default withRouter(LexxReportGenerated);