import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Input,
  Icon,
  Header,
  Label,
  Progress,Card,CardGroup,
  Button,
  Modal,
  Form,
  Dropdown,
  TextArea,Popup , ModalHeader,
  ModalContent,
  ModalActions,
 

} from "semantic-ui-react";

import { TransitionablePortal, Segment } from "semantic-ui-react";
import { getTaskList, createTask, saveProfile, getTeamAllUsers,getProfile, deleteTask } from "../apiCall";
import KeycloakUserService from "../login/KeyCloakService";
import { searchClicked } from "../../redux/actions/index";
import { DateInput } from "semantic-ui-calendar-react";
import introJs from "intro.js";
import "intro.js/introjs.css";
import deleteIcon from "../../assets/images/delete.png";

/* This Component shows the tasks list associated to the logged in user */
function LexxAllTask(props) {
  const [search, setSearch] = useState(""); //search query for tasks
  const [tasks, setTasks] = useState(""); // task list which changes when we search
  const [stableTasks, setStateTasks] = useState(""); // stable task list
  const [open, setOpen] = useState(false);
  const [deletePopupOpen ,setDeletePopupOpen] = useState(false);
  const [toBeDeletedTask , setToBeDeeletedTask] = useState({})
  const [taskSaved, saveTaskSaved] = useState(false);
  const [showUserGuide, setShowUserGuide] = useState(true);
  const [formData, setFormData] = useState({
    assigneeId: "",
    windTurbineNumber: "",
    faultCode: "",
    taskTitle: "",
    taskDescription: "",
    taskType: "",
    taskPriority: "",
    expectedDeliveryDate: "",
  });
  const [activityOptionSelected, setActivityOptionSelected] = useState('All');
  const [priorityOptionSelected, setPriorityOptionSelected] = useState('All');
  const [statusOptionSelected, setStatusOptionSelected] = useState('All');
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [activityOptions, setActivityOptions] = useState([
    { key: "Fault Fixing", text: "Fault Fixing", value: "Fault Fixing" },
    {
      key: "Major Corrective",
      text: "Major Corrective",
      value: "Major Corrective",
    },
    {
      key: "Maintenance & Repair",
      text: "Maintenance & Repair",
      value: "Maintenance & Repair",
    },
  ]);
  const [priorityOptions, setPriorityOptions] = useState([
    { key: "Low", text: "Low", value: "Low" },
    { key: "Medium", text: "Medium", value: "Medium" },
    { key: "High", text: "High", value: "High" },
  ]);
  const [activityFilterOptions, setActivityFilterOptions] = useState([
    { key: "All", text: "All", value: "All" },
    { key: "Fault Fixing", text: "Fault Fixing", value: "Fault Fixing" },
    {
      key: "Major Corrective",
      text: "Major Corrective",
      value: "Major Corrective",
    },
    {
      key: "Maintenance & Repair",
      text: "Maintenance & Repair",
      value: "Maintenance & Repair",
    },
  ]);
  const [priorityFilterOptions, setPriorityFilterOptions] = useState([
    { key: "All", text: "All", value: "All" },
    { key: "Low", text: "Low", value: "Low" },
    { key: "Medium", text: "Medium", value: "Medium" },
    { key: "High", text: "High", value: "High" },
  ]);
  const [statusFilterOptions , setStatusFilterOptions] =useState([
    { "key": "All", "text": "All", "value": "All" },
    { "key": "To Do", "text": "To Do", "value": "To Do" },
    { "key": "inprogress", "text": "In progress", "value": "inprogress" },
    { "key": "Completed", "text": "Completed", "value": "Completed" },
    { "key": "On Hold", "text": "On Hold", "value": "On Hold" },
    { "key": "No Issue Found", "text": "No Issue Found", "value": "No Issue Found" },
    { "key": "Delegated", "text": "Delegated", "value": "Delegated" },
    { "key": "Blocked", "text": "Blocked", "value": "Blocked" },
  ]
  );
  const [errors, setErrors] = useState({});
  const [isHovered, setIsHovered] = useState(""); // State to track hover
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    // hour: "numeric",
    // minute: "numeric",
    // timeZoneName: "short",
  };
  let userData= {}
  const handleChange = (e, data) => {
    const { name, value } = data || {};
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setFormData({
      assigneeId: "",
      windTurbineNumber: "",
      faultCode: "",
      taskTitle: "",
      taskDescription: "",
      taskType: "",
      taskPriority: "",
      expectedDeliveryDate: "",
    });
    setErrors({});
    setOpen(false);
  };
  const validateForm = () => {
    const newErrors = {};

    // Validate each mandatory field
    if (!formData.assigneeId) {
      newErrors.assigneeId = "Assignee is required";
    }

    if (!formData.windTurbineNumber) {
      newErrors.windTurbineNumber = "Wind turbine number is required";
    }

    if (!formData.taskTitle) {
      newErrors.taskTitle = "Task title is required";
    }

    if (!formData.taskDescription) {
      newErrors.taskDescription = "Task description is required";
    }

    if (!formData.taskType) {
      newErrors.taskType = "Task type is required";
    }

    if (!formData.taskPriority) {
      newErrors.taskPriority = "Task priority is required";
    }

    // Set the new errors
    setErrors(newErrors);

    // Return true if there are no errors, false otherwise
    console.log(
      Object.keys(newErrors).length === 0,
      "Object.keys(newErrors).length === 0;"
    );
    return Object.keys(newErrors).length === 0;
  };
  const handleFieldBlur = (e, data) => {
    const { name } = data || {};
    // Validate the field on blur
    if (!formData[name]) {
      setErrors({ ...errors, [name]: `${name} is required` });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const handleSubmit = () => {
    saveTaskSaved(true);
    setShowUserGuide(false)
    // Perform your form submission logic here, e.g., send data to an API
    createTask(formData).then(() => {
      getTasks();
      saveTaskSaved(false);
    });
    // Reset the form after submission
    setFormData({
      assigneeId: "",
      windTurbineNumber: "",
      faultCode: "",
      taskTitle: "",
      taskDescription: "",
      taskType: "",
      taskPriority: "",
      expectedDeliveryDate: "",
    });

    // Close the modal or perform any other actions after submission
    handleClose();
  };
const taskTypeOptions= {
  
}
  /* This function get all the tasks from the server
        and sets it in the variable tasks and setStateTasks
    */
  const getTasks = () => {
    props.searchClicked(false);
    getTaskList()
      .then((response) => {
        let tasks = response.data.data.tasks;
        tasks = tasks.reverse();
        setStateTasks(tasks);
        setTasks(tasks);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTeamMembers = () => {
    getTeamAllUsers("/create").then((res) => {
      let currentUserId = KeycloakUserService.getEmail();
      let users = res.data.data.users;
      let userOptions = [];
      for (let i = 0; i < users.length; i++) {
        if (users[i].userId === currentUserId) {
          userOptions.push({
            key: users[i].userId,
            text: `${users[i].name} (You)`,
            value: users[i].userId,
          });
        } else {
          userOptions.push({
            key: users[i].userId,
            text: users[i].name,
            value: users[i].userId,
          });
        }
      }
      setAssigneeOptions(userOptions);
    });
  };

  useEffect(getTasks, []);
  useEffect(getTeamMembers, []);
  function addEllipsis(text) {
    if (text.length >45) {
        return text.substring(0, 45) + "...";
    } else {
        return text;
    }
}
  /* Function called when the user searches for a task
        @event - event returned from UI for search query
        filter the stable Tasks and search for tasks
    */
  const changeSearch = (event) => {
    setShowUserGuide(false);
    setSearch(event.currentTarget.value);
    let searchedArray = [];
    let ArrayAfterFiltering = [];
    // const searchedArray = stableTasks.filter(item => {
    //     return Object.keys(item).some((eachKey) => {
    //         if (eachKey === "searchQuery") {
    //             return item[eachKey].toLowerCase().includes(event.currentTarget.value.toLowerCase());
    //         } else {
    //             return null;
    //         }
    //     })
    // });
    console.log("stableTasks",stableTasks);
 
    for (let i = 0; i < stableTasks.length; i++) {
      if (
        stableTasks[i]?.taskTitle
          .toLowerCase()
          .includes(event.currentTarget.value.toLowerCase())||stableTasks[i].taskId
          .toLowerCase()
          .includes(event.currentTarget.value.toLowerCase())
      ) {
        searchedArray.push(stableTasks[i]);
      }
    }
    ArrayAfterFiltering =  searchedArray.filter((task) =>{
      if((task.taskType.toLowerCase()===activityOptionSelected.toLowerCase()||activityOptionSelected==="All")
      &&(task.taskPriority.toLowerCase()===priorityOptionSelected.toLowerCase()||priorityOptionSelected==="All")
      &&(task.status.toLowerCase()===statusOptionSelected.toLowerCase()||statusOptionSelected==="All")){
        return true
      }
    })
    setTasks(ArrayAfterFiltering);
  
  };

  /* Redirect the user to the task detail page */
  const taskClicked = (taskId,e=false) => {
    //here taskId is used as an event not really as a taskId
    if(e)
    {
      e.stopPropagation(); 
      props.history.push(`/userProfile/id=${taskId.userId}`);
    }
    else {
      props.history.push(`/viewTask/id=${taskId}`);
    }
   
  };
  // useEffect(()=>{
   
  //   localStorage.getItem("showUserGuide") &&  getProfileFn();
  // },[])

  const showIntro = () => {

    introJs()
      .setOptions({
        skipLabel:"Skip", // Customize the label for the skip button
        showStepNumbers: true,
        dontShowAgain:!localStorage.getItem("showUserGuide"),
        steps: [
          {
            element: document.querySelector(".searchInput-task input "), // Select your menu bar element
            intro: "Enter the unique Task ID or Task Title to locate your task",
          },
 
          {
            element: document.querySelector(".filter-div"),
            intro: "Customize your search results by selecting filters that match your preferences",
          },
          {
            element: document.querySelector(".new-task-button"),
            intro: "Click here to get started and create your next task",
          },
        ],
        showBullets: true, // You can customize options as needed
      })
      .start()
    //  introJs().oncomplete(function(){
    //   console.log("oncomplete");
    //     setShowUserGuide(false)
    //   })
    //   introJs().onexit(function(){
    //     console.log("onexit");
    //     setShowUserGuide(false)
    //   }); 
  };

  /* Get the profile data of the user */
  const getProfileFn = () => {
    getProfile(props.userId).then((response) => {
        var profileData = response.data.data.user;
        userData={
            name:  profileData.name,
            title:profileData.title,
            aboutMe: profileData.aboutMe,
            city:  profileData.location.city,
            country:  profileData.location.country,
            speciality: profileData.speciality.join(' '),
            phoneNum:  profileData.phoneNum,
            profileImg:  profileData.profileImg,
            showUserGuide:false
        };
    }).catch((error) => {
        console.log(error);
    });
}
   /* This function is called when the user has made edits in the profile and saved it
        it creates a formData obj from the fields and sends it to server
    */
        const saveProfileFn = () => {
         
            console.log("userData",userData);
              saveProfile(userData).then((response) => {
                 
              }).catch((error) => {
                  console.log(error);
              });
          
      }
  useEffect(()=>{tasks.length > 0 && showUserGuide &&  showIntro();
  
    return () => {
    introJs().exit();
  };},[tasks])
const changeFilter = (e ,data,filterType)=>{
  let filteredArray=[];

  setShowUserGuide(false);
  if(filterType==="activity"){
    
    setActivityOptionSelected(data.value)
  }
  if(filterType==="priority"){
    setPriorityOptionSelected(data.value)
  }
  if(filterType==="status"){
    setStatusOptionSelected(data.value)
  } 
}

useEffect(()=>{
   stableTasks.length && filterAppliedTask()
},[activityOptionSelected,priorityOptionSelected,statusOptionSelected,stableTasks])
const  filterAppliedTask = ()=>{
 
  let filteredArray= [];
  filteredArray = stableTasks.filter((task)=> 
  { 
    if(
    (task.taskType.toLowerCase()===activityOptionSelected.toLowerCase()||activityOptionSelected==="All")
    &&(task.taskPriority.toLowerCase()===priorityOptionSelected.toLowerCase()||priorityOptionSelected==="All")
    &&(task.status.toLowerCase()===statusOptionSelected.toLowerCase()||statusOptionSelected==="All")
    ){
      return true
    }
})
 
  setTasks(filteredArray)
}

const showDeletePopup = (e , task)=>{

  e.stopPropagation();
  setToBeDeeletedTask(task)
  setDeletePopupOpen(true)
}
const deletePopupYes = async(e,taskId)=>{
  console.log("taskId",taskId);
  e.stopPropagation();
  setShowUserGuide(false)
  await deleteTask(taskId).then(()=>{getTasks();setDeletePopupOpen(false)});
}

const deletePopupNo = (e)=>{
 
  e.stopPropagation();
  setDeletePopupOpen(false)
}
const displayData = ()=>{
  return tasks.length > 0 &&
    tasks.map((item, key) => {
      return (
        <div
          key={key}
          className={`each-session taskList taskcard `}
          onClick={() => taskClicked(item.taskId)}
        >
          <Card className="each-task-card"> 
        
         
             <div className="each-task" >   
            <Popup
             trigger={ <div className="query">{addEllipsis(item.taskTitle)}</div> }
              on="hover"><span className="tooltip">{item.taskTitle}</span></Popup>
               
            <div>
          
              <span className="task-content"> Assignee: {item.assignee.name}</span>
            </div>
            <div>
            <span className="task-content">Created by: {item.owner.name}</span>
            </div>
            <div>
            <span className="task-content">
                Created on:{" "}
                {new Date(item.createTime).toLocaleString(
                  "en-US",
                  dateOptions
                )}
              </span>
            </div>
            <div className="task-detail margin-left">
              {item.taskPriority && (
                <span
                  className={
                    "task-priority " + item.taskPriority.toLowerCase()
                  }
                >
                  {" "}
                  {item.taskPriority}{" "}
                </span>
              )}
              <span className="task-type"> {item.taskType} </span>
            </div>
            <div className="status-avatar " id="status-avatar-task">
              <span
                className={
                  item.status === "Delegated"
                    ? "update Delegated"
                    : "update " + item.status
                }
              ></span>
             
              <span
                className={item.status === "To Do" ? "update todo" : ""}
              ></span>
              <span
                className={
                  item.status === "No Issue Found" ? "update paused" : ""
                }
              ></span>
              <span
                className={item.status === "On Hold" ? "update paused" : ""}
              ></span>
              <span className="status">
                {item.status === "inprogress"
                  ? "In Progress"
                  : item.status.slice(0, 1).toUpperCase() +
                    item.status.slice(1)}{" "}
              </span>
              <Popup
             trigger={<div className="del-icon-div">
              <img className="del-icon" onClick={(e)=>{showDeletePopup(e , item)  }}  src={deleteIcon}/>
              </div>}>Delete Task</Popup>
              <Label
                circular
                onClick={(e) => taskClicked({userId:item.assignee.userId},e)}
                className="collaborators float-right"
                as="a"
                image={item.assignee.avatar}
              />
               
            </div>
            <div className="time " id="taskID"> {item.taskNum} </div>
          </div>
          {item.progress !== undefined ? (
            <div className="progress-bar">
              <div>
                <span className="progress-title">Progress</span>
                <span className="progress-percent">{item.progress}%</span>
              </div>
              <Progress percent={item.progress} />
            </div>
          ) : null}</Card>
    
        </div>
      );
    })
}
  return (
    <div className="my-sessions">
      <TransitionablePortal
        open={taskSaved}
        onClose={() => saveTaskSaved(false)}
      >
        <Segment
          style={{
            left: "50%",
            position: "fixed",
            transform: "translateX(-50%)",
            top: "7%",
            zIndex: 11000,
          }}
          className="green-error"
        >
          <p>Task submission in progress...</p>
        </Segment>
      </TransitionablePortal>
      <div className="create-task-button">
        <Header as="h2">My Tasks
        <span>   
          <Icon className="info-circle"      onClick={() => showIntro()} size="small" name="info circle" />
          </span>
        
        </Header>
        
        <div className="right-float button-div-createtask">
          <Button
            className="new-task-button"
            onClick={handleOpen}
            content="Create a Task"
          />
        </div>
      </div>
      <Modal open={open} onClose={handleClose} size="tiny">
        <Modal.Header>Enter new task details</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field error={!!errors.assigneeId}>
              <label>
                Assignee<span style={{ color: "red" }}>*</span>
              </label>
              <Dropdown
                name="assigneeId"
                placeholder="Select assignee"
                fluid
                selection
                options={assigneeOptions}
                // value={formData.assigneeId}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                required
              />
              {errors.assigneeId && (
                <div style={{ color: "red" }}>{errors.assigneeId}</div>
              )}
            </Form.Field>
            <Form.Field error={!!errors.taskType}>
              <label>
                Task Type<span style={{ color: "red" }}>*</span>
              </label>
              <Dropdown
                name="taskType"
                placeholder="Select task type"
                fluid
                selection
                options={activityOptions}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                required
              />
              {errors.taskType && (
                <div style={{ color: "red" }}>{errors.taskType}</div>
              )}
            </Form.Field>
            <Form.Field error={!!errors.windTurbineNumber}>
              <label>
                Wind Turbine Number<span style={{ color: "red" }}>*</span>
              </label>
              <Input
                name="windTurbineNumber"
                value={formData.windTurbineNumber}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                placeholder="Enter your wind turbine number"
                required
              />
              {errors.windTurbineNumber && (
                <div style={{ color: "red" }}>{errors.windTurbineNumber}</div>
              )}
            </Form.Field>
            <Form.Field error={!!errors.faultCode}>
              <label>Fault Code</label>
              <Input
                name="faultCode"
                value={formData.faultCode}
                onChange={handleChange}
                placeholder="Enter your fault code"
              />
            </Form.Field>
            <Form.Field error={!!errors.taskTitle}>
              <label>
                Task Title<span style={{ color: "red" }}>*</span>
              </label>
              <Input
                name="taskTitle"
                value={formData.taskTitle}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                placeholder="Enter your task title"
                maxLength={100}
                required
              />
              {errors.taskTitle && (
                <div style={{ color: "red" }}>{errors.taskTitle}</div>
              )}
            </Form.Field>
            <Form.Field error={!!errors.taskDescription}>
              <label>
                Task Reported/Description<span style={{ color: "red" }}>*</span>
              </label>
              <TextArea
                name="taskDescription"
                value={formData.taskDescription}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                placeholder="Enter your task reported / description"
                maxLength={1000}
                style={{ minHeight: "150px" }}
                required
              />
              {errors.taskDescription && (
                <div style={{ color: "red" }}>{errors.taskDescription}</div>
              )}
            </Form.Field>
            <Form.Field error={!!errors.taskPriority}>
              <label>
                Task Priority<span style={{ color: "red" }}>*</span>
              </label>
              <Dropdown
                name="taskPriority"
                placeholder="Select task priority"
                fluid
                selection
                options={priorityOptions}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                required
              />
              {errors.taskPriority && (
                <div style={{ color: "red" }}>{errors.taskPriority}</div>
              )}
            </Form.Field>
            <Form.Field>
              <label>Expected Date of Completion</label>
              <DateInput
                name="expectedDeliveryDate"
                placeholder="Enter your Expected Date of Completion"
                value={formData.expectedDeliveryDate}
                iconPosition="right"
                onChange={handleChange}
                minDate={new Date()}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            labelPosition="right"
            content="Submit"
            onClick={() => {
              if (validateForm()) {
                handleSubmit();
              }
            }}
            // disabled={!validateForm()}
          />
        </Modal.Actions>
      </Modal>
      <Modal
        open={deletePopupOpen}
        onClose={(e) => deletePopupNo(e)}
      >
        <ModalHeader>Delete Task</ModalHeader>
        <ModalContent>
          Are you sure you want to delete <b>{toBeDeletedTask.taskTitle}</b> ?
        </ModalContent>
        <ModalActions>
          <Button className='no-deletetask-button' negative onClick={(e) => {deletePopupNo(e)}}>
           No    
          </Button>
          <Button className="new-task-button" positive onClick={(e) => {deletePopupYes(e,toBeDeletedTask.taskId)}}>
            Yes
          </Button>
        </ModalActions>
      </Modal>
      <div className="searchTask">
        <div className="searchInput-task">
        <Input
          icon={<Icon name="search" color="black" />}
          iconPosition="left"
          placeholder="Search with Task Title or Task ID"
          value={search}
          onChange={changeSearch}
        />
        </div>
        <div className="task-filters">  
       <div className="filter-div">
         <p className="task-filter-label">Task type:</p>  
        <Dropdown placeholder='Task type' onChange={(e,data)=>changeFilter(e,data,"activity")} defaultValue={activityOptionSelected}  value={activityOptionSelected}   selection options={activityFilterOptions}  />
        </div>
       <div className="filter-div"> 
        <p className="task-filter-label">Priority:</p>  
       <Dropdown placeholder='Priority' onChange={(e,data)=>changeFilter(e,data,"priority")} defaultValue={priorityOptionSelected}  value={priorityOptionSelected}   selection options={priorityFilterOptions}  />
       </div>
      <div className="filter-div"> 
        <p className="task-filter-label">Status:</p>  
        <Dropdown placeholder='Status'  onChange={(e,data)=>changeFilter(e,data,"status")} defaultValue={statusOptionSelected} value={statusOptionSelected}  selection options={statusFilterOptions}  />
        </div>
        </div>
      </div>
      <CardGroup itemsPerRow={3} className="task-card-group">
      {displayData()}
         </CardGroup>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.userInfo.userId,
   
  };
};

export default withRouter(
  connect(mapStateToProps, { searchClicked })(LexxAllTask)
);
