import React, { useEffect } from "react";
import { Html5Qrcode } from "html5-qrcode";
import {Header} from 'semantic-ui-react';
const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } };
const brConfig = { fps: 10, qrbox: { width: 300, height: 150 } };
let html5QrCode;

/* The component to show buttons for scanning QR code and bar Code and take permissions of camera */
const LexxQRCode = (props) => {

  /* Call the HTML5QRCode on loading of component */
  useEffect(() => { html5QrCode = new Html5Qrcode("reader-"+ props.type)}, [props.type]);

   /* Function to call when the button scanned QR/Barcode is clicked */
  const handleClickAdvanced = () => {
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      props.onResult(decodedText); // Get the extracted text from the QR/Bar Code
      handleStop();
    };
    html5QrCode.start(
      { facingMode: "environment" },
      props.type === "QR" ? qrConfig : brConfig, //based on the button clicked call the specific config
      qrCodeSuccessCallback
    );
  };

   /* Function to call when the button scanned "stopped" QR/Barcode is clicked */
  const handleStop = () => {
    try {
      html5QrCode
        .stop()
        .then((res) => {
          html5QrCode.clear(); // HTML5QRCode is cleared when stopped
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="wrapper">
      <Header as="h2" className="task-title"> {props.type} Code Scanner</Header>
      <div className="btn-wrapper">
        <button className="lexxbutton primary" onClick={() => handleClickAdvanced()}>Scan {props.type} Code</button>
        <button className="lexxbutton secondary" onClick={() => handleStop()}>Stop {props.type} Code Scan</button>
      </div>
      <div id={"reader-"+ props.type} width="100%" />
    </div>
  );

};

export default LexxQRCode;
