import axios from "axios";

/* change visibility of side bar*/
export const changeSidebarVisibility = (visible) => {
    return {
        type: 'SIDEBAR_VISIBLE',
        payload: !visible
    };
};

/* change count of unread messages in session */
export const unreadSessionMessages = (count) => {
    return {
        type: 'UNREAD_SESSION_MESSAGES',
        payload: count
    };
};

/* save the search results returned from server */
export const saveSearchResults = (results) => {
    return {
        type: 'SEARCH_RESULTS_SAVED',
        payload: results
    };
};

/* Troubleshooting data for a result */
export const saveTroubleshootingData = (data) => {
    return {
        type: 'TROUBLESHOOTING_DATA_SAVED',
        payload: data
    };
};

/* troubleshooting options for a result */
export const saveTroubleshootingOption = (option) => {
    return {
        type: 'TROUBLESHOOTING_OPTION_SAVED',
        payload: option
    };
};

/* Total steps of troubleshooting for a result */
export const saveTroubleshootingStep = (step) => {
    return {
        type: 'TROUBLESHOOTING_STEP_SAVED',
        payload: step
    };
}

/* Set the type if its troubleshooting or not */
export const setSearchType = (isTroubleshooting) => {
    return {
        type: 'SET_SEARCH_TYPE',
        payload: isTroubleshooting
    };
};

/* Steps visited by the user in troubleshooting */
export const saveVisitedTroubleshootingSteps = (steps) => {
    return {
        type: 'VISITED_TROUBLESHOOTING_STEPS_SAVED',
        payload: steps
    };
};

/* To create session when a search takes place */
export const searchClicked = (isSearch) => {
    return {
        type: 'STOP_SESSION_CREATE',
        payload: isSearch
    };
};

// export const saveSession = (session) => {
//     return {
//         type: 'SESSION_SAVED',
//         payload: session
//     };
// };

/* Clear the session from redux */
export const clearSessions = () => {
    return {
        type: 'SESSIONS_CLEARED',
        payload: null
    };
};

/* Save the query for search */
export const saveSearchQuery = (query) => {
    return {
        type: 'QUERY_SAVED',
        payload: query
    };
};

/* Save the active time spent on application */
export const saveActiveTime = (time) => {
    return {
        type: 'ACTIVE_TIME',
        payload: time
    };
};

/* Save the session data so that it can reloaded later */
export const saveSessionData = (sessionData) => {
    return {
        type: 'SESSION_ID',
        payload: sessionData
    }
}

/* Save the users present in the current session */
export const saveSessionUsers = (sessionUsers) => {
    return {
        type: 'SESSION_USERS',
        payload: sessionUsers
    }
}

/* Save the task id for a task */
export const saveTaskId = (taskId) => {
    return {
        type: "TASK_ID",
        payload: taskId
    };
};

/* This is the login action where the token from server is stored */
export const loginAction = (username, token) => {
    // Set the default parameters for all axios calls
    localStorage.setItem("app_token", `Bearer ${token}`);
    axios.defaults.params = {};
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return {
        type: "LOGIN",
        payload: username
    };
};

/* To save if the profile is completed by a user or not */
export const profileCompleted = (isCompleted) => {
    return {
        type: "PROFILE_COMPLETED",
        payload: isCompleted
    };
};

/* Store the feedback from the user */
export const feedback = (isPositive) => {
    return {
        type: "FEEDBACK",
        payload: isPositive
    };
};

/* Save the selected sessions for the report */
export const saveSessionOptions = (options) => {
    return {
        type: "SAVE_SESSION_OPTIONS",
        payload: options
    };
};

/* Save the completed session for review in report */
export const saveCompletedSessionOptions = (options) => {
    return {
        type: "SAVE_COMPLETED_SESSION_OPTIONS",
        payload: options
    };
};

/* Save the draft report to be reloaded later */
export const saveReduxDraftReport = (report) => {
    return {
        type: "SAVE_DRAFT_REPORT",
        payload: report
    };
};

/* Save the session options stored in the report for review */
export const saveReportReviewOptions = (options) => {
    return {
        type: "SAVE_REPORT_REVIEW_OPTIONS",
        payload: options
    };
};

/* Save the session options completed for reviewe in the report */
export const saveCompletedReportReviewOptions = (options) => {
    return {
        type: "SAVE_COMPLETED_REPORT_REVIEW_OPTIONS",
        payload: options
    };
};

/* The entire user set collection for an organization */
export const saveUsers = (users) => {
    return {
        type : "SAVE_USERS",
        payload: users
    }
}

/* Save the geolocation of user */
export const geolocation = (loc) => {
    return {
        type: "LOCATION",
        payload: loc
    };
};

/*Add a solution fault details */
export const addSolution = (solution) => {    
    return {
        type:'ADD_SOLUTION_FAULT',
        payload:solution
    };
};

/*Add step details */
export const addSteps = (steps) => {
    return {
        type:'ADD_STEP',
        payload:steps
    }
};

/*Add step collection saved into DB*/
export const addStepCollection = (stepCollection) => {
    return {
        type:'ADD_STEP_COLLECTION',
        payload:stepCollection
    }
};

/*Remove step from step collection */
export const removeStep = (step) => {
    return {
        type:'REMOVE_STEP',
        payload:step
    }
};

/*Reset Solution INFO */
export const resetSolutionFault = (reset) => {
    return {
        type: 'RESET_SOLUTION_FAULT',
        payload: reset
    }
}

/** Rest Step collection */
export const resetSteps = (reset) => {
    return {
        type: 'RESET_STEPS',
        payload: reset
    }
}

/** Rest Edit Step IDs collection */
export const resetEditStepIds = (reset) => {
    return {
        type: 'RESET_EDIT_STEPIDS',
        payload: reset
    }
}

/**Delete step collection */
export const deleteStepsCollections = (step) => {
    return {
        type: 'DELETE_STEP',
        payload:step
    }
}

/**Edit step details */
export const editStepDetails = (step) => {
    return {
        type: 'EDIT_STEP',
        payload:step
    }
}

/**Update step collection */
export const updateStep = (step) =>{
    return {
        type: 'UPDATE_STEP',
        payload:step
    }
}

/**Edit Step Details collection Reste */
export const editStepDetailsReset = (reset) => {
    return {
        type:'RESET_EDIT_STEP',
        payload:reset
    }
}

/**Save Step and Index */
export const saveStepOrder = (stepsValues) => {
    return {
        type: 'SAVE_STEPS_INDEX',
        payload: stepsValues
    }
}

/*Save step Ids*/
export const saveEditedStepIds = (stepId) => {
    return {
        type: 'SAVE_STEP_IDS',
        payload:stepId
    }
}

/**Update Step Ids */
export const updateStepId = (step) => {
    return {
        type: 'UPDATE_STEPID',
        payload:step
    }
}

/** */
export const solutionNotSaved = (reset) => {
    return {
        type: 'SOLUTION_NOT_SAVED',
        payload:reset
    }
}

export const setFeatureflag = (featureflag) => {
    return {
        type: "SET_FEATURE_FLAG",
        payload: featureflag
    }
};

export const setLanguage = (languageselect)=>{
    return {
        type:"SET_LANGUAGE",
        payload:languageselect
    }
};

export const setFiltervalues = (filtervalues)=>{
    return {
        type:"SET_FILTER_VALUES",
        payload:filtervalues
    }
};

export const setdisplaytext = (textstodisplay)=>{

    return{
        type:"SET_DISPLAY_TEXTS",
        payload:textstodisplay
    }
};

export const setCollectionchange = (collectionname)=>{

    return{
        type:"SET_COLLECTION_CHANGE",
        payload:collectionname
    }
};

export const storeFileLink = (fileLink) => {
    return {
      type: "STORE_FILELINK",
      payload: fileLink,
    };
  };
  export const storeOutline = (outLine) => {
    return {
      type: "STORE_OUTLINE",
      payload: outLine ? outLine : null,
    };
  };
  // action for saving a single doc details for showing in details page
  export const storeDocDetail = (detail) => {
    return {
      type: "STORE_DOC_DETAIL",
      payload: detail,
    };
  };

  // action for storing count of total documents fetched for pagination
export const setTotalDoc = (number) => {
    return {
      type: "SET_TOTAL_DOC",
      payload: number,
    };
  };
  export const setActivePage = (number) => {
    return {
      type: "SET_ACTIVE_PAGE",
      payload: number,
    };
  };

  export const addfilter = (value) => {
    return {
      type: "ADD_FILTER",
      payload: value,
    };
  };

  export const newSessionfromtask = (value) => {
    return {
      type: "NEW_SESSION_TASK",
      payload: value,
    };
  };
  export const fromTask = (value) => {
    return {
      type: "FROM_TASK",
      payload: value,
    };
  };