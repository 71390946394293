import React from 'react';
import { Form, Modal, Header, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

/* Stateless component when user declines the task
    reason needs to be given
*/
function LexxDeclineModal(props) {

    return (
        <Modal
            closeIcon
            open={props.open}
            onClose={() => props.closeDialog(false)}
            onOpen={() => props.closeDialog(true)}
            className="decline-modal"
        >
            <Modal.Content>
                <div className="task-num">{props.taskNum}</div>
                <Header as="h2">Task Declined</Header>
                <p>Tell us why you have declined this task.</p>
                {
                    props.error ?
                    <p className="error"> Reason is mandatory</p> : null
                }
                <Form>
                    <Form.Field required>
                        <Form.TextArea label='Add your notes *' placeholder='Why did I decline the task..' rows={6} value={props.reason} onChange={(e) => props.setReason(e.target.value)} />
                    </Form.Field>
                </Form>
                <Button
                    color='blue'
                    floated='right'
                    className="marT10"
                    onClick={() => props.decisionCall(false)}
                >
                    Done
                </Button>
            </Modal.Content>
        </Modal>
    )
}

export default withRouter(LexxDeclineModal);