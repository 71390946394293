import React, { useState, useEffect, useRef } from "react";
import {
  Icon,
  Modal,
  List,
  Form,
  Image,
  Button,
  Radio,
  TextArea,
  Progress,
} from "semantic-ui-react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getNoteData,
  getChatMediaLink,
  uploadChatData,
  getOrganizationProfilePictures,
  postNoteData,
} from "../apiCall";
import { SOCKET_EVENTS } from "../../constants/socketEvents";
import LexxNote from "./LexxNote";
import userDummy from "../../assets/images/user-dummy.png";

/* Parent component for the Chat which is a modal */
function LexxSessionLearning(props) {
  const [messages, setMessages] = useState([]); // array of all the message
  const [text, setText] = useState(""); // text typed by the logged in user
  const [allUsers, setUsers] = useState([]); // all users in the chat
  const [writing, setWriting] = useState(false);
  const [videoUploading, setVideoUploading] = useState(false);
  // const [seenUsers, setSeenUsers] = useState([]);
  const [selectedOption, setSelectedOption] = useState("myself");
  const [percentProgressBar, setPrecentProgressBar] = useState(10);
  /* Function to get all the users, also all the profile images of all the users 
       Also to setup a socket connection and get chat messages 
    */
  const getUsers = () => {
    getNoteData("?sessionId=" + props.sessionID)
      .then((response) => {
        setMessages(response.data.data.messages);
      })
      .catch((error) => {
        console.log(error);
      });

    getOrganizationProfilePictures().then((response) => {
      setUsers(response.data.data.users);
      setVideoUploading(false);
    });
  };

  useEffect(getUsers, []);
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  useEffect(() => {
    let interval;
    if (videoUploading) {
      interval = setInterval(() => {
        setPrecentProgressBar((percent) =>
          percent < 90 ? percent + 5 : percent
        );
      }, 2000);
    } else {
      interval = setInterval(() => {
        setPrecentProgressBar((percent) => percent + 5);
      }, 2000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [videoUploading]);
  // Function to add the message, the message is sent in the socket connection
  const addPost = () => {
    setWriting(false);
    if (text !== "") {
      postNoteData({
        data: text,
        isFile: false,
        sessionId: props.sessionID,
        noteFor: selectedOption,
      }).then(() => {
        getUsers();
      });
      setText("");
    }
  };

  const addNoteOnClick = () => {
    setWriting(true);
  };

  // Function called on change of text on the message field
  const handleChange = (e) => {
    setText(e.target.value);
  };

  /* Get the profile image of the user based on the parameter
        @name - name of the user whose profile image is to be shown
        usersData is an array with all the session users and profile image of them
    */
  const getProfileImage = (name) => {
    var usersData = props.sessionUsers;
    if (usersData.length > 0) {
      for (var eachUser of usersData) {
        if (eachUser.userId === name) {
          return eachUser.avatar !== "" ? eachUser.avatar : userDummy;
        }
      }
    } else {
      return null;
    }
  };

  /* Function to load the file to AWS and grab the key and then get the url to show the image */
  const loadFile = (e) => {
    setPrecentProgressBar(0);
    e.target.files[0] && setVideoUploading(true);
    let file = e.target.files[0];
    let fileName = file.name;
    let newFileName = props.username + Date.now() + fileName;
    axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("app_token");
    let data = {
      sessionId: props.sessionID,
      key: newFileName,
      type: file.type,
    };
    getChatMediaLink("/uplinks", data).then((result) => {
      if (result.data.code === "Success") {
        delete axios.defaults.headers.common["Authorization"];
        var blob = file.slice(0, file.size, file.type);
        let newFile = new File([blob], props.sessionID + "/" + newFileName, {
          type: file.type,
        });
        uploadChatData(result.data.data.url, newFile, file.type)
          .then((response) => {
            axios.defaults.headers.common["Authorization"] =
              localStorage.getItem("app_token");
            addPost();
            setWriting(false);
            postNoteData({
              data: newFileName,
              isFile: true,
              sessionId: props.sessionID,
              noteFor: selectedOption,
            }).then(() => {
              getUsers();
            });
          })
          .catch((e) => console.log(e));
      }
    });
  };

  const messagesEndRef = useRef(null);
  /* Scroll the user to bottom when message is sent */
  const scrollToBottom = () => {
    setTimeout(function () {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 500);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      addPost();
    }
  };

  const handleOptionChange = (e, { value }) => {
    setSelectedOption(value);
  };

  return (
    <>
      <Modal
        closeIcon
        open={props.open}
        onClose={() => props.learningWindow(false)}
        onOpen={() => props.learningWindow(true)}
        className=" addNote"
        dimmer={"blurring"}
        // style={{ maxHeight: '100%', overflowY: 'auto' }}
      >
        <Modal.Header>Add a Note</Modal.Header>

        <Modal.Content className="teamList sessionList notesWrapper">
          <List>
            {Object.keys(allUsers).length > 0 &&
              messages.map((message, i) => (
                <div key={i} className="messageWrapper">
                  <LexxNote
                    message={message}
                    username={props.username}
                    usersInChat={allUsers}
                  />
                </div>
              ))}
            <div ref={messagesEndRef} />
          </List>
        </Modal.Content>
        <Modal.Actions>
          {writing ? (
            <>
              {videoUploading && (
                <div>
                  <Progress
                    percent={percentProgressBar}
                    color="blue"
                    size="tiny"
                  >
                    Uploading Files...
                  </Progress>
                </div>
              )}
              <Form.Field
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ marginLeft: "3%", marginBottom: "2%" }}>
                  Who is the note for
                </span>

                <Form.Group inline style={{ marginLeft: "11%" }}>
                  <Radio
                    style={{ marginRight: "20px" }}
                    label="Myself"
                    value="myself"
                    checked={selectedOption === "myself"}
                    onChange={handleOptionChange}
                  />
                  <Radio
                    label="My Colleagues"
                    value="colleagues"
                    checked={selectedOption === "colleagues"}
                    onChange={handleOptionChange}
                  />
                </Form.Group>
              </Form.Field>
              <Form.Field
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ marginLeft: "3%", marginBottom: "2%" }}>
                  Your note
                </span>
                <TextArea
                  placeholder="Add your note here..."
                  name="text"
                  disabled={videoUploading}
                  onChange={handleChange}
                  value={text}
                  onKeyPress={handleKeyPress}
                  style={{ width: "96%", marginLeft: "2%", height: "70px", resize: "none", marginBottom:"10px" }}
                />
              </Form.Field>
              <Form.Field>
                <Button
                  as="label"
                  htmlFor="file"
                  type="button"
                  className="mediaInput"
                >
                  <Icon
                    name="paperclip"
                    color="#0971CE"
                    size="large"
                    className="marL10"
                  />
                </Button>
                <input
                  type="file"
                  id="file"
                  disabled={videoUploading}
                  style={{ display: "none" }}
                  onChange={loadFile}
                  accept="video/*,image/*"
                />
                <Button
                  className="new-task-button"
                  onClick={addPost}
                  disabled={videoUploading}
                >
                  Submit
                </Button>
              </Form.Field>
            </>
          ) : (
            <div>
              <Button className="new-task-button" onClick={addNoteOnClick}>
                Add Note
              </Button>
            </div>
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    sessionID: state.sessionData.sessionID,
    username: state.userInfo.userId,
    sessionUsers: state.sessionUsers,
  };
};

export default withRouter(connect(mapStateToProps)(LexxSessionLearning));
