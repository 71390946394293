import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbDivider, Button } from "semantic-ui-react";
import { logUserAction, faultFixed } from "../apiCall";
import { saveTaskId, newSessionfromtask } from "../../redux/actions/index";

/* This component is build to display breadcrumbs based on page 
    and to redirect the user 
*/
const LexxBreadcrumbs = (props) => {
  const { location, history } = props; //location and history from Router
  const { pathname } = location; //pathname on the browser URL
  let breadcrumbs = [];
  let path = "";
  /* this checks the pathname and updates the path variable accordingly
   */
  if (pathname.includes("feedback")) {
    if (props.troubleshootingData["info_type"] === "Fault isolation") {
      path = "/troubleshooting/issues/options/action";
    } else if (props.troubleshootingData["info_type"] === "Work Order") {
      path = "/troubleshooting/issues/workorder";
    } else if (
      props.troubleshootingData["info_type"] === "Examinations, tests & checks"
    ) {
      if (props.troubleshootingOption !== null) {
        path += "/troubleshooting/issues/options/action/procedure";
      } else {
        path += "/troubleshooting/issues/procedure";
      }
    } else if (props.troubleshootingData["info_type"] === "Schematics") {
      if (props.troubleshootingOption !== null) {
        path += "/troubleshooting/issues/options/action/schematic";
      } else {
        path += "/troubleshooting/issues/schematic";
      }
    }
  }

  if (pathname.includes("schematic") || pathname.includes("procedure")) {
    if (props.troubleshootingOption !== null) {
      path += "/troubleshooting/issues/options/action";
    } else {
      path += "/troubleshooting/issues/";
    }
  }

  path += pathname;

  breadcrumbs = path.split("/").filter((x) => x);
  const last_path = breadcrumbs.pop();
  if (!last_path.includes("id=")) {
    breadcrumbs = breadcrumbs.concat([last_path]);
  }

  let filteredArray = breadcrumbs.filter((element) => element !== "action");
  breadcrumbs = filteredArray;

  /* Function gets the link to take the user to 
        based on @stripped name parameter
        */
  const getLink = (stripped_name) => {
    if (stripped_name === "" || stripped_name === "troubleshooting") {
      return "/troubleshooting";
    } else if (stripped_name === "issues") {
      return "/troubleshooting/issues";
    } else if (stripped_name === "options") {
      return "/troubleshooting/issues/options/";
    } else if (stripped_name === "workorder") {
      return "/troubleshooting/issues/workorder/";
    } else if (stripped_name === "action") {
      return `/troubleshooting/issues/options/action`;
    } else if (stripped_name === "feedback") {
      return "/feedback";
    } else if (stripped_name.indexOf("thankyou") >= 0) {
      return "/";
    } else if (last_path.includes("id=")) {
      return pathname;
    }
  };

  /* Display the breadcrumbs in the UI , mapping of internal names with UI */
  const getBreadcrumbTitle = (stripped_name) => {
    if (
      (stripped_name === "" || stripped_name === "troubleshooting") &&
      props.isTroubleshooting
    ) {
      return "Describe The Fault";
    } else if (
      (stripped_name === "" || stripped_name === "troubleshooting") &&
      !props.isTroubleshooting
    ) {
      return "Search Info";
    } else if (
      stripped_name === "issues" &&
      props.isTroubleshooting &&
      props.searchResults.length
    ) {
      return "Select Issue";
    } else if (
      stripped_name === "issues" &&
      !props.isTroubleshooting &&
      props.searchResults.length
    ) {
      return "Look Up Info";
    } else if (stripped_name === "options") {
      return "Isolate Fault";
    } else if (stripped_name === "workorder") {
      return "Work Order";
    }
    // else if (stripped_name === 'action') {
    //     return `Step : ${props.troubleshootingStep}`
    // }
    else if (stripped_name === "feedback") {
      return "Feedback";
    } else if (stripped_name.indexOf("thankyou") >= 0) {
      return "Back To Dashboard";
    } else if (stripped_name.indexOf("schematic") >= 0) {
      return "Schematic";
    } else if (stripped_name.indexOf("procedure") >= 0) {
      return "Procedure";
    }
  };

  /* When the user clicks on back button next to breadcrumbs
            history object helps you to go back */
  const backButtonClick = () => {
    logUserAction({
      action: "Back button",
    });
    if (pathname.includes("thankyou")) {
      props.history.push("/");
    } else {
      // props.history.push(`/feedback/`);
      history.goBack();
    }
  };

  /* Renders the breadcrumbs */
  return (
    <div className="container-div">
      <div className="backbutton-div">
        <span className="back-arrow" onClick={() => backButtonClick()}>
          &#8592;
        </span>
      </div>
      <Breadcrumb className="breadcrumbs-div">
        {breadcrumbs.map((name, index) => {
          const name_stripped = name.split("/").filter((x) => x)[0];
          return (
            <>
              {props?.searchResults?.length !== 0 && (
                <React.Fragment key={index}>
                  <Breadcrumb.Section
                    link={index !== breadcrumbs.length - 1}
                    onClick={
                      index !== breadcrumbs.length - 1 ||
                      name_stripped === "thankyou"
                        ? () => {
                            history.push(getLink(name));
                            if (
                              getBreadcrumbTitle(name_stripped) ===
                              "Back To Dashboard"
                            ) {
                              props.saveTaskId(null);
                            }
                          }
                        : null
                    }
                    active={
                      index === breadcrumbs.length - 1 &&
                      name_stripped !== "thankyou"
                    }
                  >
                    {getBreadcrumbTitle(name_stripped)}
                  </Breadcrumb.Section>
                  {index !== breadcrumbs.length - 1 ? (
                    <BreadcrumbDivider icon="right angle" />
                  ) : null}
                </React.Fragment>
              )}
            </>
          );
        })}

        {props.taskId !== "" && props.taskId !== null && props.sessionfromtask && (
          <Button
            className="backtotask-breadcrumb"
            onClick={() => {
              props.newSessionfromtask(false);
              props.history.push(`/viewTask/id=${props.taskId}`);
            }}
          >
            Back to Task
          </Button>
        )}
      </Breadcrumb>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchResults: state.searchResults,
    troubleshootingData: state.troubleshootingData, // troubleshooting data regarding the steps
    troubleshootingOption: state.troubleshootingOption, // troubleshooting option choosen
    troubleshootingStep: state.troubleshootingStep, // troubleshooting step the user is at
    stepsVisited: state.visitedTroubleshootingSteps, // steps visited from the total steps within a fault code
    isTroubleshooting: state.isTroubleshooting, // true/false - if its troubleshooting or not
    taskId: state.taskId,
    sessionData:state.sessionData,
    sessionfromtask:state.sessionfromtask

  };
};

export default withRouter(
  connect(mapStateToProps, { saveTaskId, newSessionfromtask })(LexxBreadcrumbs)
);
