import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Header, Menu, Button, Label } from 'semantic-ui-react';
import { getTask, getComments, closeTaskServer } from '../apiCall';
import userDummy from '../../assets/images/user-dummy.png';
import LexxComment from "../LexxTask/LexxComment";
import LexxLogs from "../LexxReport/LexxLogs";
import SavedSessionCard from "../LexxCarousel/SavedSessionCard";
import { saveReportReviewOptions, saveCompletedReportReviewOptions } from "../../redux/actions/index";
import html2pdf from 'html2pdf.js';
import LexxReportSessionChatView from '../LexxReport/LexxReportSessionChatView';
import LexxReportSessionNotesView from '../LexxReport/LexxReportSessionNotesView';


function LexxReportSummaryReview(props) {

    const [task, setTask] = useState([]); // array to hold the task data
    const [comments, setComments] = useState([]); // array to hold the list of comments
    const [isPrinting, setIsPrinting] = useState(true);


    /* Function to get the task details and set it in task variable */
    const getTaskServerCall = () => {
        getTask("/" + props.match.params.id + "?isReport=true").then((response) => {
            setTask(response.data.data.task);
        }).catch((error) => {
            console.log(error);
        });
        getCommentsServerCall();
    }

    /* Function to get the comments for that task and set it in comments variable */
    const getCommentsServerCall = () => {
        getComments("/" + props.match.params.id).then((response) => {
            setComments(response.data.data.comments);
        }).catch((error) => {
            console.log(error);
        });
    }

    /* Function to convert UTC date to readable date */
    const getDate = (dateUTC) => {
        console.log(dateUTC);
        return dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
    }

    const handleDownload = () => {
        setIsPrinting(false);
        const content = document.getElementById('wrap-report'); // Replace 'root' with the ID of your root element
        html2pdf(content, {
          margin: 10,
          filename: 'web_page.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        });
        setIsPrinting(true);
      };

    /* Function to convert UTC date to readable time */
    const getTime = (dateUTC) => {
        if (dateUTC.getMinutes().toString().length === 1) {
            return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
        } else {
            return dateUTC.getHours() + ":" + dateUTC.getMinutes();
        }
    }

    /* This function is called when the user closes the report
        this can only be done by the owner of the task
    */
    const closeReport = () => {
        closeTaskServer("/" + props.match.params.id + "/closed");
    }

    function timeDiffCalc(diffInMilliSeconds) {
        var displayMinutes, displaySeconds;
        const hours = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= hours * 86400;
        const minutes = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= minutes * 3600;
        const seconds = Math.floor(diffInMilliSeconds / 60) % 60;
        displayMinutes = minutes.toString().length > 1 ? minutes + ":" : "0" + minutes + ":";
        displaySeconds = seconds.toString().length > 1 ? seconds : "0" + seconds;
        return hours + ":" + displayMinutes + displaySeconds;
    }

    /* This function gets all the sessions to be reviewed in a task
        if the sessions are more than 0, loop over each session and push it in array
        if the session id matches with the task sessions, show it to user
    */

    const sessionsReview = () => {
        var sessionsToShow = [];
        var sessionsNeeded = [];
        if (task.sessions.length > 0) {
            task.report.sessionConfigs.forEach((eachSession) => {
                sessionsNeeded.push(eachSession.sessionId);
            });
            props.saveReportReviewOptions(sessionsNeeded);
            props.saveCompletedReportReviewOptions([]);
            task.sessions.forEach((session, idx) => {
                if (props?.savedSessionOptions?.includes(session?.sessionId)) {
                    sessionsToShow.push(<>
                        <div className="session-details">
                                    <div>
                                        <h2 className="label">Session {idx + 1}</h2>
                                    </div>
                                    <div>
                                        <p className="label">Session Name</p>
                                        <p className="value">{session.troubleshootingData !== null ? session.troubleshootingData.title : ""}</p>
                                    </div>
                                    <div>
                                        <p className="label">Date and Time</p>
                                        <p className="value">{getDate(new Date(session.createTime)) + " " + getTime(new Date(session.createTime))}</p>
                                    </div>
                                    <div>
                                        <p className="label">Session owner</p>
                                        {/* <p className="value">{session.owner.name}{` (${session.owner.userId})`}</p> */}
                                        <p className="value">{session.owner.name}</p>
                                    </div>
                                    {/* <div>
                                        <p className="label">Shared with</p>
                                        <p className="value">{getSharedList(session.summarySharedWith)}</p>
                                    </div> */}
                                    <div>
                                        <p className="label">Resolution</p>
                                        <p className="value">{session.completeTime ? "FAULT FIXED" : "IN PROGRESS"}</p>
                                    </div>
                                    <div>
                                        <p className="label">Number of Steps</p>
                                        <p className="value">{(session.troubleshootingData !== null && session.troubleshootingData.step_activities) ? Object.keys(session.troubleshootingData.step_activities).length - 1 : "N/A"}</p>
                                    </div>
                                    <div>
                                        <p className="label">Total Time Taken</p>
                                        <p className="value">{session.completeTime ? timeDiffCalc(Math.abs(new Date(session.completeTime) - new Date(session.createTime))) : null}</p>
                                    </div>
                                    <div>
                                        <p className="label">Session Chat</p>
                                        <LexxReportSessionChatView 
                                        sessionID={session.sessionId} 
                                        username={session.owner.userId} />
                                    </div>
                                    <div>
                                        <p className="label">Session Notes</p>
                                        <LexxReportSessionNotesView 
                                        sessionID={session.sessionId} 
                                        username={session.owner.userId}/>
                                    </div>
                                </div>
                                <br></br>
                        </>)
                }             
                // if (sessionsNeeded.includes(session.sessionId)) {
                //     sessionsToShow.push(<SavedSessionCard key={idx} sessionData={session} isClickable={false} />);
                // } else {
                //     return null
                // }
            })
            if (sessionsToShow.length === 0) {
                task.sessions.forEach((session, idx) => {
                        sessionsToShow.push(<>
                            <div className="session-details">
                                        <div>
                                            <h2 className="label">Session {idx + 1}</h2>
                                        </div>
                                        <div>
                                            <p className="label">Session Name</p>
                                            <p className="value">{session.troubleshootingData !== null ? session.troubleshootingData.title : ""}</p>
                                        </div>
                                        <div>
                                            <p className="label">Date and Time</p>
                                            <p className="value">{getDate(new Date(session.createTime)) + " " + getTime(new Date(session.createTime))}</p>
                                        </div>
                                        <div>
                                            <p className="label">Session owner</p>
                                            <p className="value">{session.owner.name}{` (${session.owner.userId})`}</p>
                                        </div>
                                        {/* <div>
                                            <p className="label">Shared with</p>
                                            <p className="value">{getSharedList(session.summarySharedWith)}</p>
                                        </div> */}
                                        <div>
                                            <p className="label">Resolution</p>
                                            <p className="value">{session.completeTime ? "FAULT FIXED" : "IN PROGRESS"}</p>
                                        </div>
                                        <div>
                                            <p className="label">Number of Steps</p>
                                            <p className="value">{(session.troubleshootingData !== null && session.troubleshootingData.step_activities) ? Object.keys(session.troubleshootingData.step_activities).length - 1 : "N/A"}</p>
                                        </div>
                                        <div>
                                            <p className="label">Total Time Taken</p>
                                            <p className="value">{session.completeTime ? timeDiffCalc(Math.abs(new Date(session.completeTime) - new Date(session.createTime))) : null}</p>
                                        </div>
                                    </div>
                                    <br></br>
                            </>)
                })
            }
        } else {
            return sessionsToShow;
        }
        return sessionsToShow;
    }

    /* Take the user to the session review page */
    const goNext = () => {
        props.history.push("/reviewReportSession/id=" + props.match.params.id);
    }

    useEffect(getTaskServerCall, []);

    return (
        <div className='wrap-report-summary'>
        {isPrinting &&  (<button id='removebutton' onClick={handleDownload} className='downloadtaskbtn' >
                    Download Report
                </button>)}
        <div className="session-summary report-summary-step4" id='wrap-report'>
            <div className="header-container">
                <Header as="h2">Task Report</Header>
                <br></br>
             
                {/* {
                    task.closed ? null :
                        <span className="saveDraft" onClick={() => closeReport()}>Close Report</span>
                } */}
                <div className="task-details">{task.taskNum ? task.taskNum + " • " + task.taskTitle : ""}</div>
                {/* <div>
                    <span className="filled"></span>
                    {
                         Object.keys(task).length && task.report.sessionConfigs.map((session, i) => {
                            return <span key={i}></span>
                        })
                    }
                </div> */}
            </div>
            {
                Object.keys(task).length > 0 ?
                    <>
                        <div className="session-details">
                            <div>
                                <p className="label">Type of Task</p>
                                <p className="value">
                                    <span className={'task-priority ' + task.taskPriority.toLowerCase()}> {task.taskPriority} </span>
                                    <span className="task-type"> {task.taskType} </span>
                                </p>
                            </div>
                            <div>
                                <p className="label">Number of Sessions</p>
                                <p className="value">{task.report.sessionConfigs.length}</p>
                            </div>
                            <div>
                                <p className="label">Assignee</p>
                                <p className="value assignee">
                                    <Label circular className="collaborators float-left" as='a' image={task.assignee.avatar === "" ? userDummy : task.assignee.avatar} />
                                    {/* <span>{task.assignee.name + ` (${task.assignee.userId})`}</span> */}
                                    <span>{task.assignee.name}</span>
                                </p>
                            </div>
                            <div>
                                <p className="label">Wind Turbine Number</p>
                                <p className="value">{task.windTurbineNumber}</p>
                            </div>
                            <div>
                                <p className="label">Fault Code</p>
                                <p className="value">{task.faultCode ? task.faultCode : 'Not applicable'}</p>
                            </div>
                            <div>
                                <p className="label">Created by</p>
                                {/* <p className="value">{task.owner ? task.owner.name : 'Not applicable'}{`(${task.owner? task.owner.userId:'Not applicable'})`}</p> */}
                                <p className="value">{task.owner ? task.owner.name : 'Not applicable'}</p>
                            </div>
                            <div>
                                <p className="label">Task Reported/Description</p>
                                <p className="value">{task.taskDescription}</p>
                            </div>
                            <div>
                                <p className="label">Created on</p>
                                <p className="value">{getDate(new Date(task.createTime)) + " " + getTime(new Date(task.createTime))}</p>
                            </div>
                            <div>
                                <p className="label">Date and time finished</p>
                                {/* <p className="value">{getDate(new Date(task.completeTime)) + " " + getTime(new Date(task.completeTime))}</p> */}
                                <p className="value">{getDate(new Date(task.updateTime)) + " " + getTime(new Date(task.updateTime))}</p>
                            </div>
                        </div>
                        <Header as="h3">Sessions</Header>
                        <div className="sliding-cards">
                            {sessionsReview()}
                        </div>
                        <div>
                            <Header as="h3">Comments</Header>
                            {comments.map((comment, i) => <div key={i} className="commentsWrapper"><LexxComment comment={comment} editable={false}/></div>)}
                        </div>

                        <div className="review-logs">
                            <Header as="h3">Logs</Header>
                            {task.logs !== undefined ? task.logs.map((log, i) => <div key={i} className="logsWrapper"><LexxLogs log={log} /></div>) : null}
                        </div>
                    </>
                    : null
            }
            {/* {
                Object.keys(task).length > 0 && task.report.sessionConfigs.length === 0 ?
                    <Menu borderless fixed='bottom' id="menu">
                        <Menu.Item className="width100">
                            <Button className='primarybtn' onClick={() => closeReport()}>
                                Approve and Close Task
                            </Button>
                        </Menu.Item>
                    </Menu>
                    :
                    <Menu borderless fixed='bottom' id="menu">
                        <Menu.Item className="width100">
                            <Button className='primarybtn' onClick={() => goNext()}>
                                Next
                            </Button>
                        </Menu.Item>
                    </Menu>
            } */}
            </div>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId,
        savedSessionOptions: state.savedSessionOptions,
    };
};

export default withRouter(connect(mapStateToProps, { saveReportReviewOptions, saveCompletedReportReviewOptions })(LexxReportSummaryReview));