import React, { useState, useEffect } from 'react';
import { Icon, Input, Modal, List, Image, Checkbox, Button, Form } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { getTeam, getTeamAllUsers } from '../apiCall';
import userDummy from '../../assets/images/user-dummy.png';
import TaskModal from './LexxDelegateTaskReason';

/* Component(Modal) when the task is delegated to another technician */
function LexxDelegateTask(props) {

    const [search, setSearch] = useState("");
    const [stableOrganizationList, setStableOrganizationList] = useState([]);
    const [organizationList, setOrganizationList] = useState([]);
    const [disableButton,setDissableButton] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };
    
    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSubmit = (reason) => {
        // Handle the submission logic here (e.g., send the reason to the server)
        console.log('Submitted reason:', reason);
        const filteredData = organizationList.filter(user => user.selected === true);
        setModalOpen(false);
        props.delegateTaskToUser(filteredData[0].userId, reason);   
      };


    /* Get all the users from the db and set it in variable */
    const getUsers = () => {
        getTeamAllUsers("/handover").then((response) => {
            let tempuserarray = []
            response.data.data.users.map((user) => {
                user.selected = false;
                tempuserarray.push(user);
            })
            setStableOrganizationList(response.data.data.users.length === 0 ? [] : tempuserarray);
            setOrganizationList(response.data.data.users.length === 0 ? [] : tempuserarray);
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(getUsers, []);
    

    /* When the technician is searched, filter and set the setOrganizationList,it will re-render */
    const changeSearch = (event) => {
        setSearch(event.currentTarget.value);
        const filteredData = stableOrganizationList.filter(item => {
            return Object.keys(item).some((eachKey) => {
                if (eachKey === "name" || eachKey === "title") {
                    return item[eachKey].toLowerCase().includes(event.currentTarget.value.toLowerCase());
                } else {
                    return null;
                }
            })
        });
        setOrganizationList(filteredData);
    }

    /* Redirect the user to their profile */
    const showTeamMemberProfile = (userId) => {
        props.history.push(`/userprofile/id=${userId}`);
    }
    const handleMailsend = ()=>{
        // open a modal for asking input msg
        console.log("mailsend called");
        handleOpenModal();
        // const filteredData = organizationList.filter(user => user.selected === true);
        // props.delegateTaskToUser(filteredData[0].userId);   
    }

    const disable = ()=>{
         organizationList.map((data)=>{
            if(data.selected === true){
                setDissableButton(false)
            }
         })
    }

    useEffect(disable,[organizationList]);

    const handleCheckboxChange = (id) => {
        setOrganizationList((prevDataArray) =>
          prevDataArray.map((item) =>
            item.userId === id ? { ...item, selected: true } : { ...item, selected: false }
          )
        );
      };



    return (
        <Modal
            closeIcon
            open={props.open}
            onClose={() => props.closeDialog(false)}
            onOpen={() => props.closeDialog(true)}
            className="addMember-container"
        >
            {
                modalOpen && <TaskModal isOpen={modalOpen} onClose={handleCloseModal} onSubmit={handleSubmit} />
            }
            <Modal.Header>Handover Task to</Modal.Header>
            <div className="searchTeam">
                <Input
                    icon={<Icon name='search' color="black" />}
                    iconPosition='left' placeholder='Search' value={search} onChange={changeSearch} />
            </div>
            <Modal.Content className="teamList sessionList">
                <div className='wrapup-handleover'>
                <Button className='handover-button' disabled={disableButton} onClick={handleMailsend}>Hand Over Task</Button>
                <List>
                    {organizationList.map((tech, i) => {
                        if (tech.name !== undefined) {
                            return (
                                <List.Item key={i}  onClick={() => handleCheckboxChange(tech.userId)}>
                                    <Checkbox
                                      className='handover-checkbox'
                                      id={`checkbox_${tech.userId}`}
                                      checked={tech.selected}
                                      />
                                    <Image avatar src={tech.profileImg !== "" ? tech.profileImg : userDummy} onClick={() => showTeamMemberProfile(tech.userId)} />
                                    <List.Content>
                                        <List.Header>{tech.name}</List.Header>
                                        <List.Description>
                                            {tech.title}
                                        </List.Description>
                                    </List.Content>
                                    {/* <List.Content floated='right' className="cursor-pointer color-blue" onClick={() => props.delegateTaskToUser(tech.userId)}>
                                        <Icon name="add" className="marT10" /> Hand Over Task
                                    </List.Content> */}
                                </List.Item>
                            )
                        } else {
                            return null;
                        }
                    })
                    }

                </List>
                </div>
            </Modal.Content>
        </Modal>
    )
}

export default withRouter(LexxDelegateTask);